import ColorServices from "@/services/color-service";
import { catchError } from "@/store/Util";
import notificationService from "@/services/notification-service";
import { MessageTypes, Notification } from "@/interfaces/proccess";
import { ColorsSetting } from "../../interfaces/colors";

export const ColorModule = {
	namespaced: true,
	state: () => ({}),
	mutations: {},
	getters: {},
	actions: {
		async setChangeColor({ commit }, params: ColorsSetting) {
			try {
                let response = await ColorServices.fetchSetColor(params);

				notificationService.notifySuccess(this, {} as Notification);

				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject();
			}
		}
	},
};