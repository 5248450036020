import { isEmpty } from "lodash";
import {
  hasRole,
  hasRoleExcluded,
  MATCHED_ROLE_ROUTE,
  ROLE_REPORT,
  ROLE_PERSONS,
  ROUTE_DEFAULT,
  canAccess,
  ROLE_RETAIL_MEDIA,
} from "./utils";
import { getPermisionForUrl } from "@/utils/permissionResolve";
import store from "@/store";
import { isProd } from "@/services/process-service";
import { getTokenFromStorage } from "@/services/storage-service";

/**
 * Middleware para manejar el acceso basado en roles y permisos.
 *
 * @param {Object} context - Contexto de navegación.
 */
export default async function ({ next, router, to }: any) {
  const excludes = getExcludedRoles(to);

  if (await isRoleExcluded(excludes)) {
    return await redirectToMatchedRoute(router);
  }

  let permission = getPermissionFromStore();

  if (!hasPermission(permission)) {
    await fetchAndStoreUserPermission();
    permission = getPermissionFromStore();
  }

  const routeName = to.name;

  if (isRouteProtected(routeName)) {
    return await handleProtectedRoute(routeName, router, next);
  }

  return next();
}

/**
 * Obtiene los roles excluidos de la meta de la ruta.
 *
 * @param {Object} to - Ruta destino.
 * @returns {string[]} - Lista de roles excluidos.
 */
function getExcludedRoles(to: any): string[] {
  return to?.meta?.excludes ?? [];
}

/**
 * Maneja la lógica para rutas protegidas.
 *
 * @param {string} routeName - Nombre de la ruta.
 * @param {any} router - Instancia del enrutador.
 * @param {Function} next - Función para continuar la navegación.
 * @returns {Promise<void>} - Promesa de navegación o redirección.
 */
async function handleProtectedRoute(routeName: string, router: any, next: Function): Promise<void> {
  /**
   * accessGranted
   * Indica si el acceso fue concedido.
   */
  const accessGranted = await canAccessRoute(routeName);

  // addDebug(`routeName: ${routeName}`, { accessGranted });

  if (!accessGranted && (await shouldRedirectToCampaign(routeName))) {
    return redirectTo(router, "CampaignsIndex");
  }

  return accessGranted ? next() : redirectTo(router, "Denied");
}

/**
 * Determina si debe redirigir a la ruta "CampaignsIndex".
 *
 * @param {string} routeName - Nombre de la ruta.
 * @returns {Promise<boolean>} - Verdadero si debe redirigir, falso de lo contrario.
 */
async function shouldRedirectToCampaign(routeName: string): Promise<boolean> {
  if (routeName !== "Dashboard") return false;
  return await canAccessRoute("CampaignsIndex");
}

/**
 * Obtiene los permisos desde el estado de la tienda.
 */
function getPermissionFromStore(): Record<string, any> {
  //@ts-ignore
  return store.state?.profile?.permission;
}

/**
 * Verifica si hay permisos disponibles.
 *
 * @param {any} permission - Permisos del usuario.
 * @returns {boolean} - Verdadero si existen permisos, falso de lo contrario.
 */
function hasPermission(permission: Record<string, any>): boolean {
  return Object.keys(permission).length > 0;
}

/**
 * Obtiene y almacena los permisos del usuario en la tienda.
 */
async function fetchAndStoreUserPermission() {
  const token = getTokenFromStorage();
  if(!token) return;
  try {
    await store.dispatch("profile/fetchProfile", { root: true });
  } catch (error) {
    console.error("Error al obtener los permisos del usuario:", error);
  }
}

/**
 * Verifica si la ruta está protegida.
 *
 * @param {string} [routeName] - Nombre de la ruta.
 * @returns {boolean} - Verdadero si la ruta está protegida, falso de lo contrario.
 */
function isRouteProtected(routeName?: string): boolean {
  return routeName ? getPermisionForUrl().hasOwnProperty(routeName) : false;
}

/**
 * Verifica si se puede acceder a la ruta especificada.
 *
 * @param {string} routeName - Nombre de la ruta.
 * @returns {Promise<boolean>} - Verdadero si se tiene acceso, falso de lo contrario.
 */
async function canAccessRoute(routeName: string): Promise<boolean> {
  const access = getPermisionForUrl()[routeName];

  switch (routeName) {
    case "PDFReport":
      return hasPDFReportAccess();
    case "ModifiersCreate":
      return canAccessModifier("create");
    case "ModifiersEdit":
      return canAccessModifier("update");
    case "AccountSettingsIndex":
      return canAccess("design_settings", "Account");
    default:
      return canAccess(access.action, access.subject);
  }
}

/**
 * Verifica si se tiene acceso para modificar un Modificador.
 *
 * @param {string} action - Acción a realizar (crear o actualizar).
 * @returns {Promise<boolean>} - Verdadero si se tiene acceso, falso de lo contrario.
 */
async function canAccessModifier(action: string): Promise<boolean> {
  return (
    (await canAccess(`${action}_bid_modifier`, "Modifier")) ||
    (await canAccess(`${action}_delivery_modifier`, "Modifier"))
  );
}

/**
 * Verifica si se tiene acceso al reporte PDF.
 *
 * @returns {boolean} - Verdadero si se tiene acceso, falso de lo contrario.
 */
function hasPDFReportAccess(): boolean {
  //@ts-ignore
  const { forbidden_accounts: forbiddenAccounts, account } = store.state?.profile || {};
  return !forbiddenAccounts?.includes(account?.external_id);
}

/**
 * Verifica si el rol del usuario está excluido.
 *
 * @param {string[]} excludes - Lista de roles excluidos.
 * @returns {Promise<boolean>} - Verdadero si el rol está excluido, falso de lo contrario.
 */
async function isRoleExcluded(excludes: string[]): Promise<boolean> {
  return !isEmpty(excludes) && (await hasRoleExcluded(excludes));
}

/**
 * Redirige a la ruta correspondiente según el rol del usuario.
 *
 * @param {any} router - Instancia del enrutador.
 * @returns {Promise<void>} - Promesa de navegación a la ruta correspondiente.
 */
async function redirectToMatchedRoute(router: any): Promise<void> {
  const routeName = await determineRedirectRoute();
  return router.push({ name: routeName }).catch(handleRouterError);
}

/**
 * Determina la ruta de redirección según el rol del usuario.
 *
 * @returns {Promise<string>} - Nombre de la ruta de redirección.
 */
async function determineRedirectRoute(): Promise<string> {
  if (await hasRole(ROLE_PERSONS)) {
    return MATCHED_ROLE_ROUTE[ROLE_PERSONS];
  }
  if (await hasRole(ROLE_REPORT)) {
    return MATCHED_ROLE_ROUTE[ROLE_REPORT];
  }
  if (await hasRole(ROLE_RETAIL_MEDIA)) {
    return MATCHED_ROLE_ROUTE[ROLE_RETAIL_MEDIA];
  }
  return MATCHED_ROLE_ROUTE[ROUTE_DEFAULT];
}

/**
 * Redirige a la ruta especificada (por nombre de ruta).
 * @param router
 * @param routeName
 * @returns
 */
function redirectTo(router: any, routeName: string) {
  return router.push({ name: routeName }).catch(handleRouterError);
}

/**
 * Maneja errores de navegación del enrutador.
 *
 * @param {any} error - Error de navegación.
 */
function handleRouterError(error: any) {
  console.error("Error de navegación del enrutador:", error);
}

/**
 * Agrega mensajes de depuración si no es entorno de producción.
 *
 * @param {string} text - Mensaje de depuración.
 * @param {any} [data] - Datos adicionales.
 */
function addDebug(text: string, data?: Record<string, any>) {
  if (isProd()) return;
  console.debug(`[middleware] DEBUG: ${text}`, data ?? {});
}
