import { lookalikeCreate, LookalikeFilters, SegmentCreate } from "@/interfaces/look_a_like";
import { Paginated, SortingOption } from "@/interfaces/paginated";
import { AxiosGet, AxiosPost, AxiosPut, GetData, GetErrors, GetMessage } from "@/services/axios-service";
import { prepareParams, catchServiceErrors, modes, downloadFile } from "@/utils/services-global";
import { isNull, isUndefined } from "lodash";
import ROUTES from "@/api/routes";

const ROUTE = ROUTES.LOOKALIKE;

const ENTITY: string = "lookalike";

class LookalikeService {
  async paginated(params: {
    paginated: Paginated;
    filters?: LookalikeFilters;
    options?: SortingOption;
    fields?: Array<string>;
  }) {
    try {
      let filter = "";

      if (!isUndefined(params.filters)) {
        filter = getFilters(params.filters);
      }

      const url = await prepareParams({
        route: ROUTE.LOOKALIKE_PAGINATED_ROUTE,
        entity: ENTITY,
        mode: modes.PAGINATED,
        filter: filter,
        ...params,
      });

      const response = await AxiosGet(url);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async create(entity: lookalikeCreate) {
    try {
      let response = await AxiosPost(ROUTE.LOOKALIKE_CREATE_ROUTE, entity);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async active(params: { idSegment: Number; entity: SegmentCreate }) {
    try {
      let url = `${ROUTE.LOOKALIKE_ACTIVATE_ROUTE}/${params.idSegment}`;
      let response = await AxiosPut(url, params.entity);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async download(params: {
    paginated?: Paginated;
    filters?: LookalikeFilters;
    options?: SortingOption;
    fields: Array<string>;
  }) {
    try {
      let filter = "";

      if (!isUndefined(params.filters)) {
        filter = getFilters(params.filters);
      }

      await downloadFile({
        entity: ENTITY,
        route: ROUTE.LOOKALIKE_PAGINATED_ROUTE,
        filter,
        mode: modes.DOWNLOAD,
        ...params,
      });

      return Promise.resolve({});
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }
}

function getFilters(filters: LookalikeFilters): string {
  let filter = "";
  var symbol = "";
  return filter;
}

export default new LookalikeService();
