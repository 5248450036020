import {
	ReportRun,
	GeneratedReport,
	ReportFilterSP,
	EnumReportType,
	FiltersEntity,
	FilterProperty,
	ReportFiltersSP,
} from "@/interfaces/report";
import { GenerateReportType } from "@/interfaces/reports/v2/report";
//@ts-ignore
import { EXCLUDE_CREATIVES, EXCLUDE_LINE_ITEM } from "@/views/Admin/Reports/Form/ReportForm.vue";
import { isEmpty } from "lodash";
import moment from "moment-timezone";
import { IReportingForm } from "@/interfaces/reports/v2/report";
import { IFilterEntity } from "@/interfaces/reports/v2/report";

moment.locale("es");

// Configs Date
export const DEFAULT_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DEFAULT_DATE_TIME_FORMAT_FILTER = "YYYY-MM-DD";
const EXCLUDE_LINE_ITEM = ["exampleReportType"];
const EXCLUDE_CREATIVES = ["exampleReportType"];

// Validación de rango personalizado
export function isCustom(dataRange: string): boolean {
	return dataRange === "custom";
  }
/**
 * Initialize Report Data
 * @returns
 */
export function ReportingForm() {
	return {
		report_type: "",
		start_date: undefined,
		end_date: undefined,
		data_range: "",
		type: "",
		dimension: [] as Array<any>,
		metric: [] as Array<any>,
		filters: {
			advertisers: [] as Array<any>,
			campaigns: [] as Array<any>,
			line_items: [] as Array<any>,
			creatives: [] as Array<any>,
			account: [] as Array<any>,
			deal_id: [] as Array<any>,
			layer_name: [] as Array<any>,
		},
	};
}

/**
 * Initialize Report Data
 * @returns
 */
export function ResourcesReportingForm():IReportingForm {
	return {
		report_type: [],
		data_range: [],
		type: [],
		dimension: [],
		metric: [],
		filteredDimensions: [],
		filteredMetrics: [],
		account: [],
		advertisers: [],
		campaigns: [],
		line_items: [],
		creatives: [],
		deal_id: [],
		layer_name: [],
		report_dimensions: {},
		report_metrics: [],
		report_all_type: [],
		scheduler_type: [],
	};
}


export async function prepareReportParams(params: {
	filters: {
		account: Array<any>;
		advertisers: Array<any>;
		line_items: Array<any>;
		campaigns: Array<any>;
		creatives: Array<any>;
		deal_id: Array<any>;
		layer_name: Array<any>;
	};
	report_type: string;
	data_range: string;
	start_date: Date;
	end_date: Date;
	type: string;
	dimension: Array<any>;
	metric: Array<any>;
}) {
	const advertisers: Array<any> = params.filters.advertisers;
	const line_items: Array<any> = params.filters.line_items;
	const campaigns: Array<any> = params.filters.campaigns;
	const creatives: Array<any> = params.filters.creatives;
	const account: Array<any> = params.filters.account;
	const deal_id: Array<any> = params.filters.deal_id;
	const layer_name: Array<any> = params.filters.layer_name;

	const test = process.env.VUE_APP_REPORT_TEST == "true";

	let start_d = "";
	let end_d = "";

	if (isCustom(params.data_range) && params.start_date) {
		start_d = moment(params.start_date).format(DEFAULT_DATE_TIME_FORMAT);
	}

	if (isCustom(params.data_range) && params.end_date) {
		end_d = moment(params.end_date).format(DEFAULT_DATE_TIME_FORMAT);
	}

	return {
		test: test,
		report_name: params.report_type,
		data_range: params.data_range,
		start_date: start_d,
		end_date: end_d,
		type: params.type,
		dimensions: params.dimension.map((a: { id: string }) => a.id),
		metrics: params.metric.map((a: { id: string }) => a.id),
		filters: {
			advertiser_id: advertisers.map((m: { id: any }) => String(m.id)).toString(),
			line_item_id: !EXCLUDE_LINE_ITEM.includes(params.report_type)
				? line_items.map((m: { id: any }) => String(m.id)).toString()
				: undefined,
			campaign_id: campaigns.map((m: { id: any }) => String(m.id)).toString(),
			creative_id: !EXCLUDE_CREATIVES.includes(params.report_type)
				? creatives.map((m: { id: any }) => String(m.id)).toString()
				: undefined,
			account_id: account.map((m: { id: any }) => String(m.id)).toString(),
		},
		report_type: params.report_type,
	} as ReportRun;
}

export function preparedFiltersEntitys(): Record<string, IFilterEntity> {
	return {
		account: {
			type: "account",
			key: "external_id",
			resourceName: "account",
			requireFilter: false,
			filter: null,
			delete :[]
		},
		advertisers: {
			type: "advertiser",
			key: "external_id",
			resourceName: "advertisers",
			requireFilter: false,
			filter: null,
			dependency: null,
			delete: ["campaigns", "line_items", "creatives"],
		},
		line_items: {
			type: "line_item",
			key: "external_id",
			resourceName: "line_items",
			requireFilter: true,
			filter: "campaigns",
			dependency: "campaigns",
			delete: ["creatives"],
		},
		campaigns: {
			type: "campaign",
			key: "external_id",
			resourceName: "campaigns",
			requireFilter: true,
			filter: "advertisers",
			dependency: "advertisers",
			delete: ["line_items", "creatives"],
		},
		creatives: {
			type: "creative",
			key: "external_id",
			resourceName: "creatives",
			requireFilter: true,
			filter: "line_items",
			dependency: "line_items",
			delete: [],
		},
		deal_id: {
			type: "deal_id",
			key: "key",
			resourceName: "deal_id",
			requireFilter: false,
			filter: "deal_id",
			dependency: "deal_id",
			delete: [],
		},
		layer_name: {
			type: "layer_name",
			key: "key",
			resourceName: "layer_name",
			requireFilter: false,
			filter: "layer_name",
			dependency: "layer_name",
			delete: [],
		},
		ooh_layer_name: {
			type: "ooh_layer_name",
			key: "key",
			resourceName: "ooh_layer_name",
			requireFilter: false,
			filter: "ooh_layer_name",
			dependency: "ooh_layer_name",
			delete: [],
		},
		ooh_distance: {
			type: "ooh_distance",
			key: "key",
			resourceName: "ooh_distance",
			requireFilter: false,
			filter: "ooh_distance",
			dependency: "ooh_distance",
			delete: [],
		},
	};
}

/**
 * Report v2
 * Parepara el payload con los filtros seleccionados para generar el reporte
 * @param params
 * @returns
 */
export async function prepareReportToSP(
	params: {
		filters: Record<string, Array<any> | any>;
		report_type: GenerateReportType;
		report_year: number;
		data_range: string;
		start_date: Date;
		end_date: Date;
		type: EnumReportType;
		dimensions: Record<string, any>;
		metrics: Record<string, any>;
	},
	isOoh: boolean
): Promise<GeneratedReport> {
	const {
		filters: { advertisers, line_items, campaigns, creatives, account, deal_id, layer_name, ooh_layer_name, ooh_distance },
		data_range,
		start_date,
		end_date,
		type,
		dimensions,
		metrics,
		report_type,
		report_year,
	} = params;

	const start_d = isCustom(data_range) && start_date ? moment(start_date).format(DEFAULT_DATE_TIME_FORMAT_FILTER) : "";
	const end_d = isCustom(data_range) && end_date ? moment(end_date).format(DEFAULT_DATE_TIME_FORMAT_FILTER) : "";

	const mergedDimensionsMetrics = { ...dimensions, ...metrics };
	const filteredDimensionsMetrics = Object.fromEntries(
		Object.entries(mergedDimensionsMetrics).filter(([_, value]) => value === 1)
	);

	const reportFilters: ReportFiltersSP = {
		account_id: account.map((item: { key: any }) => Number(item.key)),
		advertiser_id: advertisers.map((item: { key: any }) => Number(item.key)),
		campaign_id: campaigns.map((item: { key: any }) => Number(item.key)),
		line_item_id: line_items.map((item: { key: any }) => Number(item.key)),
		creative_id: creatives.map((item: { key: any }) => Number(item.key)),
		deal_id: deal_id.map((item: { key: any }) => item.key),
		layer_name: !isOoh && layer_name?.key ? [Number(layer_name.key)] : undefined,
	};

	if (isOoh) {
		reportFilters.layer_name = undefined;
		reportFilters.layer_id_filter = layer_name?.key ? [Number(layer_name.key)] : [];
		reportFilters.layer_id_ooh_filter = ooh_layer_name?.key ? [Number(ooh_layer_name.key)] : [];
		reportFilters.ooh_distance = ooh_distance?.key ? Number(ooh_distance.key) : undefined;
	}

	const payload: GeneratedReport = {
		data_range,
		start_date: start_d,
		end_date: end_d,
		type,
		metrics: filteredDimensionsMetrics,
		filters: reportFilters,
		report_type,
		year: report_year,
	};

	return payload;
}


/**
 * Reporte v2
 * Prepara el payload con los filtros seleccionados para obtener el listado que alimenta los combox de los filtros
 * @param params
 * @returns
 */
export async function prepareFilterToSP(params: {
	filters: FiltersEntity;
	data_range: string;
	start_date: Date;
	end_date: Date;
	report_type: string;
	report_year: number;
	omit: string;
}) {
	const createFilteredEntities = (keys: string[], omitKey: string, filters: FiltersEntity) => {
		return keys.reduce<Record<string, any>>((acc, key) => {
			acc[key] = omitKey === key ? [] : (filters as any)[key];
			return acc;
		}, {});
	};

	const sanitizeArray = (arr: any[]) => {
		return arr.some(value => value === null || Number.isNaN(value)) ? [] : arr;
	};

	const formatFilterValues = (entities: Record<string, any>) => ({
		account_id: sanitizeArray(entities.account.map((m: FilterProperty) => Number(m.key))),
		advertiser_id: sanitizeArray(entities.advertisers.map((m: FilterProperty) => Number(m.key))),
		line_item_id: sanitizeArray(entities.line_items.map((m: FilterProperty) => Number(m.key))),
		campaign_id: sanitizeArray(entities.campaigns.map((m: FilterProperty) => Number(m.key))),
		creative_id: sanitizeArray(entities.creatives.map((m: FilterProperty) => Number(m.key))),
		deal_id: sanitizeArray(entities.deal_id.map((m: FilterProperty) => String(m.key))),
		layer_name: entities.layer_name ? sanitizeArray([Number(entities.layer_name.key)]) : [],
		ooh_layer_name: entities.ooh_layer_name ? sanitizeArray([Number(entities.ooh_layer_name.key)]) : [],
		ooh_distance: entities.ooh_distance ? Number(entities.ooh_distance.key) : undefined,
	});

	const filterKeys = [
		'account',
		'advertisers',
		'line_items',
		'campaigns',
		'creatives',
		'deal_id',
		'layer_name',
		'ooh_layer_name',
		'ooh_distance',
	];

	const filteredEntities = createFilteredEntities(filterKeys, params.omit, params.filters);
	const formattedFilters = formatFilterValues(filteredEntities);

	const formatDate = (date: Date | null, dataRange: string) => {
		return isCustom(dataRange) && date ? moment(date).format(DEFAULT_DATE_TIME_FORMAT_FILTER) : "";
	};

	const start_d = formatDate(params.start_date, params.data_range);
	const end_d = formatDate(params.end_date, params.data_range);

	const payload = {
		data_range: params.data_range,
		start_date: start_d,
		end_date: end_d,
		report_type: params.report_type,
		year: params.report_year,
		filters: formattedFilters,
	};

	return payload;
}
