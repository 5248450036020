import { ItemGraphic } from "@/interfaces/graphic";
import { ResultElementData, ResultPaginate } from "@/interfaces/paginated";
import { ElementData } from "@/interfaces/persons/v10/person";
import { ConfigPost, ResultContinent, ResultCountry, ResultData } from "@/interfaces/persons/v10/response";
import { ListOption } from "@/interfaces/persons/v10/tabs/pois";
import {
  PersonFilterType,
  PersonGeoKey,
  PersonOohKey,
  PersonPoisKey,
  PersonPosKey,
  PersonPrivateKey,
  PersonStorageKey,
  TaskMap,
} from "@/interfaces/persons/v10/types";
import { ResultP11Paginate } from "@/interfaces/persons/v11/response";
import { ELEMENT_KEY_DEFAULT } from "@/models/persons/v10/DataDefault";
import {
  ListOptionEntity,
  MatchedRouteEntity,
  PersonResourceEntity,
  SelectedDataEntity,
  SluggableEntity,
  determineIfSetDefaultValue,
} from "@/models/persons/v10/Implements";
import { PersonEntity } from "@/models/persons/v10/Person";
import { ButtonActionEntity } from "@/models/persons/v10/Tabs/Pois/ButtonAction";
import { RejectError } from "@/models/persons/v10/response";
import { AxiosGet, AxiosGetData, AxiosPost, ForceGetData, GetData, HasSuccess } from "@/services/axios-service";
import { getFromStorage, removeFromStorage, setToStorage } from "@/services/storage-service";
import { getSpecResultSorted, prepareSortedElementData } from "@/utils/persons/v10/helpers";
import { ComboListOptions, resolveList } from "@/utils/resolveObjectArray";
import { ErrorResponse, catchServiceErrors } from "@/utils/services-global";
import { isUndefined } from "lodash";
import notificationService from "@/services/notification-service";
import { MessageTypes, Notification } from "@/interfaces/proccess";
import store from "@/store";

import ROUTES from "@/api/routes";
const ROUTE = ROUTES.PLANNING;

const notificationData = {
  [MessageTypes.OOH_SUCCESS]: {
    ...notificationService.success,
    message: MessageTypes.OOH_SUCCESS,
  },
  [MessageTypes.OOH_ERROR]: {
    ...notificationService.error,
    message: MessageTypes.OOH_ERROR,
  },
};

class PersonService {
  /**
   * Fetch type: POST paginated
   * @param params
   * @returns
   */
  async fetchGetPaginated(params: { query: string; type: string }) {
    try {
      const url = `${matchedRoutes()[params.type]}${params.query}`;

      const response = await AxiosGet(url);

      if (!HasSuccess(response)) return ErrorResponse(response);

      const result: ResultP11Paginate = GetData(response);

      const paginateResult = parsePaginate(result);

      return paginateResult;
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  /**
   * Fetch type: GET paginated
   * @param params
   * @returns
   */
  async fetchPostPaginated(params: { type: string; postData: any }) {
    try {
      const url = matchedRoutes()[params.type];

      const _filters = params.postData.filters;

      const newFilters = {
        ..._filters,

        // pois
        categoria: _filters.categories,
        categories: undefined,

        subcategoria: _filters.subcategories,
        subcategories: undefined,

        marca: _filters.brands,
        brands: undefined,

        nombre: _filters.names,
        names: undefined,

        // ooh
        ooh_categoria: _filters.ooh_categories,
        ooh_categories: undefined,

        ooh_subcategoria: _filters.ooh_subcategories,
        ooh_subcategories: undefined,

        ooh_marca: _filters.ooh_brands,
        ooh_brands: undefined,

        ooh_nombre: _filters.ooh_names,
        ooh_names: undefined,

        // privates
        privates: undefined,
      };

      params.postData.filters = newFilters;

      const response = await AxiosPost(url, params.postData);

      if (!HasSuccess(response)) return ErrorResponse(response);

      const result: ResultP11Paginate = GetData(response);

      const paginateResult = parsePaginate(result);

      return paginateResult;
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async fetchPaginated(params: { filters?: any; type: string }) {
    try {
      let filter = "";

      if (!isUndefined(params.filters)) {
        filter = preparedURL(params.filters);
      }

      const url = `${matchedRoutes()[params.type]}?${filter}`;

      const response = await AxiosGet(url);

      if (!HasSuccess(response)) return ErrorResponse(response);

      const result: ResultPaginate = GetData(response);

      return result;

      // if (!result) return Promise.resolve([] as ElementData);

      // return Promise.resolve(await resolveResult(params.type, result));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async fetchTableName() {
    try {
      const response = await AxiosGet(ROUTE.PERSONS.GET_TABLE_NAME);

      if (!HasSuccess(response)) return ErrorResponse(response);

      const result: ResultPaginate = GetData(response);

      return result;

      // if (!result) return Promise.resolve([] as ElementData);

      // return Promise.resolve(await resolveResult(params.type, result));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async postPaginated(params: { filters?: any; type: string }) {
    try {
      const url = `${matchedRoutes()[params.type]}`;

      const response = await AxiosPost(url, params.filters);

      if (!HasSuccess(response)) return ErrorResponse(response);

      const result: ResultPaginate = GetData(response);

      return result;
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async fetchData(params: { type: string; setID?: Boolean }) {
    try {
      const url = `${matchedRoutes()[params.type]}`;

      const response = await AxiosGet(url);

      let resultData: ResultContinent[] | ComboListOptions[] | ListOption[] | any[] | SluggableEntity[] =
        GetData(response);

      if (params.type === "countries") {
        resultData = resolveResponseCountry(GetData(response), params.setID);
      }

      if (params.type === "audiente_type") {
        let items: ListOption[] = [];
        let list = resolveList(GetData(response));
        list.map(l => {
          const item = new ListOptionEntity(l.id, String(l.value), [2, 3, 4].includes(l.id));
          items.push(item);
        });
        resultData = items;
      }

      if (["strategies", "use_cases"].includes(params.type)) {
        resultData = resolveSluggable(GetData(response));
      }

      return Promise.resolve(resultData);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async fetchGetWithData(params: { type: string; data: any }) {
    try {
      const url = `${matchedRoutes()[params.type]}`;

      const response = await AxiosGetData(url, params.data);

      const result: ResultData = response.data;

      if (!HasSuccess(response)) return ErrorResponse(response);

      return result;
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async postData(type: string, postData: ConfigPost) {
    try {
      const url = `${matchedRoutes()[type]}`;

      const response = await AxiosPost(url, postData);

      if (!HasSuccess(response)) return ErrorResponse(response);

      let result: ResultData | SluggableEntity[] = ForceGetData(response);

      if (["strategies", "use_cases"].includes(type)) {
        result = resolveSluggable(GetData(response));
      }

      return Promise.resolve(result);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async getGraphicData(key: string, type?: string) {
    let url = `${matchedGraphicRoutes()[key]}`;

    if (type) {
      url = `${matchedGraphicRoutes()[type][key]}`;
    }

    const response = await AxiosGet(url);

    if (!HasSuccess(response)) return ErrorResponse(response);

    const result: ResultData = response.data;

    return Promise.resolve(result);
  }

  async postSavePois(name: string) {
    try {
      const response = await AxiosPost(ROUTE.PERSONS.SAVE_POIS, {
        layer_name: name,
      });
      return response;
    } catch (error) {
      console.error("PersonsService::postSavePois", { error });
      return Promise.reject(new RejectError(error));
    }
  }

  async postSaveGeoFencing(param: { country_code: number; name: string; radio: number }) {
    try {
      const response = await AxiosPost(ROUTE.PERSONS.EXPORT_LAT_LON_LIST, param);
      return response;
    } catch (error) {
      console.error("PersonsService::postSaveGeoFencing", { error });
      return Promise.reject(new RejectError(error));
    }
  }

  async activateOOH(payload: any) {
    try {
      return await AxiosPost(ROUTE.PERSONS.ACTIVATE_OOH, payload);
    } catch (error) {
      console.error("PersonsService::activateOOH", { error });
      return await catchServiceErrors(error);
    }
  }

  async saveDataToStorage(params: {
    person: PersonEntity;
    resources: PersonResourceEntity;
    items: any[];
    pois_actions: ButtonActionEntity[];
  }) {
    try {
      const storedTimeStand = JSON.stringify(new Date().getTime());
      const storedPerson = JSON.stringify(params.person);
      const storedResources = JSON.stringify(params.resources);
      const storedItems = JSON.stringify(params.items);
      const storedActions = JSON.stringify(params.pois_actions);

      setToStorage(PersonStorageKey.STORED_TIMESTAND, storedTimeStand);
      setToStorage(PersonStorageKey.STORED_PERSON, storedPerson);
      setToStorage(PersonStorageKey.STORED_RESOURCE, storedResources);
      setToStorage(PersonStorageKey.STORED_EXPANSION, storedItems);
      setToStorage(PersonStorageKey.STORED_ACTION, storedActions);

      return Promise.resolve({
        timeStand: storedTimeStand,
        person: storedPerson,
        resources: storedResources,
        items: storedItems,
        pois_actions: storedActions,
      });
    } catch (error) {
      console.error("PersonsService::saveDataToStorage", { error });
      return Promise.reject(new RejectError(error));
    }
  }

  async clearDataToStorage() {
    removeFromStorage(PersonStorageKey.STORED_TIMESTAND);
    removeFromStorage(PersonStorageKey.STORED_PERSON);
    removeFromStorage(PersonStorageKey.STORED_RESOURCE);
    removeFromStorage(PersonStorageKey.STORED_EXPANSION);
    removeFromStorage(PersonStorageKey.STORED_ACTION);
  }

  async getSavedDataToStorage() {
    try {
      const storedTimeStand = getFromStorage(PersonStorageKey.STORED_TIMESTAND);
      const storedPerson = getFromStorage(PersonStorageKey.STORED_PERSON);
      const storedResource = getFromStorage(PersonStorageKey.STORED_RESOURCE);
      const storedExpansion = getFromStorage(PersonStorageKey.STORED_EXPANSION);
      const storedAction = getFromStorage(PersonStorageKey.STORED_ACTION);

      return Promise.resolve({
        timeStand: getParsed(storedTimeStand),
        person: getParsed(storedPerson),
        resources: getParsed(storedResource),
        items: getParsed(storedExpansion),
        pois_actions: getParsed(storedAction),
      } as {
        timeStand: number | undefined;
        person: PersonEntity;
        resources: PersonResourceEntity | undefined;
        items: any[] | undefined;
        pois_actions: ButtonActionEntity[] | undefined;
      });
    } catch (error) {
      console.error("PersonsService::saveDataToStorage", { error });
      return Promise.reject(new RejectError(error));
    }
  }

  isValidTimeStand() {
    const storedTimeStand = getFromStorage(PersonStorageKey.STORED_TIMESTAND);

    if (!storedTimeStand) return false;

    return validTimeStand(parseInt(storedTimeStand));
  }

  async showNotificationForOohSuccess(type: MessageTypes) {
    const notification: Notification = notificationData[type];
    await notificationService.CreateNotification(notification);
    notificationService.setCustomNotification(store, notification);
  }
}

export function getParsed(data: string | undefined) {
  if (!data) return undefined;
  return JSON.parse(data);
}

export function validTimeStand(timeStand: number) {
  const currentTime = new Date().getTime();
  const limitTime = 8 * 60 * 60 * 1000; // 8 horas en milisegundos
  //const limitTime = 2 * 60 * 1000; // 2 minutos en milisegundos (para testing)
  return currentTime - timeStand <= limitTime;
}

/**
 * Resolve Result
 * @param type
 * @param result
 * @returns
 */
export async function resolveResult(type: PersonFilterType, result: ResultElementData) {
  return (
    result?.data?.map(r => {
      return prepareElementToResolve(type, r);
    }) || []
  );
}

export function prepareElementToResolve(type: PersonFilterType, element: ElementData) {
  const MATCH_ITEM = new MatchedRouteEntity();

  const { id, value, count, categoria, subcategoria, marca } = MATCH_ITEM.prepareMatchedData(type);

  //MATCH_ITEM.log(type);

  let newElement = new SelectedDataEntity();

  newElement.checked = false;

  if (id) {
    const isSetDefaultKey: Boolean = determineIfSetDefaultValue(type);
    newElement.id = isSetDefaultKey ? ELEMENT_KEY_DEFAULT : element[id];
  }

  if (value) {
    newElement.value = element[value];
    newElement.type = type;
  }

  if (count) {
    newElement.count = element[count];
  }

  if (categoria) {
    newElement.categoria = element[categoria];
  }

  if (subcategoria) {
    newElement.subcategoria = element[subcategoria];
  }

  if (marca) {
    newElement.marca = element[marca];
  }

  let elementData = new SelectedDataEntity(newElement);

  return elementData;
}

export function resolveResponseCountry(response: ResultCountry[], setID: Boolean = false) {
  let resultSorted = getSpecResultSorted(response, [
    "Asia",
    "Europe",
    "Caribbean",
    "North America",
    "Central America",
    "South America",
  ]);

  let resultElements: ResultContinent[] = [];

  const uniqueElements = [...new Set(resultSorted.map(v => v.continent))];

  resultElements = uniqueElements.map(e => {
    return {
      title: e,
      items: prepareSortedElementData(resultSorted, e, "asc", setID),
    } as ResultContinent;
  });

  return resultElements;
}

export function resolveSluggable(obj: TaskMap<string>) {
  return Object.entries(obj).map(([key, value]) => {
    return new SluggableEntity({ id: key, value });
  });
}

export function resolveConfigurableResponse(
  response: any,
  config: { key: string; value: string; count?: string },
): Array<ItemGraphic> {
  return response.map((item: any) => {
    return {
      uniques: item[config.key],
      name: item[config.key],
    } as ItemGraphic;
  });
}

function matchedRoutes() {
  return {
    countries: ROUTE.PERSONS.TAB_FILTERS.COUNTRIES,
    strategies: ROUTE.PERSONS.TAB_FILTERS.STRATEGIES,
    use_cases: ROUTE.PERSONS.TAB_FILTERS.USE_CASES,

    [PersonGeoKey.STATES]: ROUTE.PERSONS.TAB_FILTERS.GEO.STATES,
    [PersonGeoKey.CITIES]: ROUTE.PERSONS.TAB_FILTERS.GEO.CITIES,
    [PersonGeoKey.NEIGHBORHOODS]: ROUTE.PERSONS.TAB_FILTERS.GEO.NEIGHBORHOODS,

    [PersonPoisKey.CATEGORIES]: ROUTE.PERSONS.TAB_FILTERS.POIS.CATEGORIES,
    [PersonPoisKey.SUBCATEGORIES]: ROUTE.PERSONS.TAB_FILTERS.POIS.SUBCATEGORIES,
    [PersonPoisKey.BRANDS]: ROUTE.PERSONS.TAB_FILTERS.POIS.BRANDS,
    [PersonPoisKey.NAMES]: ROUTE.PERSONS.TAB_FILTERS.POIS.NAMES,

    [PersonPrivateKey.PRIVATES]: ROUTE.PERSONS.TAB_FILTERS.PRIVATES,
    post_privates: ROUTE.PERSONS.TAB_FILTERS.POST_PRIVATES,

    [PersonOohKey.OOH_CATEGORIES]: ROUTE.PERSONS.TAB_FILTERS.OOH.CATEGORIES,
    [PersonOohKey.OOH_SUBCATEGORIES]: ROUTE.PERSONS.TAB_FILTERS.OOH.SUBCATEGORIES,
    [PersonOohKey.OOH_BRANDS]: ROUTE.PERSONS.TAB_FILTERS.OOH.BRANDS,
    [PersonOohKey.OOH_NAMES]: ROUTE.PERSONS.TAB_FILTERS.OOH.NAMES,

    [PersonPosKey.CHIPPER_STATES]: ROUTE.PERSONS.TAB_FILTERS.POS.CHIPPER_STATES,
    [PersonPosKey.CHIPPER_CITIES]: ROUTE.PERSONS.TAB_FILTERS.POS.CHIPPER_CITIES,
    [PersonPosKey.CHIPPER_NEIGHBORHOODS]: ROUTE.PERSONS.TAB_FILTERS.POS.CHIPPER_NEIGHBORHOODS,
    [PersonPosKey.CHIPPER_MACRO_CATEGORIES]: ROUTE.PERSONS.TAB_FILTERS.POS.CHIPPER_MACRO_CATEGORIES,
    [PersonPosKey.CHIPPER_CATEGORIES]: ROUTE.PERSONS.TAB_FILTERS.POS.CHIPPER_CATEGORIES,
    [PersonPosKey.CHIPPER_COMPANIES]: ROUTE.PERSONS.TAB_FILTERS.POS.CHIPPER_COMPANIES,
    [PersonPosKey.CHIPPER_BRANDS]: ROUTE.PERSONS.TAB_FILTERS.POS.CHIPPER_BRANDS,
    [PersonPosKey.CHIPPER_NAMES_SKU]: ROUTE.PERSONS.TAB_FILTERS.POS.CHIPPER_NAMES_SKU,
    [PersonPosKey.CHIPPER_STORES_TYPE]: ROUTE.PERSONS.TAB_FILTERS.POS.CHIPPER_STORES_TYPE,

    analyze_pois: ROUTE.PERSONS.ANALIZE.POIS,
    analyze_geo: ROUTE.PERSONS.ANALIZE.GEO,
    analyze_pos: ROUTE.PERSONS.ANALIZE.POS,
    store_attribution: ROUTE.PERSONS.CALCULATE.STORE_ATTRIBUTION,
  };
}

function matchedGraphicRoutes() {
  return {
    pois: {
      categories: ROUTE.PERSONS.GRAPHIC.POIS.CATEGORIES,
      subcategories: ROUTE.PERSONS.GRAPHIC.POIS.SUBCATEGORIES,
      brands: ROUTE.PERSONS.GRAPHIC.POIS.BRANDS,
      states: ROUTE.PERSONS.GRAPHIC.POIS.STATES,
      cities: ROUTE.PERSONS.GRAPHIC.POIS.CITIES,
      neighborhoods: ROUTE.PERSONS.GRAPHIC.POIS.NEIGHBORHOODS,
    },
    geo: {
      states: ROUTE.PERSONS.GRAPHIC.GEO.STATES,
      cities: ROUTE.PERSONS.GRAPHIC.GEO.CITIES,
      neighborhoods: ROUTE.PERSONS.GRAPHIC.GEO.NEIGHBORHOODS,
    },
    reach: {
      geo: ROUTE.PERSONS.CALCULATE.GEO,
      pois: ROUTE.PERSONS.CALCULATE.POIS,
      pos: ROUTE.PERSONS.CALCULATE.POS,
      store_attribution: ROUTE.PERSONS.CALCULATE.STORE_ATTRIBUTION,
    },
  };
}

function preparedURL(filter) {
  let url = "";

  url += "country_code=" + filter.country_code;
  url += "&mode=" + filter.mode || "paginated";
  url += "&limit=" + filter.limit || 25;
  url += "&page=" + filter.page || 1;

  if (filter.filters) {
    for (const [key, value] of Object.entries(filter.filters)) {
      const filterValues: number[] = value as number[];
      filterValues.forEach(f => {
        url += `&filters[${key}][]=${f}`;
      });
    }
  }

  if (filter.search) {
    url += "&search=" + filter.search;
  }

  return url;
}

function parsePaginate(result: ResultP11Paginate) {
  let resultPaginate: ResultPaginate = {
    current_page: result._current_page,
    total: result._total_records,
    data: result.results,
  };
  return resultPaginate;
}

export default new PersonService();
