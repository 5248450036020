import { ButtonAction } from "@/interfaces/persons/v10/tabs/pois";
import { ButtonActionType } from "@/interfaces/persons/v10/types";
import { TranslateResult } from "vue-i18n";

export class ButtonActionEntity implements ButtonAction {
	type: ButtonActionType = ButtonActionType.NONE;
	loading: Boolean = false;
	disabled: Boolean = true;
	icon: string = "";
	title: TranslateResult = "";

	constructor(
		type: ButtonActionType,
		icon: string,
		disabled: Boolean = true
	) {
		this.type = type;
		this.setTitle(type);
		this.icon = icon;
		this.setDisabled(disabled);
	}

	setLoading(loading: Boolean = false) {
		this.loading = loading;
	}

	setDisabled(disabled: Boolean = true) {
		this.disabled = disabled;
	}

	setTitle(type: ButtonActionType) {
		const title = `persons.v10.panelsPois.button.${type}`;
		this.title = title;
	}
}

/**
 * Filtrar los botones de accion por {type}
 * @param actions
 * @param actionType
 * @param callback
 * @returns
 */
export function getFilteredActions(
	actions: ButtonActionEntity[],
	callback: (arg0: ButtonActionType) => unknown
) {
	return actions.filter((a) => {
		return callback(a.type);
	});
}

export function setButtonAction(type: ButtonActionType, icon: string) {
	return new ButtonActionEntity(type, icon, true);
}

export function getPoisActions() {
	return [
		setButtonAction(ButtonActionType.ANALYZE_POIS, "mdi-search-web"),
		setButtonAction(ButtonActionType.ANALYZE_GEO, "mdi-search-web"),
		setButtonAction(ButtonActionType.ANALYZE_POS, "mdi-search-web"),
		setButtonAction(ButtonActionType.CALCULATE_REACH, "mdi-earth"),
		setButtonAction(ButtonActionType.STORE_ATTR, "mdi-table-of-contents"),
		setButtonAction(
			ButtonActionType.ANALYZE_AUDIENCE,
			"mdi-account-group-outline"
		),
		setButtonAction(ButtonActionType.SAVE_POIS, "mdi-download-box-outline"),
		setButtonAction(ButtonActionType.GEO_FENCING, "mdi-map-marker-outline"),
		setButtonAction(
			ButtonActionType.TARGET_AUDIENCE,
			"mdi-file-export-outline"
		),
		setButtonAction(ButtonActionType.ACTIVATE_OOH, "mdi-crosshairs"),
	];
}
