import {
	PersonGeoEntity,
	PersonOohEntity,
	PersonPoisEntity,
	PersonPosEntity,
	PersonPrivateEntity,
} from "@/models/persons/v10/Implements";
import { Geo, GeoFilterData, Person, Pois, PoisFilterData } from "./person";

export type PersonKeyof = keyof Person;

export type GeoFilterDataKeyof = keyof GeoFilterData;
export type PoisFilterDataKeyof = keyof PoisFilterData;

export type GeoKeyof = keyof Geo;

export type PoisKeyof = keyof Pois;

export type PersonFilterKey =
	| PersonKey.GEO
	| PersonKey.POIS
	| PersonKey.PRIVATE
	| PersonKey.OOH
	| PersonKey.POS;

export type PersonFilterType =
	| PersonGeoKey
	| PersonPoisKey
	| PersonOohKey
	| PersonPrivateKey
	| PersonPosKey;

export type PersonEntityType =
	| PersonGeoEntity
	| PersonPoisEntity
	| PersonOohEntity
	| PersonPrivateEntity
	| PersonPosEntity;

export enum PersonKey {
	NONE = "",
	COUNTRY_GLOBAL = "country_global",
	GEO = "geo",
	POIS = "pois",
	PRIVATE = "privates",
	AUDIENTE = "audience",
	STORE_ATTRIBUTION = "store_attribution",
	STRATEGY = "strategy",
	USES_CASES = "uses_cases",
	OOH = "ooh",
	POS = "pos",
}

export enum PersonGeoKey {
	STATES = "states",
	CITIES = "cities",
	NEIGHBORHOODS = "neighborhoods",
}

export enum PersonPoisKey {
	CATEGORIES = "categories",
	SUBCATEGORIES = "subcategories",
	BRANDS = "brands",
	NAMES = "names",
}

export enum PersonPrivateKey {
	PRIVATES = "privates",
}

export enum PersonOohKey {
	OOH_CATEGORIES = "ooh_categories",
	OOH_SUBCATEGORIES = "ooh_subcategories",
	OOH_BRANDS = "ooh_brands",
	OOH_NAMES = "ooh_names",
}

export enum PersonPosKey {
	CHIPPER_STATES = "chipper_states",
	CHIPPER_CITIES = "chipper_cities",
	CHIPPER_NEIGHBORHOODS = "chipper_neighborhoods",
	CHIPPER_MACRO_CATEGORIES = "macro",
	CHIPPER_CATEGORIES = "category",
	CHIPPER_COMPANIES = "company",
	CHIPPER_BRANDS = "brand",
	CHIPPER_NAMES_SKU = "name_sku",
	CHIPPER_STORES_TYPE = "store_type",
	CHIPPER_QUINTILS = "quintils",
}

export enum MatchFilter {
	categories = "categoria",
	subcategories = "subcategoria",
	brands = "marca",
	names = "nombre",
}

export enum TypeFilterKey {
	SELECTED = "selected",
	PRE = "pre",
	AND = "and",
}

export enum GraphicType {
	POIS = "pois",
}

export enum AnalyzeType {
	GEO = "analyze_geo",
	POIS = "analyze_pois",
	POS = "analyze_chipper",
}

export enum GraphicDataType {
	GEO = "geo",
	POIS = "pois",
	REACH = "reach",
}

export enum AnalyzeAudienceType {
	GEO = "geo",
	POIS = "pois",
	POS = "pos",
}

export enum GraphicDataKey {
	GEO = "geo",
	POIS = "pois",

	STATES = "states",
	CITIES = "cities",
	NEIGHBORHOODS = "neighborhoods",

	CATEGORIES = "categories",
	SUBCATEGORIES = "subcategories",
	BRANDS = "brands",
}

export enum ButtonActionType {
	NONE = "",
	ANALYZE_GEO = "analyze_geo",
	ANALYZE_POIS = "analyze_pois",
	ANALYZE_POS = "analyze_pos",
	CALCULATE_REACH = "calculate_reach",
	STORE_ATTR = "store_attribution",
	ANALYZE_AUDIENCE = "analyze_audience",
	SAVE_POIS = "save_pois",
	GEO_FENCING = "geo_fencing",
	TARGET_AUDIENCE = "target_audience",
	GEO_REPORT = "geo_report",
	ACTIVATE_OOH = "activate_ooh",
}

export enum FilterKeySlice {
	states = -2,
	cities = -1,
	neighborhoods = 0,
	
	categories = -3,
	subcategories = -2,
	brands = -1,
	names = 0,
	
	ooh_categories = -3,
	ooh_subcategories = -2,
	ooh_brands = -1,
	ooh_names = 0,

	chipper_states = -8,
	chipper_cities = -7,
	chipper_neighborhoods = -6,
	macro = -5,
	category = -4,
	company = -3,
	brand = -2,
	name_sku = -1,
	store_type = 0,

	privates = 0,
}

export enum PersonTab {
	POIS = 0,
	AUDIENCE = 1,
	STORE_ATTRIBUTION = 2,
}

export enum ActivePanelTab {
	/** Persons */
	NONE = NaN,
	COUNTRY_GLOBAL = 0,
	STRATEGY = 1,
	USES_CASES = 2,
	GEO = 3,
	POIS = 4,
	OOH = 5,
	PRIVATES = 6,
	POS = 7,

	/** Schedule Report tabs */
	SCHEDULER_REPORT = 0,
	SAVE_SCHEDULE = 1,
	REPORT_TYPE = 2,
	REPORT_FILTERS = 3,
	REPORT_DIMENSIONS = 4,
	REPORT_METRICS = 5,
}

export enum TotalType {
	TOTAL = "total",
	SHOWN = "shown",
}

export enum SelectedType {
	UNSELECTED = "unselected",
	SELECTED = "selected",
	CHECKED = "checked",
}

export enum PersonStorageKey {
	STORED_TIMESTAND = "stored_timestamp",
	STORED_PERSON = "stored_person",
	STORED_RESOURCE = "stored_resource",
	STORED_EXPANSION = "stored_expansion",
	STORED_ACTION = "stored_action",
}

export enum StoreAttributionType {
	COOKIE = "cookie",
	MAID = "maid",
	HASHED = "hashed",
}

/**
 * create_audience: 					Create audience
 * identify_pois_ooh: 					Identify POIs /OOH
 *
 */
export enum PersonStrategyKey {
	NONE = "",
	CREATE_AUDIENCE = "create_audience",
	IDENTIFY_POIS_OOH = "identify_pois_ooh",
}

export enum PersonStrategyID {
	NONE = NaN,
	CREATE_AUDIENCE = 1,
	IDENTIFY_POIS_OOH = 2,
}

/**
 * 1. SEEN_CERTAIN_AREA
 * api: Individuals seen in certain area
 * lang: Individuals in a specific region (department, city, or neighborhood)
 * 
 * 2. SEEN_CLOSE_TO_POI_OOH
 * api: Individuals seen close to a POI /OOH
 * lang: Individuals near a Point of Interest or Out of Home billboards
 * 
 * 4. POIS_CLOSE_CERTAIN_OOHS
 * api: POIs / close to certain OOHs
 * lang: Identify POIs/OOHs
 * 
 * 5. SEEN_POS_AREA
 * api: Users identified in areas based POS (point of Sale) data
 * lang: Users identified in areas based POS (point of Sale) data
 * 
 * 6. STORE_ATTRIBUTION_REPORT
 * api: Store attribution report
 * lang: Individuals targeted by a campaign and afterwards seen near a Point of Interest or Out of Home billboard (Store Attribution)
 */

export enum PersonUsesCasesKey {
	NONE = "",
	SEEN_CERTAIN_AREA = "individuals_seen_in_certain_area",
	SEEN_CLOSE_TO_POI_OOH = "individuals_seen_close_to_a_poi_ooh",
	POIS_CLOSE_CERTAIN_OOHS = "pois_close_to_certain_oohs",
	SEEN_POS_AREA = "users_identified_in_areas_based_pos_point_of_sale_data",
	STORE_ATTRIBUTION_REPORT = "store_attribution_report",
}

export enum PersonUsesCasesTab {
	NONE = NaN,
	SEEN_CERTAIN_AREA = 1,
	SEEN_CLOSE_TO_POI_OOH = 2,
	POIS_CLOSE_CERTAIN_OOHS = 4,
	SEEN_POS_AREA = 5,
	STORE_ATTRIBUTION_REPORT = 6,
}

export enum TotalPoisType {
	NONE = "",
	PUBLIC = "public",
	PRIVATE = "private",
	OOH = "ooh",
	POS = "pos",
}

export enum StrategyTitleType {
	NONE = "",
	STRATEGY_AUDIENCES = "strategy.audiences",
	STRATEGY_LOCATIONS = "strategy.locations",
	STRATEGY_REPORTS = "strategy.reports",
}

export enum StrategyTitleID {
	"" = "",
	"create_audience" = "strategy.audiences",
	"identify_pois_ooh" = "strategy.locations",
	"store_attribution_report" = "strategy.reports",
}

export enum MatchedRouteType {
	INDEX = "index",
	KEY = "key",
	VALUE = "value",
	KEY1 = "key_1",
	KEY2 = "key2",
	KEY3 = "key3",
}

export type TaskMap<T> = {
	[key: string]: T;
};
