import { ResultPaginate } from "@/interfaces/paginated";
import ModelsService from "@/services/models-service";
import { catchError } from "@/store/Util";
import notificationService from "@/services/notification-service";
import { Notification } from "@/interfaces/proccess";
import { resolveList, resolveListParams } from "@/utils/resolveObjectArray";
import { ModelsDataCreate } from "@/interfaces/models";

export const ModelsModule = {
	namespaced: true,
	state: () => ({
		result_paginate: {} as ResultPaginate,
	}),
	mutations: {
		SET_RESULT_PAGINATED(
			state,
			_result_paginate: ResultPaginate = {} as ResultPaginate
		) {
			state.result_paginate = _result_paginate;
		}
	},
	getters: {
		result_paginate(state): ResultPaginate {
			return state.result_paginate;
		},
	},
	actions: {
		async paginated({ commit }, params) {
			try {
				const response = await ModelsService.paginated(params);
				commit("SET_RESULT_PAGINATED", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async metricsList({ commit }) {
			try {
				const response = await ModelsService.metricsList();
				return await Promise.resolve(resolveListParams(response, "extra", "description"));
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async bidModelsList({ commit }) {
			try {
				const response = await ModelsService.bidModelsList();
				return await Promise.resolve(resolveListParams(response, "bid_model_id", "bid_model_name"));
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async create({ commit }, params: {data: ModelsDataCreate}) {
			try {
				const response = await ModelsService.create(params.data);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
	},
};