import store from "@/store";
//@ts-ignore
import defineAbility from "@/config/ability.ts";
import { isValidToken } from "@/services/jwt-service";
import { isProd } from "@/services/process-service";

export const moduleAuth: any = store.state["auth"];

export const moduleProfile: any = store.state["profile"];

export const pathname = window.location.pathname;

export const MATCHED_ROLE_ROUTE = {
	"account-report": "Report",
	"account-personas": "PersonsIndex",
	"retail_media": "CampaignsIndex",
	"route-default": "Dashboard",
};

export const ROLE_REPORT: string = "account-report";
export const ROLE_PERSONS: string = "account-personas";
export const ROLE_RETAIL_MEDIA: string = "retail_media";
export const ROUTE_DEFAULT: string = "route-default";

export function debugLogAuth() {
	if (isProd()) return;
	const loggedIn = Boolean(moduleAuth?.loggedIn);
	const isValid = isValidToken(moduleAuth?.token);
	console.debug("[DEBUG] debugLogAuth", { loggedIn, isValid, token: moduleAuth?.token });
}

export function getModuleAuth(): any {
	return moduleAuth;
}

export function getModuleProfile(): any {
	return moduleProfile;
}

export function isAuthenticated(): boolean {
	//debugLogAuth();
	return Boolean(moduleAuth?.loggedIn) && isValidToken(moduleAuth?.token);
}

export async function getProfile(): Promise<any> {
	if (!moduleProfile?.profile) {
		await fetchProfile();
	}
	return moduleProfile?.profile;
}

export async function fetchProfile() {
	return await store.dispatch("profile/fetchProfile");
}

export async function getRoles(): Promise<Array<any>> {
	return (await getProfile())?.roles ?? [];
}

export async function hasRole(roleName: string): Promise<boolean> {
	return (await getRoles()).some((r) => r.name === roleName);
}

export async function hasRoleExcluded(
	excludes: Array<string>
): Promise<boolean> {
	return (await getRoles()).some((r) => excludes.includes(r.name));
}

export function getSplitedPath(): Array<string> {
	return pathname.split("/");
}

export function isRoute(routePath: string, index: number): boolean {
	return getSplitedPath()[index] === routePath;
}

export async function canAccess(
	action: string,
	subject: string
): Promise<boolean> {
	const ability = defineAbility();
	return ability.can(action, subject);
}
