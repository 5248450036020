import {
  Advertiser,
  AdvertiserDataCreate,
  AdvertiserDataUpdate,
  AdvertiserList,
  AdvertiserListCreativo,
  Category,
} from "@/interfaces/advertiser";
import { TradeMark } from "@/interfaces/advertiser";
import { ResultPaginate } from "@/interfaces/paginated";
import AdvertiserService from "@/services/advertiser-service";
import { CatcherError, catchError } from "../Util";
import notificationService from "@/services/notification-service";
import { Notification } from "@/interfaces/proccess";
import { resolveList, resolveListAdvertiserCreativo } from "@/utils/resolveObjectArray";
import { mergeElements } from "@/utils/filter-global";
import { PaginatedEntity } from "@/models/Paginated";
import { orderBy } from "lodash";

export const AdvertiserModule = {
  namespaced: true,
  state: () => ({
    result_paginate: new PaginatedEntity(),
    paginated_advertiser_users: new PaginatedEntity(),
    categories: [] as Category[],
    advertiser: {} as Advertiser,
    advertisers: [] as Advertiser[],
    advertisers_list: [] as AdvertiserList[],
    advertisers_list_creativo: [] as AdvertiserListCreativo[],
    trade_marks: [] as TradeMark[],
    users: [] as any[],
  }),
  mutations: {
    SET_USERS(state, _users: any[] = []) {
      state.users = _users;
    },
    REMOVE_USER(state, userId) {
      state.paginated_advertiser_users.data = state.paginated_advertiser_users.data.filter(user => user.id !== userId);
      state.paginated_advertiser_users.data = mergeElements(state.paginated_advertiser_users.data);
      state.paginated_advertiser_users.total = state.paginated_advertiser_users.data.length;
    },
    CLEAR_USERS(state) {
      state.paginated_advertiser_users.data = [];
      state.paginated_advertiser_users.total = 0;
    },

    SET_RESULT_PAGINATED(state, _result_paginate: ResultPaginate = {} as ResultPaginate) {
      state.result_paginate = _result_paginate;
    },
    SET_PAGINATED_ADVERTISER_USERS(state, paginated: PaginatedEntity = new PaginatedEntity()) {
      state.paginated_advertiser_users = paginated;
      const newData = mergeElements(paginated.data);
      state.paginated_advertiser_users.data = newData;
    },
    ADD_USERS_TO_ADVERTISER(state, newUsers: any[] = []) {
      const orderedNewUsers = orderBy(newUsers, ["name"], ["desc"]);
      const newData = mergeElements(orderedNewUsers, state.paginated_advertiser_users.data);
      state.paginated_advertiser_users.data = newData;
      state.paginated_advertiser_users.total = newData.length;
    },
    SET_CATEGORIES(state, _categories: Category[] = []) {
      state.categories = _categories;
    },
    SET_ADVERTISER(state, _advertiser: Advertiser = {} as Advertiser) {
      state.advertiser = _advertiser;
    },
    SET_ADVERTISERS(state, _advertisers: Advertiser[] = [] as Advertiser[]) {
      state.advertisers = _advertisers;
    },
    SET_ADVERTISERS_LIST(state, _advertisers_list: AdvertiserList[] = [] as AdvertiserList[]) {
      state.advertisers_list = _advertisers_list;
    },
    SET_ADVERTISERS_LIST_CREATIVO(
      state,
      _advertisers_list_creativo: AdvertiserListCreativo[] = [] as AdvertiserListCreativo[],
    ) {
      state.advertisers_list_creativo = _advertisers_list_creativo;
    },
  },
  getters: {
    result_paginate(state): ResultPaginate {
      return state.result_paginate;
    },
    users(state) {
      return state.users;
    },
    getAdvertiserUser(state) {
      return state.paginated_advertiser_users;
    },
    getAdvertiserUserIds(state) {
      return state.paginated_advertiser_users.data.map(user => user.id);
    },
  },
  actions: {
    async selectUsers({ commit }, users: any[]) {
      commit("SET_USERS", users);
      return Promise.resolve(users);
    },

    deleteUser({ commit }, userId) {
      commit("REMOVE_USER", userId);
    },

    async setPaginatedUsersToAdvertiser({ commit }, data: PaginatedEntity = new PaginatedEntity()) {
      commit("SET_PAGINATED_ADVERTISER_USERS", data);
      return Promise.resolve(data);
    },

    async addNewUsersToAdvertiser({ commit }, newUsers: any[]) {
      commit("ADD_USERS_TO_ADVERTISER", newUsers);
      return Promise.resolve(newUsers);
    },

    async paginated({ commit }, params) {
      try {
        const response = await AdvertiserService.paginated(params);
        commit("SET_RESULT_PAGINATED", response);
        return await Promise.resolve(response);
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    clearUsers({ commit }) {
      commit("CLEAR_USERS");
    },

    async download({}, params) {
      try {
        await AdvertiserService.download(params);
        return await Promise.resolve({});
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async getCategories({ commit }) {
      try {
        const response = await AdvertiserService.categories();
        commit("SET_CATEGORIES", response);
        return await Promise.resolve(response);
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async create({}, advertiser: AdvertiserDataCreate) {
      try {
        const response = await AdvertiserService.create(advertiser);
        notificationService.notifySuccess(this, {
          to: "AdvertisersIndex",
        } as Notification);
        return Promise.resolve(response);
      } catch (error) {
        catchError(this, error);
        return Promise.reject(error);
      }
    },
    async update({ commit }, params: { advertiser: AdvertiserDataUpdate; id: number }) {
      try {
        const response = await AdvertiserService.update(params.advertiser, params.id);
        //commit("SET_ADVERTISER", response);
        notificationService.notifySuccess(this, {
          to: "AdvertisersIndex",
        } as Notification);
        return await Promise.resolve(response);
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async show({ commit }, id: number) {
      try {
        const response = await AdvertiserService.show(id);
        commit("SET_ADVERTISER", response);
        return await Promise.resolve(response);
      } catch (error) {
        CatcherError(this.dispatch, error, {
          to: "AdvertisersIndex",
        });
        return await Promise.reject(error);
      }
    },
    async list({ commit }, params) {
      try {
        const response = await AdvertiserService.list(params);
        commit("SET_ADVERTISERS_LIST", resolveList(response));
        return await Promise.resolve(resolveList(response));
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async listCreativo({ commit }, params) {
      try {
        const response = await AdvertiserService.listCreativo(params);
        // commit("SET_ADVERTISERS_LIST_CREATIVO", resolveListAdvertiserCreativo(response));
        return await Promise.resolve(resolveListAdvertiserCreativo(response));
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async all({ commit }, params) {
      try {
        const response = await AdvertiserService.all(params);
        commit("SET_ADVERTISERS_LIST", response);
        return await Promise.resolve(response);
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async searchAdvertiser({}, params) {
      try {
        const response = await AdvertiserService.searchAdvertiser(params);
        return await Promise.resolve(response.data);
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async makersList({ commit }, params) {
      try {
        const response = await AdvertiserService.makers(params);
        return await Promise.resolve(response);
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
    async tradeMarksList({ commit }, params) {
      // Añadi esto
      try {
        const response = await AdvertiserService.tradeMarks(params);
        return Promise.resolve(response);
      } catch (error) {
        catchError(this, error);
        return Promise.reject(error);
      }
    },
  },
};
