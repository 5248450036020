import { LIMIT, Mode } from "@/interfaces/persons/v10/query/global";
import {
	Paginate,
	PaginateData,
	PostData,
} from "@/interfaces/persons/v10/query/pois";
import {
	PersonOohKey,
	PersonPoisKey,
	PersonPosKey,
	PersonPrivateKey,
} from "@/interfaces/persons/v10/types";

const FILTER_RESET_MAPPING: Record<string, string[]> = {

	/**
	 * POIS
	 */
	[PersonPoisKey.CATEGORIES]: [
		PersonPoisKey.SUBCATEGORIES,
		PersonPoisKey.BRANDS,
		PersonPoisKey.NAMES,
	],
	[PersonPoisKey.SUBCATEGORIES]: [PersonPoisKey.BRANDS, PersonPoisKey.NAMES],
	[PersonPoisKey.BRANDS]: [PersonPoisKey.NAMES],

	/**
	 * OOH
	 */
	[PersonOohKey.OOH_CATEGORIES]: [
		PersonOohKey.OOH_SUBCATEGORIES,
		PersonOohKey.OOH_BRANDS,
		PersonOohKey.OOH_NAMES,
	],
	[PersonOohKey.OOH_SUBCATEGORIES]: [
		PersonOohKey.OOH_BRANDS,
		PersonOohKey.OOH_NAMES,
	],
	[PersonOohKey.OOH_BRANDS]: [PersonOohKey.OOH_NAMES],

	/**
	 * POS
	 */
	[PersonPosKey.CHIPPER_STATES]: [
		PersonPosKey.CHIPPER_CITIES,
		PersonPosKey.CHIPPER_NEIGHBORHOODS,
		PersonPosKey.CHIPPER_MACRO_CATEGORIES,
		PersonPosKey.CHIPPER_CATEGORIES,
		PersonPosKey.CHIPPER_COMPANIES,
		PersonPosKey.CHIPPER_BRANDS,
		PersonPosKey.CHIPPER_NAMES_SKU,
		PersonPosKey.CHIPPER_STORES_TYPE,
	],
	[PersonPosKey.CHIPPER_CITIES]: [
		PersonPosKey.CHIPPER_NEIGHBORHOODS,
		PersonPosKey.CHIPPER_MACRO_CATEGORIES,
		PersonPosKey.CHIPPER_CATEGORIES,
		PersonPosKey.CHIPPER_COMPANIES,
		PersonPosKey.CHIPPER_BRANDS,
		PersonPosKey.CHIPPER_NAMES_SKU,
		PersonPosKey.CHIPPER_STORES_TYPE,
	],
	[PersonPosKey.CHIPPER_NEIGHBORHOODS]: [
		PersonPosKey.CHIPPER_MACRO_CATEGORIES,
		PersonPosKey.CHIPPER_CATEGORIES,
		PersonPosKey.CHIPPER_COMPANIES,
		PersonPosKey.CHIPPER_BRANDS,
		PersonPosKey.CHIPPER_NAMES_SKU,
		PersonPosKey.CHIPPER_STORES_TYPE,
	],
	[PersonPosKey.CHIPPER_MACRO_CATEGORIES]: [
		PersonPosKey.CHIPPER_CATEGORIES,
		PersonPosKey.CHIPPER_COMPANIES,
		PersonPosKey.CHIPPER_BRANDS,
		PersonPosKey.CHIPPER_NAMES_SKU,
		PersonPosKey.CHIPPER_STORES_TYPE,
	],
	[PersonPosKey.CHIPPER_CATEGORIES]: [
		PersonPosKey.CHIPPER_COMPANIES,
		PersonPosKey.CHIPPER_BRANDS,
		PersonPosKey.CHIPPER_NAMES_SKU,
		PersonPosKey.CHIPPER_STORES_TYPE,
	],
	[PersonPosKey.CHIPPER_COMPANIES]: [
		PersonPosKey.CHIPPER_BRANDS,
		PersonPosKey.CHIPPER_NAMES_SKU,
		PersonPosKey.CHIPPER_STORES_TYPE,
	],
	[PersonPosKey.CHIPPER_BRANDS]: [
		PersonPosKey.CHIPPER_NAMES_SKU,
		PersonPosKey.CHIPPER_STORES_TYPE,
	],
	[PersonPosKey.CHIPPER_NAMES_SKU]: [PersonPosKey.CHIPPER_STORES_TYPE],
};

export class PostDataEntity implements PostData {
	paginateData: PaginateDataEntity = new PaginateDataEntity();

	constructor() {}

	// SETTERS //

	setSearch(filterName: string, search: string | null) {
		this.paginateData[filterName].page = 1;
		this.paginateData[filterName].search = search;
		this.paginateData[filterName].searching = true;
	}

	setSearching(filterName: string, searching: Boolean = false) {
		this.paginateData[filterName].searching = searching;
	}

	setCurrentMaxPage(filterName: string, maxPage: number = 1) {
		this.paginateData[filterName].max_page = maxPage;
	}

	setCurrentPage(filterName: string, page: number = 1) {
		this.paginateData[filterName].page = page;
	}

	resetAllPage(filterName: string, page: number = 1) {
		let queryData: PaginateEntity | undefined =
			this.paginateData[filterName];
		if (!queryData) return;
		queryData.page = page;
		queryData.max_page = page;
	}

	setFetchingMaxPage(filterName: string, active: Boolean = false) {
		this.paginateData[filterName].setFetchingMaxPage(active);
	}

	setMode(filterName: string, mode: Mode) {
		this.paginateData[filterName].mode = mode;
	}

	// GETTERS //

	async getCurrentPage(filterName: string) {
		return this.paginateData[filterName].page;
	}

	// ACTIONS

	async resetFilters(filterName: string) {
		const filterValues = FILTER_RESET_MAPPING[filterName];
		if (filterValues) {
			filterValues.forEach((key) => {
				this.paginateData[key] = new PaginateEntity();
			});
		}
	}

	async increasePage(filterName: string) {
		this.paginateData[filterName].page++;
		this.paginateData[filterName].max_page++;
	}

	async decreasePage(filterName: string) {
		this.paginateData[filterName].page--;
	}

	isFetchingMaxPage(filterName: string): Boolean {
		return this.paginateData[filterName].isFetchingMaxPage;
	}

	getQuery(filterName: string) {
		const paginateData: PaginateEntity = this.paginateData[filterName];
		return paginateData.getQuery();
	}
}

export class PaginateDataEntity implements PaginateData {
	[PersonPoisKey.CATEGORIES]: PaginateEntity = new PaginateEntity();
	[PersonPoisKey.SUBCATEGORIES]: PaginateEntity = new PaginateEntity();
	[PersonPoisKey.BRANDS]: PaginateEntity = new PaginateEntity();
	[PersonPoisKey.NAMES]: PaginateEntity = new PaginateEntity();

	[PersonOohKey.OOH_CATEGORIES]: PaginateEntity = new PaginateEntity();
	[PersonOohKey.OOH_SUBCATEGORIES]: PaginateEntity = new PaginateEntity();
	[PersonOohKey.OOH_BRANDS]: PaginateEntity = new PaginateEntity();
	[PersonOohKey.OOH_NAMES]: PaginateEntity = new PaginateEntity();

	[PersonPrivateKey.PRIVATES]: PaginateEntity = new PaginateEntity();

	[PersonPosKey.CHIPPER_STATES]: PaginateEntity = new PaginateEntity();
	[PersonPosKey.CHIPPER_CITIES]: PaginateEntity = new PaginateEntity();
	[PersonPosKey.CHIPPER_NEIGHBORHOODS]: PaginateEntity = new PaginateEntity();
	[PersonPosKey.CHIPPER_MACRO_CATEGORIES]: PaginateEntity =
		new PaginateEntity();
	[PersonPosKey.CHIPPER_CATEGORIES]: PaginateEntity = new PaginateEntity();
	[PersonPosKey.CHIPPER_COMPANIES]: PaginateEntity = new PaginateEntity();
	[PersonPosKey.CHIPPER_BRANDS]: PaginateEntity = new PaginateEntity();
	[PersonPosKey.CHIPPER_NAMES_SKU]: PaginateEntity = new PaginateEntity();
	[PersonPosKey.CHIPPER_STORES_TYPE]: PaginateEntity = new PaginateEntity();

	constructor() {}
}

export class PaginateEntity implements Paginate {
	mode: Mode = Mode.PAGINATED;
	limit: number = LIMIT;
	page: number = 1;
	max_page: number = 1;
	search: string | null = null;
	searching: Boolean = false;
	isFetchingMaxPage: Boolean = false;

	constructor(mode?: Mode) {
		if (mode) {
			this.mode = mode;
		}
	}

	async setMode(mode: Mode) {
		this.mode = mode;
	}

	setFetchingMaxPage(active: Boolean = false) {
		this.isFetchingMaxPage = active;
	}

	getPage() {
		if (this.isFetchingMaxPage) return 1;
		return this.page !== this.max_page ? this.max_page : this.page;
	}

	getLimit() {
		if (this.isFetchingMaxPage) return this.max_page * this.limit;
		return this.limit;
	}

	getQuery() {
		return {
			mode: this.mode,
			limit: this.getLimit(),
			page: this.getPage(),
			search: this.search,
		};
	}
}
