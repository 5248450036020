import { AxiosGet, GetData, GetErrors, GetMessage, AxiosPost } from "@/services/axios-service";
import { catchServiceErrors } from "@/utils/services-global";
import { isEmpty } from "lodash";
import { ColorsSetting } from "../interfaces/colors";
import ROUTES from "@/api/routes";

const ROUTE = ROUTES.USER;

class ColorServices {
  async fetchSetColor(params: ColorsSetting) {
    try {
      let url = ROUTE.SET_COLOR_ROUTE;
      let formData = this.parseData(params);
      const response = await AxiosPost(`${url}`, formData, true);
      return Promise.resolve(GetData(response));
    } catch (error) {
      console.log(`ERROR ${error}`);
      return await catchServiceErrors(error);
    }
  }

  parseData(params: ColorsSetting) {
    let formData = new FormData();
    for (let clave in params) {
      formData.append(clave, params[clave]);
    }
    return formData;
  }
}

export default new ColorServices();
