import { CreativeFilters, TagCheck, CreativeFiltersAssoc } from "@/interfaces/creative";
import { AssociationDataCreate } from "@/interfaces/creativeAssociation";
import {
  AxiosDelete,
  AxiosGet,
  AxiosPatch,
  AxiosPost,
  AxiosPut,
  GetData,
  GetErrors,
  GetMessage,
} from "./axios-service";
import ROUTES from "@/api/routes";

const ROUTE = ROUTES.CREATIVES;

const ENTITY: string = "creative";

export const CREATIVE_SIZES_ROUTE = "/api/list/creative_sizes";
export const CREATIVE_TEMPLATES_ROUTE = "/api/list/creative_templates";
export const CREATIVE_TYPES_ROUTE = "/api/list/creative_types";
export const CREATIVE_CATEGORIES_ROUTE = "/api/list/advertiser_categories";
export const CREATIVE_MIME_TYPES_ROUTE = "/api/list/mime_types";
export const CREATIVE_CREATIVE_RULES_ROUTE = "/api/list/creative_rules";
export const CREATIVE_EXPANDABLE_TYPES = "/api/list/expandable_types";
export const CREATIVE_EXPANDABLE_DIRECTIONS = "/api/list/expandable_directions";
export const CREATIVE_IN_BANNER_VIDEOS = "/api/list/inbanner_videos";
export const CREATIVE_VENDORS = "/api/list/creative_vendors";
export const CREATIVE_ADDONS = "/api/creative_addons";
export const CREATIVE_ASSETS = "/api/creative_assets";
export const CREATIVE_HTML_ASSETS = "/api/html_assets";
export const CREATIVE_VIDEO_ASSET = "/api/video_assets";
export const CREATIVE_VIDEO_API = "/api/list/video_apis";
export const CREATIVE_VIDEO_BID_RATE = "/api/list/video_bit_rates";
export const ASSET_TYPES = "/api/list/asset_types";
export const DETECT_ATTRIBUTES = "/api/list/extract_vast_attributes";

import { isNull, isUndefined } from "lodash";
import { Paginated, SortingOption } from "@/interfaces/paginated";
import { catchServiceErrors, downloadFile, modes, prepareParams } from "@/utils/services-global";

class CreativeService {
  async paginated(params: {
    paginated?: Paginated;
    filters?: CreativeFilters;
    options?: SortingOption;
    fields?: Array<string>;
  }) {
    try {
      let filter = "";

      if (!isUndefined(params.filters)) {
        filter = getFilters(params.filters);
      }

      const url = await prepareParams({
        route: ROUTE.CREATIVE_ROUTE,
        entity: ENTITY,
        mode: modes.PAGINATED,
        filter: filter,
        ...params,
      });

      const response = await AxiosGet(url);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async paginatedAssoLine(params: {
    paginated?: Paginated;
    filters?: CreativeFilters;
    options?: SortingOption;
    fields?: Array<string>;
  }) {
    try {
      let filter = "";

      if (!isUndefined(params.filters)) {
        filter = getFiltersAssoc(params.filters);
      }
      const url = await prepareParams({
        route: ROUTE.CREATIVE_LINE_ASSOCIATIONS_ROUTE,
        entity: ENTITY,
        mode: modes.PAGINATED,
        filter: filter,
        ...params,
      });
      const response = await AxiosGet(url);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async download(params: {
    paginated?: Paginated;
    filters?: CreativeFilters;
    options?: SortingOption;
    fields: Array<string>;
  }) {
    try {
      let filter = "";

      if (!isUndefined(params.filters)) {
        filter = getFilters(params.filters);
      }

      await downloadFile({
        entity: ENTITY,
        route: ROUTE.CREATIVE_ROUTE,
        filter,
        mode: modes.DOWNLOAD,
        ...params,
      });

      return Promise.resolve({});
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async list(params: { filters?: CreativeFilters; options?: SortingOption }) {
    try {
      let filter = "";

      if (!isUndefined(params.filters)) {
        filter = getFilters(params.filters);
      }

      const url = await prepareParams({
        route: ROUTE.CREATIVE_ROUTE,
        entity: ENTITY,
        mode: modes.LIST,
        filter: filter,
        ...params,
      });

      const response = await AxiosGet(url);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async creativeSizes() {
    try {
      const response = await AxiosGet(`${CREATIVE_SIZES_ROUTE}?mode=list`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async creativeTemplates() {
    try {
      const response = await AxiosGet(`${CREATIVE_TEMPLATES_ROUTE}?mode=list`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async creativeTypes() {
    try {
      const response = await AxiosGet(`${CREATIVE_TYPES_ROUTE}?mode=list`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async advertiserCategories() {
    try {
      const response = await AxiosGet(`${CREATIVE_CATEGORIES_ROUTE}?mode=list`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async mimeTypes() {
    try {
      const response = await AxiosGet(`${CREATIVE_MIME_TYPES_ROUTE}?mode=list`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async audioTypes() {
    try {
      const response = await AxiosGet(`${ROUTE.CREATIVE_AUDIO_MIME_ROUTE}?mode=list`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async creativeRules() {
    try {
      const response = await AxiosGet(`${CREATIVE_CREATIVE_RULES_ROUTE}?mode=list`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async expandableTypes() {
    try {
      const response = await AxiosGet(`${CREATIVE_EXPANDABLE_TYPES}?mode=list`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async expandableDirections() {
    try {
      const response = await AxiosGet(`${CREATIVE_EXPANDABLE_DIRECTIONS}?mode=list`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async inBannerVideos() {
    try {
      const response = await AxiosGet(`${CREATIVE_IN_BANNER_VIDEOS}?mode=list`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async vendors() {
    try {
      const response = await AxiosGet(`${CREATIVE_VENDORS}?mode=list`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async videoApi() {
    try {
      const response = await AxiosGet(`${CREATIVE_VIDEO_API}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async videoBidRate() {
    try {
      const response = await AxiosGet(`${CREATIVE_VIDEO_BID_RATE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async addons(params: { filters?: CreativeFilters }) {
    try {
      let filter = "";

      if (!isUndefined(params.filters)) {
        filter = getFilters(params.filters);
      }

      const response = await AxiosGet(`${CREATIVE_ADDONS}?${filter}&mode=list`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async assetTypes() {
    try {
      const response = await AxiosGet(`${ASSET_TYPES}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async assets(params: { filters?: CreativeFilters }) {
    try {
      let filter = "";

      if (!isUndefined(params.filters)) {
        filter = getFilters(params.filters);
      }
      const response = await AxiosGet(`${CREATIVE_ASSETS}?${filter}&mode=all`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async creativeAssets(params: any) {
    if (params.thumbnail) {
      try {
        const response = await AxiosPost(`${CREATIVE_ASSETS}`, params.formData, true);
        return Promise.resolve(GetData(response));
      } catch (error) {
        return Promise.reject({
          success: false,
          message: GetMessage(error),
          errors: GetErrors(error),
        });
      }
    } else {
      try {
        let response: any;
        switch (params.templateId) {
          case 1:
          case 4:
          case 5:
          case 13:
            response = await AxiosPost(`${CREATIVE_ASSETS}`, params.formData, true);
            break;
          case 21:
            response = await AxiosPost(`${CREATIVE_HTML_ASSETS}`, params.formData, true);
            break;
          case 3:
          case 6:
          case 20:
            response = await AxiosPost(`${CREATIVE_VIDEO_ASSET}`, params.formData, true);
            break;
          default:
            response = await AxiosPost(`${CREATIVE_ASSETS}`, params.formData, true);
            break;
        }
        return Promise.resolve(GetData(response));
      } catch (error) {
        return Promise.reject({
          success: false,
          message: GetMessage(error),
          errors: GetErrors(error),
        });
      }
    }
  }

  async validateTag(tag: TagCheck) {
    try {
      const response = await AxiosPost(`${ROUTE.CREATIVE_TAG_ROUTE}`, tag);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async detectAttributes(params: any) {
    try {
      const response = await AxiosPost(`${DETECT_ATTRIBUTES}`, params);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  /**
   * Creative
   * @param params
   * @returns
   */
  async CreateNewCreative(params: any) {
    try {
      const response = await AxiosPost(`${ROUTE.CREATIVE_ROUTE}`, params);
      return Promise.resolve(GetData(response));
    } catch (error) {
      console.error("CreativeService::CreateNewCreative", {
        error: error,
      });
      return await catchServiceErrors(error);
    }
  }

  /**
   * Creative
   * @param params
   * @returns
   */
  async UpdateCreative(creative: any) {
    try {
      const response = await AxiosPatch(`${ROUTE.CREATIVE_ROUTE}/${creative.id}`, creative);
      return Promise.resolve(GetData(response));
    } catch (error) {
      console.error("CreativeService::CreateUpdateCreative", {
        error: error,
      });
      return await catchServiceErrors(error);
    }
  }

  async show(id: number) {
    try {
      const response = await AxiosGet(`${ROUTE.CREATIVE_ROUTE}/${id}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async associateLineItem(association: AssociationDataCreate) {
    try {
      const response = await AxiosPost(`${ROUTE.CREATIVE_ASSOCIATE_ROUTE}`, association);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async associateLineItemDelete(id: number) {
    try {
      const response = await AxiosDelete(ROUTE.CREATIVE_ASSOCIATE_ROUTE + "/" + id);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async associateLineItemUpdate(association: any) {
    try {
      const response = await AxiosPatch(`${ROUTE.CREATIVE_ASSOCIATE_ROUTE}/${association.id}`, association);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async createCreativeBulk(params: any) {
    try {
      const response = await AxiosPost(`${ROUTE.CREATIVE_BULK_ROUTE}`, params, true);
      return Promise.resolve({ data: GetData(response), message: response.data.message });
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async setActive(id: Number, active: Boolean) {
    try {
      const response = await AxiosPut(`${ROUTE.CREATIVE_ROUTE}/${id}/set/${active ? 1 : 0}`, {});
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async getCreativesAssociated(idLineItem: Number) {
    try {
      const response = await AxiosGet(`${ROUTE.CREATIVE_ASSOCIATION_ROUTE}/${idLineItem}/creatives`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async deleteByID(id: number) {
    try {
      const response = await AxiosDelete(`${ROUTE.CREATIVE_ROUTE}/${id}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }
}

/* function getFilters(filters: CreativeFilters): string {
    let filter = '';

    const external_id = (isUndefined(filters.external_id)) ? '' : filters.external_id
    if( !isUndefined(filters.external_id) ){filter += 'filters[external_id]=' + external_id}
    const alternative_id = (isUndefined(filters.alternative_id)) ? '' : filters.alternative_id
    if( !isUndefined(filters.alternative_id) ){filter += '&filters[alternative_id]=' + alternative_id}
    const name = (isUndefined(filters.name)) ? '' : filters.name
    if( !isUndefined(filters.name) ){filter += '&filters[name]=' + name}
    const model_type_id = (isUndefined(filters.model_type_id)) ? '' : filters.model_type_id
    if( !isUndefined(filters.model_type_id) ){filter += '&filters[model_type_id]=' + model_type_id}
    const active = (isUndefined(filters.active)) ? '' : filters.active ? '1' : '0'
    if( !isUndefined(filters.active) ){filter += '&filters[active]=' + active}
    const advertiser_id = (isUndefined(filters.advertiser_id)) ? '' : filters.advertiser_id
    if( !isUndefined(filters.advertiser_id) ){filter += '&filters[advertiser.id]=' + advertiser_id}
    const creative_type_id = (isUndefined(filters.creative_type_id)) ? '' : filters.creative_type_id
    if( !isUndefined(filters.creative_type_id) ){filter += '&filters[creative_type_id]=' + creative_type_id}

    return filter
} */
function hasData(data: any): Boolean {
  return !isUndefined(data) && !isNull(data);
}

function getFilters(filters: CreativeFilters): string {
  let filter = "";

  const id = hasData(filters.id) ? filters.id : "";
  const name = hasData(filters.name) ? filters.name : "";
  const external_id = hasData(filters.external_id) ? filters.external_id : "";
  const creative_type_name = hasData(filters.creative_type_name) ? filters.creative_type_name : ""; // type
  const creative_size_name = hasData(filters.creative_size_name) ? filters.creative_size_name : ""; // size
  const alternative_id = hasData(filters.alternative_id) ? filters.alternative_id : "";
  const advertiser_id = hasData(filters.advertiser_id) ? filters.advertiser_id : "";
  const _advertiser_id = hasData(filters._advertiser_id) ? filters._advertiser_id : "";
  const model_type_id = hasData(filters.model_type_id) ? filters.model_type_id : "";
  const asset_type_id = hasData(filters.asset_type_id) ? filters.asset_type_id : "";
  const active = hasData(filters.active) ? (filters.active ? 1 : 0) : "";
  const creative_type_id = hasData(filters.creative_type_id) ? filters.creative_type_id : "";
  const line_item_id = hasData(filters.line_item_id) ? filters.line_item_id : "";

  var symbol = "";

  if (id) {
    filter += `${symbol}filters[id]=${id}`;
    symbol = "&";
  }

  if (name) {
    filter += `${symbol}filters[name]=${name}`;
    symbol = "&";
  }

  if (external_id) {
    filter += `${symbol}filters[external_id]=${external_id}`;
    symbol = "&";
  }

  if (creative_type_name) {
    filter += `${symbol}filters[creative_type.description]=${creative_type_name}`;
    symbol = "&";
  }

  if (creative_size_name) {
    filter += `${symbol}filters[creative_size.name]=${creative_size_name}`;
    symbol = "&";
  }

  if (alternative_id) {
    filter += `${symbol}filters[alternative_id]=${alternative_id}`;
    symbol = "&";
  }

  if (advertiser_id) {
    filter += `${symbol}filters[advertiser.id]=${advertiser_id}`;
    symbol = "&";
  }

  if (_advertiser_id) {
    filter += `${symbol}filters[advertiser_id]=${_advertiser_id}`;
    symbol = "&";
  }

  if (model_type_id) {
    filter += `${symbol}filters[model_type_id]=${model_type_id}`;
    symbol = "&";
  }

  if (asset_type_id) {
    filter += `${symbol}filters[asset_type_id]=${asset_type_id}`;
    symbol = "&";
  }

  if (creative_type_id) {
    filter += `${symbol}filters[creative_type_id]=${creative_type_id}`;
    symbol = "&";
  }

  if (line_item_id) {
    filter += `${symbol}filters[line_item_id]=${line_item_id}`;
    symbol = "&";
  }

  if (active) {
    filter += `${symbol}filters[active]=${active}`;
    symbol = "&";
  }

  return filter;
}

/**
 * @param {CreativeFiltersAssoc} filters The values for filters
 * @return {string} Returns string with filters, else empty
 */
function getFiltersAssoc(filters: CreativeFiltersAssoc): string {
  let filter = "";

  const creative_id = isUndefined(filters.creative_id) || isNull(filters.creative_id) ? "" : filters.creative_id;

  const ext_id = isUndefined(filters.external_id) || isNull(filters.external_id) ? "" : filters.external_id;
  const campaign_item_id =
    isUndefined(filters.campaign_item_id) || isNull(filters.campaign_item_id) ? "" : filters.campaign_item_id;
  const campaign_item_name =
    isUndefined(filters.campaign_item_name) || isNull(filters.campaign_item_name) ? "" : filters.campaign_item_name;
  const line_item_id = isUndefined(filters.line_item_id) || isNull(filters.line_item_id) ? "" : filters.line_item_id;
  const line_item_external_id =
    isUndefined(filters.line_item_external_id) || isNull(filters.line_item_external_id)
      ? ""
      : filters.line_item_external_id;
  const line_item_name =
    isUndefined(filters.line_item_name) || isNull(filters.line_item_name) ? "" : filters.line_item_name;

  var symbol = "";

  if (creative_id) {
    filter += `${symbol}filters[creative_id]=${creative_id}`;
    symbol = "&";
  }

  if (ext_id) {
    filter += `${symbol}filters[external_id]=${ext_id}`;
    symbol = "&";
  }

  if (campaign_item_id) {
    filter += `${symbol}filters[campaigns.id]=${campaign_item_id}`;
    symbol = "&";
  }

  if (campaign_item_name) {
    filter += `${symbol}filters[campaigns.name]=${campaign_item_name}`;
    symbol = "&";
  }

  if (line_item_id) {
    filter += `${symbol}filters[line_item_id]=${line_item_id}`;
    symbol = "&";
  }

  if (line_item_name) {
    filter += `${symbol}filters[line_items.name]=${line_item_name}`;
    symbol = "&";
  }

  if (line_item_external_id) {
    filter += `${symbol}filters[line_items.external_id]=${line_item_external_id}`;
    symbol = "&";
  }

  return filter;
}

export default new CreativeService();
