import { ItemGraphic } from "@/interfaces/graphic";
import { AudienceSectionItem } from "@/models/persons/v10/Audience";
import { GraphicEntity } from "@/models/persons/v10/Implements";


export interface Audience {
	total_reach: GraphicEntity,
	proximity_to_poi: GraphicEntity,
	gender: GraphicEntity,
	age: GraphicEntity,
	niv_socio: GraphicEntity,

	filters: FilterItem[];
	last_filters: FilterItem[];
}

export interface IFiltersObject {
	poi_distance: FilterItem[];
	user_type: FilterItem[];
	gender: FilterItem[];
	age: FilterItem[];
	category_poi: FilterItem[];
	sub_category_poi: FilterItem[];
	marca_poi: FilterItem[];
	dpto_poi: FilterItem[];
	city_poi: FilterItem[];
	barrio_poi: FilterItem[];
	date: FilterItem[];
	// date_of_week: FilterItem[];
	// time_of_day: FilterItem[];
	frequency: FilterItem[];
	residence_dpto: FilterItem[];
	residence_city: FilterItem[];
	residence_barrio: FilterItem[];
	iab: FilterItem[];
	interest: FilterItem[];
	sites: FilterItem[];
	app_bundle: FilterItem[];
	app_name: FilterItem[];
	content_language: FilterItem[];
	city_connection: FilterItem[];
	niv_socio: FilterItem[];
	carrier: FilterItem[];
	device_type: FilterItem[];
	make: FilterItem[];
	browser: FilterItem[];
	os: FilterItem[];
}

export type FilterItem = Required<Pick<ItemGraphic, "name" | "type">> & {
	extra?: object
}

export type FilterParam = {
	filters: {
		start_date?: string,
		end_date?: string,

		frequency?: {
			[key: string]: number[]
		},

		user_id_type: string[],
		gender: string[],
		age: string[],
		estado_residence: string[],
		neighborhood_residence: string[],
		city_residence: string[],
		nivsocio: string[],
		app_bundle: string[],
		app_name: string[],
		iab: string[],
		city_seen: string[],
		platform_browser: string[],
		platform_device_make: string[],
		platform_device_type: string[],
		platform_device_language: string[],
		platform_os: string[],
		platform_carrier: string[],
		
		codigo_estado: string[],
		codigo_ciudad: string[],
		codigo_barrio: string[],
		distance?: string,
		categoria: string[],
		subcategoria: string[],
		marca: string[],

		domain: string[],
		interest: string[],
	}
}

export interface ISegmentAudience {
	name: string;
	categories: number[];
	advertiser_id: number | null;
}

export interface FloatingAction {
	action: string;
	title: string;
	icon: string;
	loading: boolean;
	disabled: boolean;
	forceTooltip?: boolean;
}

export enum AUDIENCE_ACTIONS {
	FILTER = "filter",
	RESET = "reset",
	BACK = "back",
	EXPORT_SCREEN = "export_screen",
	EXPORT_AUDIENCE = "export_audience",
}

export interface IFrequencyItem {
	name: string;
	index: number;
	"1": boolean;
	"2": boolean;
	"3": boolean;
	"4": boolean;
	"5": boolean;
	"6": boolean;
	"7": boolean;
}

export type IFrequency = {
	[key in 1 | 2 | 3 | 4 | 5 | 6 | 7]: number[];
};

export enum TAudienceType {
	GEO = "analyze_geos",
	POIS = "analyze_pois",
	POS = "analyze_chipper",
}

export interface IExportSegmentAudience extends ISegmentAudience, FilterParam { }

export interface AudienceSectionItemOptions {
	/**
	 * If the post was already done
	 * - default `false`
	 */
	fetched?: boolean;

	/**
	 * Set selected to not show buttons
	 */
	selected?: boolean;

	/**
	 * Name of section
	 */
	name: string, 

	/**
	 * Keys of graphics to load
	 */
	graphic_keys: string[], 

	/**
	 * Icon of the button
	 */
	icon?: string,

	panel: number,
}

export type TStorageAudienceSection = {[key: string]: AudienceSectionItem}