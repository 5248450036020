import { GetData, GetErrors, GetMessage, AxiosPost } from "@/services/axios-service";

import i18n from "@/plugins/i18n";
import ROUTES from "@/api/routes";

const ROUTE = ROUTES.PERSON;

import { getInitFilterGraphic, prepareFilterGraphic } from "@/utils/persons/audience";

import { FilterAudience } from "@/interfaces/audience";
import { catchServiceErrors } from "@/utils/services-global";

class GraphicsAudienceService {
  async prepareFiltersAudience(filters: any, models: any) {
    try {
      const newFilters = await getInitFilterGraphic();

      const resultFilters = await prepareFilterGraphic(newFilters, filters, models);

      return Promise.resolve(resultFilters);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async filterAudience(filters: FilterAudience) {
    try {
      const response = await AxiosPost(ROUTE.GENERATE.PERSON_FILTER_AUDIENCE_ROUTE, { filters: filters });

      const data = GetData(response);

      /**
       * Response
       * string [ processing, failed ]
       * object -> ok
       */
      if (typeof data === "string") {
        const message = `persons.messages.apply-filters.${data}`;
        return Promise.reject({
          status: data,
          success: false,
          message: i18n.t(message),
          errors: [],
        });
      }
      return Promise.resolve(data);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }
}

export default new GraphicsAudienceService();
