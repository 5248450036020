// @ts-ignore
import { Paginated, SortingOption } from "@/interfaces/paginated";
// @ts-ignore
import { ActivityLogFilter } from "@/interfaces/activity_log";
// @ts-ignore
import { isUndefined, isEmpty } from "lodash";
import {
	AxiosGet,
	GetData,
	GetErrors,
	GetMessage
// @ts-ignore
} from "./axios-service";
// @ts-ignore
import { catchServiceErrors, modes, prepareParams } from "@/utils/services-global";
import ROUTES from "@/api/routes";
const ROUTE = ROUTES.LOG;
class ActivityLog{
	async getActivityLog(){
		try {
			let datos={
				success: false,
				response: [],
			}
			await fetch("/activityLog.json")
			.then(response=>{
				return response.json();
			})
			.then(responjson=>{
				datos.success= responjson.success;
				datos.response= responjson.response;
			});
			return datos.response;
		} catch (error) {

		}
	}

	async paginated(params: {
		paginated?: Paginated;
		filters?: ActivityLogFilter;
		options?: SortingOption;
		fields?: Array<string>;
	}, paramsEntity: any) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const r = `${matchedRoutes()[paramsEntity.entity]}/${paramsEntity.id}`

			const url = await prepareParams({
				route: r,
				entity: paramsEntity.entity,
				mode: modes.PAGINATED,
				filter: filter,
				...params
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async all(params: { filters?: ActivityLogFilter, options?: SortingOption }, paramsEntity: any) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const r = `${matchedRoutes()[paramsEntity.entityName]}/${paramsEntity.id}`

			const url = await prepareParams({
				route: r,
				entity: paramsEntity.entityName,
				mode: modes.ALL,
				filter: filter,
				...params
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

}

/**
 * Matched Routes
 * @returns
 */
 function matchedRoutes() {
	return {
		advertiser: ROUTE.ACTIVITY_LOG_ADVERTISER_ROUTE,
		campaign: ROUTE.ACTIVITY_LOG_CAMPAIGN_ROUTE,
		lineItem: ROUTE.ACTIVITY_LOG_LINE_ITEM_ROUTE,
		creative: ROUTE.ACTIVITY_LOG_CREATIVE_ROUTE
	};
}

function getFilters(filters: ActivityLogFilter): string {

	let filter = "";

	const id = !!filters.id ? filters.id : "";
	const event = !!filters.event ? filters.event : "";
	const created_date = !!filters.created_date ? filters.created_date : "";
	const updated_date = !!filters.updated_date ? filters.updated_date : "";
	const user_name = !!filters.user_name ? filters.user_name : "";

	if (!isEmpty(id)) {
		filter += `filters[id]=${id}`;
	}
	if (!isEmpty(event)) {
		filter += `&filters[event]=${event}`;
	}
	if (!isEmpty(created_date)) {
		filter += `&filters[created_date]=${created_date}`;
	}
	if (!isEmpty(updated_date)) {
		filter += `&filters[updated_date]=${updated_date}`;
	}
	if (!isEmpty(user_name)) {
		filter += `&filters[user_name]=${user_name}`;
	}

	return filter;

}

export default new ActivityLog();
