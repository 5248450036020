export enum CardActionType {
	VIEW_LINE_ITEM = "view-line-item",
	SUBMIT = "submit",
	CREATE = "create",
	SAVE = "save",
	UPDATE = "update",
	SAVE_CONTINUE = "save-continue",
	SAVE_ASSOCIATION = "save-association",
	RUN = "run",
	SCHEDULER = "scheduler",
	SCHEDULER_CREATE = "scheduler-create",
	SCHEDULER_UPDATE = "scheduler-update",
	DOWNLOAD = "download",
	CANCEL = "cancel",
	CLOSE = "close",
	BUTTON = "button",
	GENERATE = "generate",
	DELETE_ALL = "delete-all",
	UPLOAD = "upload",
	BACK = "back",
}

export enum CardActionColor {
	SECONDARY = "secondary",
	PRIMARY = "primary",
}

export enum CardActionEmit {
	VIEW_LINE_ITEM = "viewLine",
	SUBMIT = "submit",
	CREATE = "create",
	SAVE = "save",
	UPDATE = "update",
	SAVE_CONTINUE = "save-continue",
	SAVE_ASSOCIATION = "save-association",
	RUN = "run",
	SCHEDULER = "scheduled-report",
	SCHEDULER_CREATE = "create-scheduler",
	SCHEDULER_UPDATE = "update-scheduler",
	DOWNLOAD = "download",
	CANCEL = "cancel",
	CLOSE = "close",
	DELETE_ALL = "delete-all",
	UPLOAD = "upload",
	BACK = "back",
}

export interface CardAction {
	type: CardActionType;
	text: CardActionType;
	disabled: Boolean;
	outlined: Boolean;
	color: CardActionColor;
	emit: CardActionEmit;
	icon?: string;
	setDisabled?(disabled: Boolean): void;
	setText?(text: CardActionType): void;
	getText(): string;
}
