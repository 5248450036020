import { AxiosGet, GetData, GetErrors, GetMessage } from "@/services/axios-service";
import { capitalizeWords, parseEncode } from "@/utils/filter-global";
import { Filters } from "@/interfaces/person";
import { isArray, isEmpty, isNull, isString } from "lodash";
import { SourceDataFilter } from "@/interfaces/audience";
import { resolveUsersBy } from "@/utils/resolveObjectArray";
import { prepareHeatMapGraphic, prepareUserGenderGraphic } from "@/utils/amcharts/utils";
import { ItemGraphic } from "@/interfaces/graphic";
import store from "@/store";
import { isWillBeRetried } from "@/utils/persons/audience";
import { catchServiceErrors } from "@/utils/services-global";
import ROUTES from "@/api/routes";

const ROUTE = ROUTES.PERSON;

class AudienceService {
  /**
   * Get resource by key
   * @param source SourceDataFilter
   * @param isGraphic Boolean
   * @returns
   */
  async fetchResourceBykey(source: SourceDataFilter, isGraphic: Boolean = false) {
    try {
      let url = `${matchedRoutes()[source.key]}?mode=${source.mode}`;

      const response = await AxiosGet(url);

      const resultData = GetData(response);

      let result: any = null;

      switch (source.key) {
        case "map":
          result = resultData;
          break;

        case "gender_age":
          if (await isWillBeRetried(resultData)) {
            result = resultData;
          } else {
            result = await prepareUserGenderGraphic(resultData);
          }
          break;

        case "day_week":
          const Key = matchedResultKey()[source.key];
          if (await isWillBeRetried(resultData)) {
            result = resultData;
          } else {
            result = await prepareHeatMapGraphic(resultData[Key]);
          }
          break;

        default:
          const resultKey = matchedResultKey()[source.key];

          if (isGraphic) {
            if (isString(resultData)) {
              result = resultData;
            }

            if (resultData instanceof Object) {
              if (isEmpty(resultData)) {
                result = [];
              } else {
                const items = resultData[resultKey] as ItemGraphic[];

                result =
                  items?.map(r => {
                    if (r.name != "F" && r.name != "M") {
                      r.name = capitalizeWords(String(r.name));
                    }
                    return r;
                  }) || [];
              }
            }
          } else {
            if (isArray(resultData) && isEmpty(resultData)) {
              result = resultData;
            } else {
              result = await resolveUsersBy(resultData[resultKey]);
            }
          }
          break;
      }

      return Promise.resolve(result);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }
}

/**
 * Matched Routes
 * @returns
 */
function matchedRoutes() {
  const isBr: Boolean = store.getters["audience/isBR"];
  return {
    dates: ROUTE.AUDIENCE.AUDIENCE_DATES_ROUTE,
    distances: ROUTE.AUDIENCE.AUDIENCE_DISTANCES_ROUTE,
    weekDays: ROUTE.AUDIENCE.AUDIENCE_WEEK_DAY_ROUTE,
    hourDays: ROUTE.AUDIENCE.AUDIENCE_HOUR_DAY_ROUTE,

    // pois
    categories: ROUTE.AUDIENCE.AUDIENCE_CATEGORIES_ROUTE,
    subcategories: ROUTE.AUDIENCE.AUDIENCE_SUBCATEGORIES_ROUTE,
    departments: ROUTE.AUDIENCE.AUDIENCE_DEPARTMENTS_ROUTE,
    cities: ROUTE.AUDIENCE.AUDIENCE_CITIES_ROUTE,
    neighborhood: ROUTE.AUDIENCE.AUDIENCE_NEIGHBORHOOD_ROUTE,
    brands: ROUTE.AUDIENCE.AUDIENCE_BRANDS_ROUTE,
    names: ROUTE.AUDIENCE.AUDIENCE_NAMES_ROUTE,

    // demographic
    genders: ROUTE.AUDIENCE.AUDIENCE_GENDERS_ROUTE,
    ages: ROUTE.AUDIENCE.AUDIENCE_AGES_ROUTE,
    residence_cities: ROUTE.AUDIENCE.AUDIENCE_RESIDENCE_CITIES_ROUTE,
    neighborhood_residence: ROUTE.AUDIENCE.AUDIENCE_NEIGHBORHOOD_RESIDENCE_ROUTE,
    socioeconomics: isBr
      ? ROUTE.AUDIENCE.AUDIENCE_SOCIOECONOMICS_ROUTE_BR
      : ROUTE.AUDIENCE.AUDIENCE_SOCIOECONOMICS_ROUTE,

    // online_behaviour
    identifier_type: ROUTE.AUDIENCE.AUDIENCE_IDENTIFIER_TYPE_ROUTE,
    navigation_behaviour: ROUTE.AUDIENCE.AUDIENCE_NAVIGATION_BEHAVIOUR_ROUTE,
    campaign_interests: ROUTE.AUDIENCE.AUDIENCE_CAMPAIGN_INTERESTS_ROUTE,
    app_visited: ROUTE.AUDIENCE.AUDIENCE_APP_VISITED_ROUTE,
    sites_visited: ROUTE.AUDIENCE.AUDIENCE_SITES_VISITED_ROUTE,
    city_seen: ROUTE.AUDIENCE.AUDIENCE_CITY_SEEN_ROUTE,

    // device
    browser: ROUTE.AUDIENCE.AUDIENCE_BROWSER_ROUTE,
    language: ROUTE.AUDIENCE.AUDIENCE_LANGUAGE_ROUTE,
    make: ROUTE.AUDIENCE.AUDIENCE_MAKE_ROUTE,
    device_type: ROUTE.AUDIENCE.AUDIENCE_DEVICE_TYPE_ROUTE,
    operating_system: ROUTE.AUDIENCE.AUDIENCE_OPERATING_SYSTEM_ROUTE,
    operator: ROUTE.AUDIENCE.AUDIENCE_OPERATOR_ROUTE,

    // TABLE
    gender_age: ROUTE.AUDIENCE.PERSON_GENDER_AGE,

    // HEAD_MAP
    day_week: ROUTE.AUDIENCE.PERSON_HOUR_DAY_WEEK,

    // map
    map: ROUTE.PERSON_AUDIENCE_MAP,
  };
}
function matchedResultKey() {
  const isBr: Boolean = store.getters["audience/isBR"];
  return {
    dates: "date",
    distances: "distance",
    weekDays: "day_of_week",
    hourDays: "hora_dia",
    // pois
    categories: "categoria",
    subcategories: "subcategoria",
    departments: "dpto",
    cities: "city_poi",
    neighborhood: "barrio_poi",
    brands: "marca",
    names: "nombre_poi",

    // demographic
    genders: "gender",
    ages: "age",
    residence_cities: "ciudad_residencia",
    neighborhood_residence: "barrio_residencia",
    socioeconomics: isBr ? "nivsoc_brasil" : "nivsocio",

    // online_behaviour
    identifier_type: "user_type",
    navigation_behaviour: "iab",
    campaign_interests: "interest",
    app_visited: "app_name",
    sites_visited: "domain",
    city_seen: "city_seen",

    // device
    browser: "platform_browser",
    language: "platform_device_language",
    make: "platform_device_make",
    device_type: "platform_device_type",
    operating_system: "platform_os",
    operator: "carrier",

    //table
    gender_age: "gender_age",

    //head_map
    day_week: "hora_dia_day_of_week",

    //map
    map: "map",
  };
}

/**
 * Matched model: filter
 * @returns
 */
export function matchedFilters() {
  const isBr: Boolean = store.getters["audience/isBR"];
  return {
    pois: {
      categories: { categoria: [] },
      subcategories: { subcategoria: [] },
      departments: { dpto: [] },
      cities: { ciudad: [] },
      neighborhood: { neighborhood_poi: [] },
      brands: { marca: [] },
      names: { nombre: [] },
    },
    demographics: {
      genders: { gender: [] },
      ages: { age: [] },
      residence_cities: { city_residence: [] },
      neighborhood_residence: { neighborhood_residence: [] },
      socioeconomic_levels: isBr ? { nivsoc_brasil: [] } : { nivsocio: [] },
    },
    online_behaviour: {
      identifier_type: { user_type: [] },
      navigation_behaviour: { iab: [] },
      campaign_interests: { interest: [] },
      app_visited: { app_name: [] },
      sites_visited: { domain: [] },
      city_seen: { city_seen: [] },
      names: { nombre: [] },
    },
    device: {
      browser: { platform_browser: [] },
      language: { platform_device_language: [] },
      make: { platform_device_make: [] },
      device_type: { platform_device_type: [] },
      operating_system: { platform_os: [] },
      operator: { carrier: [] },
    },
  };
}

/**
 * @param {Filters} filters The values for filters
 * @return {string} Returns string with filters, else empty
 */
function getFilters(filters: Filters, filter_country_code?: string): string {
  let filter = "";

  const country_code = filters.country_code || null;
  const layers = filters.layers || [];
  const pais = !filter_country_code ? filters.pais : "";
  const categories = filters.categoria || [];
  const subcategoria = filters.subcategoria || [];
  const dpto = filters.dpto || [];
  const nombre = filters.nombre || [];
  const ciudad = filters.ciudad || [];
  const marca = filters.marca || [];
  const search = filters.search || null;
  const term = filters.term || null;
  const paises = filters.paises || [];
  const layer_paises = filters.layer_paises || [];
  const layer_dpto = filters.layer_dpto || [];
  const layer_ciudad = filters.layer_ciudad || [];
  const layer_categoria = filters.layer_categoria || [];
  const layer_subcategoria = filters.layer_subcategoria || [];
  const layer_nombre = filters.layer_nombre || [];
  const layer_marca = filters.layer_marca || [];
  const layer_term = filters.layer_term || null;

  //Demograficos
  const demo_gender = filters.gender || [];
  const demo_age = filters.age_range || [];
  const demo_nivsocio = filters.nivsocio || [];
  const demo_city = filters.city || [];
  const demo_interest = filters.interest || [];
  const demo_iab = filters.iab_cat || [];
  const demo_type = filters.type || [];
  const demo_carrier = filters.carrier || [];
  const demo_make = filters.make || [];

  var symbol = "";

  if (country_code) {
    filter += `${symbol}country_code=${country_code}`;
    symbol = "&";
  }

  if (filter_country_code) {
    filter += `${symbol}filters[country_code]=${filter_country_code}`;
    symbol = "&";
  }

  if (pais) {
    filter += `${symbol}filters[pais]=${pais}`;
    symbol = "&";
  }

  if (!filter_country_code && !isEmpty(paises)) {
    paises.forEach(c => {
      filter += `${symbol}filters[pais][]=${c}`;
      symbol = "&";
    });
  }

  if (!isEmpty(categories)) {
    categories.forEach(c => {
      filter += `${symbol}filters[categoria][]=${parseEncode(c)}`;
      symbol = "&";
    });
  }

  if (!isEmpty(subcategoria)) {
    subcategoria.forEach(c => {
      filter += `${symbol}filters[subcategoria][]=${parseEncode(c)}`;
      symbol = "&";
    });
  }

  if (!isEmpty(dpto)) {
    dpto.forEach(c => {
      filter += `${symbol}filters[dpto][]=${parseEncode(c)}`;
      symbol = "&";
    });
  }

  if (!isEmpty(nombre)) {
    nombre.forEach(c => {
      filter += `${symbol}filters[nombre][]=${parseEncode(c)}`;
      symbol = "&";
    });
  }

  if (!isEmpty(ciudad)) {
    ciudad.forEach(c => {
      filter += `${symbol}filters[ciudad][]=${parseEncode(c)}`;
      symbol = "&";
    });
  }

  if (!isEmpty(marca)) {
    marca.forEach(c => {
      filter += `${symbol}filters[marca][]=${parseEncode(c)}`;
      symbol = "&";
    });
  }

  if (!isNull(search) && !isEmpty(search)) {
    filter += `${symbol}search=${parseEncode(search)}`;
    symbol = "&";
  }

  if (!isNull(term) && !isEmpty(term)) {
    filter += `${symbol}term=${parseEncode(term)}`;
    symbol = "&";
  }

  if (!isEmpty(layers)) {
    layers.forEach(c => {
      filter += `${symbol}layers[]=${parseEncode(c)}`;
      symbol = "&";
    });
  }

  if (!isEmpty(layer_paises)) {
    layer_paises.forEach(c => {
      filter += `${symbol}layer_filters[pais][]=${c}`;
      symbol = "&";
    });
  }

  if (!isEmpty(layer_dpto)) {
    layer_dpto.forEach(c => {
      filter += `${symbol}layer_filters[dpto][]=${parseEncode(c)}`;
      symbol = "&";
    });
  }

  if (!isEmpty(layer_ciudad)) {
    layer_ciudad.forEach(c => {
      filter += `${symbol}layer_filters[ciudad][]=${parseEncode(c)}`;
      symbol = "&";
    });
  }

  if (!isEmpty(layer_categoria)) {
    layer_categoria.forEach(c => {
      filter += `${symbol}layer_filters[categoria][]=${parseEncode(c)}`;
      symbol = "&";
    });
  }

  if (!isEmpty(layer_subcategoria)) {
    layer_subcategoria.forEach(c => {
      filter += `${symbol}layer_filters[subcategoria][]=${parseEncode(c)}`;
      symbol = "&";
    });
  }

  if (!isEmpty(layer_nombre)) {
    layer_nombre.forEach(c => {
      filter += `${symbol}layer_filters[nombre][]=${parseEncode(c)}`;
      symbol = "&";
    });
  }

  if (!isEmpty(layer_marca)) {
    layer_marca.forEach(c => {
      filter += `${symbol}layer_filters[marca][]=${parseEncode(c)}`;
      symbol = "&";
    });
  }

  if (!isNull(layer_term) && !isEmpty(layer_term)) {
    filter += `${symbol}term=${parseEncode(layer_term)}`;
    symbol = "&";
  }

  //Demograficos
  if (!isEmpty(demo_gender)) {
    demo_gender.forEach(c => {
      filter += `${symbol}filters[gender][]=${parseEncode(c)}`;
      symbol = "&";
    });
  }

  if (!isEmpty(demo_age)) {
    demo_age.forEach(c => {
      filter += `${symbol}filters[age_range][]=${parseEncode(c)}`;
      symbol = "&";
    });
  }

  if (!isEmpty(demo_nivsocio)) {
    demo_nivsocio.forEach(c => {
      filter += `${symbol}filters[nivsocio][]=${parseEncode(c)}`;
      symbol = "&";
    });
  }

  if (!isEmpty(demo_city)) {
    demo_city.forEach(c => {
      filter += `${symbol}filters[city][]=${parseEncode(c)}`;
      symbol = "&";
    });
  }

  if (!isEmpty(demo_interest)) {
    demo_interest.forEach(c => {
      filter += `${symbol}filters[interest][]=${parseEncode(c)}`;
      symbol = "&";
    });
  }

  if (!isEmpty(demo_iab)) {
    demo_iab.forEach(c => {
      filter += `${symbol}filters[iab_cat][]=${parseEncode(c)}`;
      symbol = "&";
    });
  }

  if (!isEmpty(demo_type)) {
    demo_type.forEach(c => {
      filter += `${symbol}filters[type][]=${parseEncode(c)}`;
      symbol = "&";
    });
  }

  if (!isEmpty(demo_carrier)) {
    demo_carrier.forEach(c => {
      filter += `${symbol}filters[carrier][]=${parseEncode(c)}`;
      symbol = "&";
    });
  }

  if (!isEmpty(demo_make)) {
    demo_make.forEach(c => {
      filter += `${symbol}filters[make][]=${parseEncode(c)}`;
      symbol = "&";
    });
  }

  return filter;
}

export default new AudienceService();
