import { UserLanguage } from "@/interfaces/internalization";
import i18n from "@/plugins/i18n";
import InternalizationService from "@/services/internalizacion-service";
import { getFromStorage, setToStorage } from "@/services/storage-service";
import { catchError } from "@/store/Util";
import { ComboListOptions, resolveLanguages } from "@/utils/resolveObjectArray";
import { Commit } from "vuex";

const LANG_FAULT = "en";

// Set default language from storage
verifyLang();

export const InternalizationModule = {
	namespaced: true,
	state: () => ({
		languages: [] as any[],
		language: i18n.locale,
		snack: {
			message: "",
			color: "",
		},
	}),
	mutations: {
		SET_LANGUAGES(state: { languages: any[] }, languages: any[]) {
			state.languages = languages;
		},
		SET_LANGUAGE(
			state: { language: string; snack: any },
			language: string = "en"
		) {
			setToStorage("language", JSON.stringify(language));
			state.language = language;
			i18n.locale = language;
		},
		SET_SNACK(state: { language: string; snack: any }, lang: string) {
			state.snack = {
				message: lang ? i18n.t("internalization.change_lang", { lang }) : "",
				color: "secondary",
			};
		},
	},
	getters: {
		getLanguages(state: { languages: any[] }): any[] {
			return state.languages;
		},
		getLanguage(state: { language: string }): string {
			return state.language;
		},
		getSnack(state: { snack: any }) {
			return state.snack;
		},
		getLanguageData(state: {
			languages: ComboListOptions[];
			language: string;
		}) {
			return state.languages.find((l) => l.id === state.language);
		},
	},
	actions: {
		async fetchLanguages({ commit }) {
			try {
				const response = await InternalizationService.getLanguages();
				const languages = resolveLanguages(response);
				commit("SET_LANGUAGES", languages);
				return await Promise.resolve(languages);
			} catch (error) {
				catchError(this, error, { to: "" });
				return await Promise.reject(error);
			}
		},
		async setLanguage(
			{ commit }: { commit: Commit },
			params: UserLanguage
		) {
			try {
				commit("SET_LANGUAGE", params.language);
				if (params.id) {
					await InternalizationService.setLanguage(params);
				}
				return await Promise.resolve(
					"Language set to ".concat(params.language.toString())
				);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async setSnack({ commit }, lang: string) {
			commit("SET_SNACK", lang);
		},
	},
};

async function verifyLang() {
	try {
		let langFromStore = getFromStorage("language");
		let langParsed = getParsed(langFromStore);
		if (!langParsed) {
			await setToStorage("language", JSON.stringify(LANG_FAULT));
			langFromStore = getFromStorage("language");
		}
		let localeToSave: string = langParsed ?? LANG_FAULT;
		i18n.locale = localeToSave;
	} catch (error) {
		console.error("InternalizationModule::verifyLang", { error });
	}
}

export function getParsed(data: string | undefined) {
	if (!data) return undefined;
	return JSON.parse(data);
}
