import { ElementData } from "@/interfaces/persons/v10/person";
import { ResultCountry } from "@/interfaces/persons/v10/response";
import { LikeName } from "@/interfaces/persons/v10/select_like";
import {
	PersonGeoKey,
	PersonOohKey,
	PersonPoisKey,
	PersonPosKey,
	PersonPrivateKey,
} from "@/interfaces/persons/v10/types";
import { orderBy } from "lodash";

export function getSpecResultSorted(source: ResultCountry[], order: string[]) {
	return source.sort((a, b) => {
		const A = order.indexOf(a.continent);
		const B = order.indexOf(b.continent);

		if (A > B) return -1;
		if (B > A) return 1;
		return 0;
	});
}

export function prepareSortedElementData(
	items: ResultCountry[],
	continent: string,
	order: "asc" | "desc",
	setID: Boolean = false
) {
	let itemsOrdered = items
		.filter((i) => i.continent === continent)
		.map((item) => {
			return {
				id: setID ? item.id : item.country_code,
				value: item.name,
			} as ElementData;
		});

	itemsOrdered = orderBy(itemsOrdered, ["value"], [order]);

	return itemsOrdered;
}

export const colorSet = {
	[PersonGeoKey.STATES]: "teal",
	[PersonGeoKey.CITIES]: "orange",
	[PersonGeoKey.NEIGHBORHOODS]: "pink darken-4",

	[PersonPoisKey.CATEGORIES]: "red darken-1",
	[PersonPoisKey.SUBCATEGORIES]: "red darken-4",
	[PersonPoisKey.BRANDS]: "red darken-2",
	[PersonPoisKey.NAMES]: "red",

	[PersonOohKey.OOH_CATEGORIES]: "cyan darken-1",
	[PersonOohKey.OOH_SUBCATEGORIES]: "cyan darken-4",
	[PersonOohKey.OOH_BRANDS]: "cyan darken-2",
	[PersonOohKey.OOH_NAMES]: "cyan",

	[PersonPrivateKey.PRIVATES]: "indigo",

	[PersonPosKey.CHIPPER_MACRO_CATEGORIES]: "purple",
	[PersonPosKey.CHIPPER_CATEGORIES]: "purple darken-1",
	[PersonPosKey.CHIPPER_COMPANIES]: "purple lighten-1",
	[PersonPosKey.CHIPPER_BRANDS]: "purple darken-2",
	[PersonPosKey.CHIPPER_NAMES_SKU]: "purple lighten-2",
	[PersonPosKey.CHIPPER_STORES_TYPE]: "purple darken-3",
} as { [key in LikeName]: string };
