import {
  RequestCampaign,
  RequestCampaingFilters,
  RequestCampaignCreate,
  NotesRequestCampaingFilter,
  CreativeRequestCampaingFilter,
  // @ts-ignore
} from "@/interfaces/request_campaign";
// @ts-ignore
import { NotesRequestCampaing } from "@/interfaces/request_campaign";
// @ts-ignore
import { Paginated, SortingOption } from "@/interfaces/paginated";
import {
  AxiosPost,
  AxiosPatch,
  AxiosGet,
  GetData,
  GetErrors,
  GetMessage,
  // @ts-ignore
} from "@/services/axios-service";
// @ts-ignore
import { prepareParams, modes, catchServiceErrors } from "@/utils/services-global";
// @ts-ignore
import { isUndefined, isEmpty } from "lodash";
import ROUTES from "@/api/routes";

const ROUTE = ROUTES.EXT_CAMPAIGN;

const ENTITY: string = "ext_campaigns";
const ENTITY_EXT: string = "ext_campaign_notes";
const CREATIVE_EXT: string = "creative";
const FILTERS = [
  "external_campaigns.id",
  "name",
  "type.description",
  "brand",
  "start_date",
  "end_date",
  "budget",
  "kpi",
  "status.description",
];

const FILTERS_EXT = ["ext_camp_id"];

const FILTERS_CREATIVE_EXT = ["campaign_id"];

class RequestCampaignService {
  async createRequestCampaign(campaign: RequestCampaignCreate) {
    try {
      const response = await AxiosPost(ROUTE.EXT_CAMPAIGN_ROUTE, campaign);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async updateRequestCampaign(campaign: RequestCampaign, id: Number) {
    try {
      const response = await AxiosPatch(`${ROUTE.EXT_CAMPAIGN_ROUTE}/${id}`, campaign);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async RequestCampaign(campaign: RequestCampaign, id: Number) {
    try {
      const response = await AxiosPatch(`${ROUTE.EXT_CAMPAIGN_ROUTE}/${id}`, campaign);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async showRequestCampaign(id: number) {
    try {
      const response = await AxiosGet(`${ROUTE.EXT_CAMPAIGN_ROUTE}/${id}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }
  async sendEmailCPI(id: number) {
    try {
      const response = await AxiosPost(`${ROUTE.EXT_CAMPAIGN_SEND_EMAIL_ROUTE}/${id}`, {});
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async paginatedRequestCampaign(params: {
    paginated?: Paginated;
    filters?: RequestCampaingFilters;
    options?: SortingOption;
    fields?: Array<string>;
  }) {
    try {
      let filter = "";

      if (!isUndefined(params.filters)) {
        filter = getFilters(FILTERS, params.filters);
      }

      const url = await prepareParams({
        route: ROUTE.EXT_CAMPAIGN_ROUTE,
        entity: ENTITY,
        mode: modes.PAGINATED,
        filter: filter,
        ...params,
      });

      const response = await AxiosGet(url);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async getListDataForms(key: string) {
    try {
      const response = await AxiosGet(`${matchedRoutes()[key]}`);
      return Promise.resolve(GetData(response));
    } catch (err) {
      return Promise.reject({
        success: false,
        message: GetMessage(err),
        errors: GetErrors(err),
      });
    }
  }

  async createNoteRequestCampaign(note: NotesRequestCampaing) {
    try {
      const response = await AxiosPost(ROUTE.EXT_CAMPAIGN_NOTES_ROUTE, note);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async paginatedNotesRequestCampaign(params: {
    paginated?: Paginated;
    filters?: NotesRequestCampaingFilter;
    options?: SortingOption;
    fields?: Array<string>;
  }) {
    try {
      let filter = "";

      if (!isUndefined(params.filters)) {
        filter = getFilters(FILTERS_EXT, params.filters);
      }

      const url = await prepareParams({
        route: ROUTE.EXT_CAMPAIGN_NOTES_ROUTE,
        entity: ENTITY_EXT,
        mode: modes.PAGINATED,
        filter: filter,
        ...params,
      });

      const response = await AxiosGet(url);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async createCreativeRequestCampaign(params: any) {
    try {
      const response = await AxiosPost(`${matchedRoutes()[params.key]}`, params.data);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async paginatedCreativeRequestCampaign(params: {
    paginated?: Paginated;
    filters?: CreativeRequestCampaingFilter;
    options?: SortingOption;
    fields?: Array<string>;
  }) {
    try {
      let filter = "";

      if (!isUndefined(params.filters)) {
        filter = getFilters(FILTERS_CREATIVE_EXT, params.filters);
      }

      const url = await prepareParams({
        route: ROUTE.EXT_CAMPAIGN_CREATIVE_ROUTE,
        entity: CREATIVE_EXT,
        mode: modes.PAGINATED,
        filter: filter,
        ...params,
      });

      const response = await AxiosGet(url);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async UpdateCreativeRequestCampaign(params: { data: any; key: any; id: any }) {
    try {
      const response = await AxiosPost(`${matchedRoutes()[params.key]}/${params.id}`, params.data);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }
}

function matchedRoutes() {
  return {
    campaignType: ROUTE.EXT_CAMPAIGN_TYPES_ROUTE,
    providers: ROUTE.EXT_CAMPAIGN_PROVIDERS_ROUTE,
    buyModel: ROUTE.EXT_BUY_MODELS_ROUTE,
    campaignMMP: ROUTE.EXT_CAMPAIGN_MMP_ROUTE,
    campaignOwner: ROUTE.EXT_CAMPAIGN_OWNERS_ROUTE,
    statusCampaign: ROUTE.EXT_CAMPAIGN_STATUS_ROUTE,
    sizeList: ROUTE.EXT_CAMPAIGN_CREATIVE_SIZE_ROUTE,
    aspectRatioList: ROUTE.EXT_CAMPAIGN_CREATIVE_ASPECT_RATIO_ROUTE,
    creativeDisplay: ROUTE.EXT_CAMPAIGN_CREATIVE_DISPLAY_ROUTE,
    creativeNative: ROUTE.EXT_CAMPAIGN_CREATIVE_NATIVE_ROUTE,
    creativeVideo: ROUTE.EXT_CAMPAIGN_CREATIVE_VIDEO_ROUTE,
    creativeTypeList: ROUTE.EXT_CAMPAIGN_TYPES_CREATIVE_ROUTE,
  };
}

function getFilters(FILTROS: string[], filters: any): string {
  let filter = "";
  FILTROS.forEach(element => {
    if (!isEmpty(filters[element])) {
      filter += "filters[" + element + "]=" + filters[element] + "&";
    }
  });
  filter = filter.substring(0, filter.length - 1);
  return filter;
}

export default new RequestCampaignService();
