import { PollDataCreate, PollDataUpdate, PollFilters, PollSummary, Question } from "@/interfaces/polls";
import PollService from "@/services/polls-service";
import { CatcherError, catchError } from "@/store/Util";
import notificationService from "@/services/notification-service";
import { Notification } from "@/interfaces/proccess";

export const PollModule = {
	namespaced: true,
	state: () => ({
		poll: [] as Array<PollSummary>,
		questionsByPoll: {},
		surveyCanceled: false,
	}),
	mutations: {
		SET_POLLS(state, _poll: Array<PollSummary>) {
			state.poll = _poll;
		},
		SET_QUESTIONS_BY_POLL(state, { poll_id, questions }: { poll_id: number, questions: Array<Question> }) {
			state.questionsByPoll = {
				...state.questionsByPoll,
				[poll_id]: questions,
			};
		},
		SET_SURVEY_CANCELED(state, value: boolean) { 
			state.surveyCanceled = value;
		},

	},
	getters: {
		getPolls(state) {
			return state.poll;
		},
		getQuestionsByPoll: (state) => (poll_id: number) => {
			return state.questionsByPoll[poll_id] || [];
		},
		isSurveyCanceled(state) {
			return state.surveyCanceled;
		},

	},
	actions: {
		async fetchPolls({ commit }, filters?: PollFilters) {
			try {
				const response = await PollService.all(filters);
				commit("SET_POLLS", response); 
				return await Promise.resolve(response);
			} catch (error) {
				CatcherError(this.dispatch, error);
				return await Promise.reject(error);
			}
		},

		async fetchQuestionsByPollId({ commit }, poll_id: number) {
			try {
				const response = await PollService.getQuestionsByPollId(poll_id);
				commit("SET_QUESTIONS_BY_POLL", { poll_id, questions: response });
				return await Promise.resolve(response);
			} catch (error) {
				CatcherError(this.dispatch, error);
				return await Promise.reject(error);
			}
		},

		async submitPollResponses({ dispatch }, { poll_id, responses }) {
			try {
			  const response = await PollService.submitResponses({ poll_id, responses });
			  notificationService.notifySuccess(this, {
				message: "Responses submitted successfully",
			  });
			  return Promise.resolve(response);
			} catch (error) {
			  catchError(this, error);
			  return Promise.reject(error);
			}
		  },

		async pollCancel({ commit }, poll_id: number) {
			try {
				const response = await PollService.pollCancel({ poll_id });
				console.log("encuesta cancelada")
				commit("SET_SURVEY_CANCELED", true); 
				notificationService.notifySuccess(this, {
					message: "Poll canceled successfully",
				} as Notification);
				return Promise.resolve(response);
			} catch (error) {
				commit("SET_SURVEY_CANCELED", false);
				await catchError(this, error);
				return Promise.reject(error);
			}
		},
		
	},
};
