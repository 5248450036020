import { ExportData } from "@/interfaces/export";
import { Paginated, SortingOption } from "@/interfaces/paginated";
import { Account_scheme_1 } from "@/interfaces/user";
import { capitalize, isEmpty, isNull, isUndefined, trim } from "lodash";

/**
 * Filter current selection
 * @param selection
 * @param items
 * @returns
 */
export async function filterCurrentSelection(
	selection: Array<string>,
	items: Array<any>
) {
	return selection.filter((key: string) => !items.includes(key));
}

/**
 * Filter No In Selection
 * @param selection
 * @param items
 * @returns
 */
export async function filterNoInSelection(
	selection: Array<string>,
	items: Array<any>
) {
	return selection.filter(
		(key: string) => !items.some((i) => i.value == key)
	);
}

export async function filterByKey(
	items: Array<any>,
	key: string,
	attribute?: string
) {
	if (attribute) {
		return items.filter((i: any) => i[attribute] == key);
	}
	return items.filter((i: string) => i == key);
}

/**
 * parseToLower
 * @param str
 * @returns
 */
export function parseToLower(str: string) {
	return str.toLowerCase();
}

/**
 * String Encode
 * @param text
 * @returns
 */
export function parseEncode(text: string) {
	return encodeURIComponent(trim(text));
}

/**
 * Get default sorting option
 * @returns
 */
export function getDefaultSortingOption(sort?: string): SortingOption {
	return {
		sort: sort ?? "external_id",
		order: "desc",
	};
}

/**
 * Get default paginated params
 * @param page 
 * @param limit 
 * @returns 
 */
export function getPaginatedDefault(
	page: number = 1,
	limit: number = 25
): Paginated {
	return {
		page,
		limit,
	};
}

/**
 * Hyphenize string
 * @param text
 * @returns
 */
export function hyphenize(text: string) {
	if (!text) return "";
	return text.replace(/\s+/g, "-").toLowerCase();
}

/**
 * Capitalize all words
 * @param text
 * @returns
 */
export function capitalizeWords(text: string) {
	if (typeof text !== "string") return text;
	if (!text) return text;
	let words = text.split(" ");
	words = words.map((w) => capitalize(w));
	return words.join(" ");
}

/**
 * Clean Special Charts: String
 * @param text
 * @returns
 */
export function cleanSpecialCharts(text: string) {
	if (!text) return text;

	var specialChars = "!@#$^&%*()+=-[]/{}|:<>?,.";

	let stringToReplace: string = "";

	for (var i = 0; i < specialChars.length; i++) {
		stringToReplace = text.replace(
			new RegExp("\\" + specialChars[i], "gi"),
			""
		);
	}

	return stringToReplace;
}

/**
 * Clean Special Charts: Array String
 * @param data
 * @returns
 */
export function arrayCleanSpecialCharts(data: Array<string>) {
	if (isEmpty(data)) return data;

	let newData: Array<string> = [];

	data.map((d) => {
		newData.push(cleanSpecialCharts(d));
	});

	return newData;
}

/**
 * Replace all variables in a string
 * @param str
 * @param term
 * @returns
 */
export function replaceVariable(str: string, term: any) {
	var regexp = /{{([\s\S]+?)}}/g;
	return str.replace(regexp, term);
}

/**
 * Replace all variables in a string
 * @param str
 * @param searchValue
 * @param replaceValue
 * @returns
 */
export function replaceAllVariable(
	str: string,
	searchValue: string | RegExp,
	replaceValue: string
) {
	return str.replaceAll(searchValue, replaceValue);
}

export function hasData(item: any) {
	return !isEmpty(item) && !isNull(item) && !isUndefined(item);
}

/**
 * Sort by specific fields
 * @param source
 * @param order
 * @returns
 */
export async function getSpecGraphicSorted(
	source: ExportData[],
	order: string[]
) {
	return source.sort((a, b) => {
		return (
			order.indexOf(a.exporting.root.dom.id) -
			order.indexOf(b.exporting.root.dom.id)
		);
	});
}

/**
 * Get element unique
 * @param elements
 * @returns
 */
export async function getUniqArray<T extends { external_id: number }>(
	elements: T[]
) {
	const uniqueElements: T[] = [];

	elements.forEach((item) => {
		// Comprueba si el elemento ya existe en uniqueElements basado en el "external_id"
		const exists = uniqueElements.some(
			(element) => element.external_id === item.external_id
		);

		// Si no existe, agrega el elemento a la lista uniqueElements
		if (!exists) {
			uniqueElements.push(item);
		}
	});

	return uniqueElements;
}

export async function getFilteredAccounts(
	items: Account_scheme_1[],
	term?: string | number | null
) {
	let result: Account_scheme_1[] = items;

	const search: string | number | null = parseInt(term as string);

	// si el valor es numerico se filtra por el {external_id}
	if (!isNaN(search) && search > 0) {
		result = items.filter((item) =>
			item.external_id.toString().includes(term as string)
		);
	}

	// si el valor es string se filtra por el {name}
	if (typeof term === "string" && term !== "" && isEmpty(result)) {
		result = items.filter((item) =>
			item.name.toLowerCase().includes(term.toLowerCase())
		);
	}

	/**
	 * Retorna siempre elementos unicos
	 */
	return await getUniqArray(result);
}

/**
 * Remover duplicados de un array de numeros
 * @param arr 
 * @returns 
 */
export function makeUnique(arr: number[]): number[] {
    return arr.filter((valor, indice, array) => {
        // Devuelve true solo si el índice del elemento actual es igual al primer índice de ese elemento en el array
        return array.indexOf(valor) === indice;
    });
}

/**
 * Merge de arrays para limpiar datos duplicados
 * @param elements1 
 * @param elements2 
 * @returns 
 */
export function mergeElements(elements1: any[], elements2: any[] = []) {
	let set = new Set([...elements1, ...elements2]);
	return [...set];
}