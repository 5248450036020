import { ModelsDataCreate, ModelsFilters } from "@/interfaces/models";
import { Paginated, SortingOption } from "@/interfaces/paginated";
import { AxiosGet, AxiosPost, AxiosPatch, GetData, GetErrors, GetMessage } from "@/services/axios-service";
import { prepareParams, catchServiceErrors, modes } from "@/utils/services-global";
import { isNull, isUndefined } from "lodash";
import ROUTES from "@/api/routes";

const ROUTE = ROUTES.MODELS;

const ENTITY: string = "models";

class ModelsService {
  async paginated(params: {
    paginated: Paginated;
    filters?: ModelsFilters;
    options?: SortingOption;
    fields?: Array<string>;
  }) {
    try {
      let filter = "";

      if (!isUndefined(params.filters)) {
        filter = getFilters(params.filters);
      }

      const url = await prepareParams({
        route: ROUTE.MODELS_PAGINATED_ROUTE,
        entity: ENTITY,
        mode: modes.PAGINATED,
        filter: filter,
        ...params,
      });

      const response = await AxiosGet(url);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async create(models: ModelsDataCreate) {
    try {
      let response = await AxiosPost(ROUTE.MODELS_CREATE_ROUTE, models);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async metricsList() {
    try {
      const response = await AxiosGet(`${ROUTE.MODELS_METRICS_LIST_ROUTE}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async bidModelsList() {
    try {
      const response = await AxiosGet(`${ROUTE.BIDMODELS_LIST_ROUTE}?type=bid_models`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }
}

function getFilters(filters: ModelsFilters): string {
  let filter = "";

  const id = !!filters.id ? filters.id : "";
  const external_id = !!filters.external_id ? filters.external_id : "";
  const name = !!filters.name ? filters.name : "";
  const archived = !!filters.archived ? filters.archived : "";

  var symbol = "";

  if (external_id) {
    filter += `${symbol}filters[external_id]=${external_id}`;
    symbol = "&";
  }

  if (id) {
    filter += `${symbol}filters[id]=${id}`;
    symbol = "&";
  }

  if (name) {
    filter += `${symbol}filters[name]=${name}`;
    symbol = "&";
  }

  if (archived) {
    filter += `${symbol}filters[archived]=${archived}`;
    symbol = "&";
  }

  return filter;
}

export default new ModelsService();
