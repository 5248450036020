import { EnumReportType, EnumStatus } from "@/interfaces/report";

export const PATCH_REPORT_MESSAGE = "report.messages";

export interface TableReport {
	items: TableItem[];
}

export interface TableItem {
	id: number;
	account_id: number;
	dimensions: string;
	metrics: string;
	filters: string;
	start_date: string;
	end_date: string;
	status: string;
	email_subject: string;
	emails: string;
	account_email: number;
	file_type: string;
	scheduler_type_code: string;
	scheduler_type: string;
	report_type_code: string;
	report_type: string;
}

export enum GenerateReportTypeEnum {
	CAMPAIGN_REPORT_SP = "campaign_report",
	REACH_REPORT_SP = "reach_report",
	GEOGRAPHY_REPORT_SP = "geography_report",
	BILLING_REPORT_SP = "billing_report",
	MAIDS_REPORT_SP = "maids_report",
	STORE_ATRIBUTTION_REPORT_SP= "store_atributtion_report",
	OOH_REPORT_SP= "ooh_report",
	GEO_COORDINATES_REPORT_SP= "geo_coordinates_report",
	INSIGTHS_REPORT_SP="insigths_report",
}

export type GenerateReportType =
	| GenerateReportTypeEnum.CAMPAIGN_REPORT_SP
	| GenerateReportTypeEnum.REACH_REPORT_SP
	| GenerateReportTypeEnum.BILLING_REPORT_SP
	| GenerateReportTypeEnum.GEOGRAPHY_REPORT_SP;

export interface GenerateReportReponse {
	type: EnumReportType;
	success: boolean;
	status: EnumStatus;
	message: string;
	errors: any[];
	data: Record<any, any>;
}

export const EnumMessageType = {
	EMPTY: `${PATCH_REPORT_MESSAGE}.empty`,
	ERROR: `${PATCH_REPORT_MESSAGE}.error`,
	PENDING: `${PATCH_REPORT_MESSAGE}.pending`,
	SUCCESS: `${PATCH_REPORT_MESSAGE}.success`,
	DATA_RANGE: `${PATCH_REPORT_MESSAGE}.data_range`,
	ACCOUNT: `${PATCH_REPORT_MESSAGE}.account`,
	CAMPAIGN: `${PATCH_REPORT_MESSAGE}.campaign`,
	METRICS: `${PATCH_REPORT_MESSAGE}.metrics`,
	DIMENSIONS: `${PATCH_REPORT_MESSAGE}.dimensions`,
	DISCLAIMER: `${PATCH_REPORT_MESSAGE}.disclaimer`,
	REPORT_GENERATED: `${PATCH_REPORT_MESSAGE}.report_generated`,
	REPORT_TYPE_INVALID: `${PATCH_REPORT_MESSAGE}.report_type_invalid`,
	REPORT_JOB_ID: `${PATCH_REPORT_MESSAGE}.report_job_id`,
};

export interface IListDataPayload {
	type:string;
	selectAll:boolean;
}

export interface IReportingForm {
	report_type: any[];
	data_range: any[];
	type: any[];
	dimension: any[];
	metric: any[];
	filteredDimensions: any[];
	filteredMetrics: any[];
	account: any[];
	advertisers: any[];
	campaigns: any[];
	line_items: any[];
	creatives: any[];
	deal_id: any[];
	layer_name: any[];
	report_dimensions: Record<string, any>;
	report_metrics: Record<string, any>;
	report_all_type: any[];
	scheduler_type: any[];
}
export interface IFilterEntity {
	type: string;
	key: string;
	resourceName: string;
	requireFilter: boolean;
	filter: string | null;
	dependency?: string | null;
	delete: string[];
}