import { PollDataCreate, PollDataUpdate, PollFilters } from "@/interfaces/polls";
import { AxiosGet, AxiosPost, AxiosPatch, GetData } from "./axios-service";
import { catchServiceErrors } from "@/utils/services-global";
import ROUTES from "@/api/routes";

const { POLLS } = ROUTES;

class PollService {
  
  async all(filters?: PollFilters) {
    try {
      const url = this.buildUrlWithFilters(filters);
      const response = await AxiosGet(url);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async getQuestionsByPollId(poll_id: number) {
    try {
      const url = POLLS.POLL_QUESTIONS_ROUTE.replace("{poll_id}", String(poll_id));
      const response = await AxiosGet(url);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async submitResponses(payload: { poll_id: number; responses: Array<{ question_id: number; response: number }> }) {
    try {
      const url = POLLS.POLL_RESPONSES_ROUTE;
      const response = await AxiosPost(url, payload);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async pollCancel({ poll_id }: { poll_id: number }) {
    try {
      const url = POLLS.POLL_CANCEL_ROUTE; 
      const payload = { poll_id };
      const response = await AxiosPost(url, payload);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  private buildUrlWithFilters(filters?: PollFilters): string {
    let baseUrl = POLLS.POLLS_ROUTE;
    if (!filters) return baseUrl;
    const params = new URLSearchParams();

    if (filters.id) params.append("filters[id]", String(filters.id));
    if (typeof filters.active !== "undefined") {
      params.append("filters[active]", filters.active ? "1" : "0");
    }
    return `${baseUrl}?${params.toString()}`;
  }
}

export default new PollService();
