import { Paginated, ResultPaginate, SortingOption } from "@/interfaces/paginated";
import { AxiosGet, AxiosPost, GetData, GetErrors, GetMessage } from "@/services/axios-service";
import { prepareParams, modes, catchServiceErrors } from "@/utils/services-global";
import store from "@/store";
import ROUTES from "@/api/routes";

const ROUTE = ROUTES.NOTIFICATION;

const ENTITY: string = "notification";

class NotificationsService {
  /**
   * Get paginated notifications
   * @param params
   * @returns
   */
  async paginated(params: { paginated: Paginated; filters?: undefined; options?: SortingOption }) {
    try {
      const url = await prepareParams({
        route: ROUTE.NOTIFICATION_ROUTE,
        entity: ENTITY,
        mode: modes.PAGINATED,
        filter: undefined,
        ...params,
      });

      const response = await AxiosGet(url);

      const appLang = store.getters["internationalization/getLanguage"];

      /*
			let data = { success: false, response: [] };
			await fetch("/getNotifications.json")
			.then(response => {
				return response.json();
			})
			.then(jsondata => {
				data.success = jsondata.success;
				data.response = jsondata.response;
				
			});*/

      const preparedResponse: ResultPaginate = await prepareMessages(GetData(response), appLang);

      return Promise.resolve(preparedResponse);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  /**
   * Dismiss notificacions
   * @param ids
   * @returns
   */
  async dismiss(ids: string[]) {
    try {
      const response = await AxiosPost(ROUTE.NOTIFICATION_DISMISS, {
        notifications: ids,
      });
      return Promise.resolve(response);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  /**
   * Notification settings
   * Get all notification settings
   * @returns
   */
  async settings() {
    try {
      const url = await prepareParams({
        route: ROUTE.NOTIFICATION_SETTINGS_ROUTE,
        entity: ENTITY,
        mode: modes.ALL,
        filter: undefined,
      });
      /*
			const res = [
				{
					"code": "CAMP-01",
					"name": "MinRangeNotification",
					"description": "When the duration of a campaign is less than 6 days.",
					"model": "Campaign",
					"platform_notification_active": false,
					"email_notification_active": false
				},
				{
					"code": "CAMP-02",
					"name": "MiddleCtrNotification",
					"description": "When the CTR is between 0.5% and 0.8%.",
					"model": "Campaign",
					"platform_notification_active": false,
					"email_notification_active": true
				},
				{
					"code": "CAMP-03",
					"name": "CtrBudgetNotification",
					"description": "When a active campaign spend is equal to or greater than the budget",
					"model": "Campaign",
					"platform_notification_active": false,
					"email_notification_active": false
				}
			];*/

      const response = await AxiosGet(url);
      return Promise.resolve(GetData(response));

      //return Promise.resolve(res);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  /**
   * Notification settings
   * Set active notification
   * @param code
   * @param active
   * @returns
   */
  async setActive(code: string, notify_active: Boolean, email_active: Boolean) {
    try {
      const params = {
        code,
        platform_notification_active: notify_active ? 1 : 0,
        email_notification_active: email_active ? 1 : 0,
      };
      const response = await AxiosPost(`${ROUTE.NOTIFICATION_SET_ACTIVE}`, params, false);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }
}

/** UTILS */

export async function prepareMessages(response: any, lang: string) {
  let result = response;
  const keyLang: string = lang === "pt" ? "pr" : lang;

  let data = response.data.map((r: any) => {
    const messageData = r.data[keyLang];
    return {
      id: r.id,
      created_at: parseDateToLocalString(r.created_at),
      created: r.created_at,
      title: messageData.title,
      description: messageData.description,
      url: messageData.url,
      file_path: r.file_path,
      es: r.data.es,
      en: r.data.en,
      pr: r.data.pr,
    };
  });

  result.data = data;

  return result;
}

/**
 * Parse date
 * @param created_at
 * @returns
 */
const parseDateToLocalString = (created_at: string) => {
  let date = new Date(Date.parse(created_at));
  return date.toLocaleString();
};

export default new NotificationsService();
