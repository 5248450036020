import { AppName, AppSite, Site } from "@/interfaces/appSite";
import { Paginated, SortingOption } from "@/interfaces/paginated";
import {
  Targeting,
  TargetingDataCreate,
  TargetingDataUpdate,
  TargetingFilters,
  TargetingList,
} from "@/interfaces/targeting";
import { mappingTargetingKeys, resolveTargetingExpressions } from "@/utils/resolveObjectArray";
import { isEmpty, isUndefined } from "lodash";
import omitBy from "lodash/omitBy";
import { AxiosGet, AxiosPatch, AxiosPost, GetData, GetErrors, GetMessage } from "./axios-service";
import { catchServiceErrors } from "@/utils/services-global";
import { URLSearchParams } from "url";
import ROUTES from "@/api/routes";
const { APP_SITE, CUSTOM_LIST, TARGETING, GEO, EXCHANGE, ENVIROMENT, PLATFORM, CONTENT } = ROUTES;

class TargetingService {
  async all(filters?: TargetingFilters, options?: SortingOption, formatter?: Boolean) {
    try {
      let filter = "";
      let option = "";

      if (!isUndefined(filters)) {
        filter = getFilters(filters);
      }

      if (!isUndefined(options)) {
        option += getOptions(options, "all");
      }

      const url = getURL(filter, option);

      const response = await AxiosGet(TARGETING.TARGETING_ROUTE + url);

      return Promise.resolve(!!formatter ? resolveTargetingExpressions(GetData(response)) : GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async create(targeting: TargetingDataCreate) {
    try {
      if (isEmpty(targeting.targeting_terms))
        return Promise.reject({
          success: false,
          message: "Targeting empty",
          errors: [],
        });
      const response = await AxiosPost(TARGETING.TARGETING_ROUTE, targeting);
      return Promise.resolve(GetData(response) as Array<Targeting>);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async update(targeting: TargetingDataUpdate) {
    try {
      if (isEmpty(targeting.targeting_terms))
        return Promise.reject({
          success: false,
          message: "Targeting empty",
          errors: [],
        });
      const response = await AxiosPatch(`${TARGETING.TARGETING_ROUTE}/${targeting.id}`, targeting);
      return Promise.resolve(GetData(response) as Array<Targeting>);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }
  async getSummaryTargeting(id: number) {
    try {
      const response = await AxiosGet(`${TARGETING.SUMMARY_TARGETING_EXPRESSIONS_ROUTE}/${id}`);
      return Promise.resolve(GetData(response) as Array<any>);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  /**
   * getTargetingKeys
   * @returns
   */
  async getTargetingKeys() {
    try {
      const response = await AxiosGet(`${TARGETING.TARGETING_KEY_ROUTE}?mode=all`);
      const data = GetData(response);
      const targeting_keys = mappingTargetingKeys(data);
      return Promise.resolve(targeting_keys as Array<any>);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  /**
   * getTargetingKey
   * @param key
   * @returns
   */
  async getTargetingKey(key: String) {
    try {
      const response = await AxiosGet(await this.getUrlByKey(key));
      return Promise.resolve(GetData(response) as Array<AppSite>);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  /**
   * getAppNameByAtribute
   * @param term
   * @param by_attribute
   * @returns
   */
  async getAppNameByAtribute(term: String, by_attribute: String) {
    try {
      const url = APP_SITE.APP_NAME_ROUTE + "?term=" + term + "&by_attribute=" + by_attribute;
      const response = await AxiosGet(url);
      return Promise.resolve(GetData(response) as Array<AppName>);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }
  /**
   * getSearchByTerm
   * @param params
   * @returns
   */
  async getSearchByTerm(params: {
    key: any;
    term: any;
    object?: { key: String; value: String } | undefined;
    by_attribute?: any;
    filters?: Record<string, any>;
  }) {
    try {
      const queryParams: Record<string, any> = {
        term: params.term,
      };

      const urlParams = new globalThis.URLSearchParams(queryParams).toString();
      let url = (await this.getUrlByKey(params.key)) + "?" + urlParams;

      if (params.by_attribute) {
        url = url.concat("&by_attribute=" + params.by_attribute);
      }

      const response = await AxiosGet(url, {
        params: omitBy(params.filters, isEmpty),
      });

      return Promise.resolve(GetData(response) as Array<any>);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  /**
   * getSitesByAtribute
   * @param term
   * @param by_attribute
   * @returns
   */
  async getSitesByAtribute(term: String, by_attribute: String) {
    try {
      const url = APP_SITE.SITE_ROUTE + "?term=" + term + "&by_attribute=" + by_attribute;
      const response = await AxiosGet(url);
      return Promise.resolve(GetData(response) as Array<Site>);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async getTargetingPredicates() {
    try {
      const response = await AxiosGet(TARGETING.TARGETING_PREDICATE_ROUTE);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async getUrlByKey(key: String) {
    let url = "";

    switch (key) {
      /**
       * app_site
       */
      case "app_bundle_list":
        url = APP_SITE.APP_BUNDLE_LIST_ROUTE;
        break;
      case "app_id_list":
        url = APP_SITE.APP_ID_ROUTE;
        break;
      case "deal_id":
        url = CUSTOM_LIST.CUSTOM_LIST_EXCHANGE_ROUTE;
        //url = ROUTES.DEAL_ID_ROUTE
        break;
      case "deal_id_list":
        url = APP_SITE.DEAL_ID_LIST_ROUTE;
        break;
      case "domain_list":
        url = APP_SITE.DOMAIN_ROUTE;
        break;
      case "placement_id":
        url = APP_SITE.PLACEMENT_ROUTE;
        break;
      case "placement_list":
        url = APP_SITE.PLACEMENT_ROUTE;
        break;
      case "publisher_id":
        url = APP_SITE.PUBLISER_ID_ROUTE;
        break;
      case "publisher_id_list":
        url = APP_SITE.PUBLISER_ID_LIST_ROUTE;
        break;
      case "site_list":
        url = APP_SITE.SITE_LIST_ROUTE;
        break;

      /**
       * geo
       */
      case "city":
        url = GEO.CITY_ROUTE;
        break;

      case "country":
        url = GEO.COUNTRY_ROUTE;
        break;

      case "region":
        url = GEO.REGION_ROUTE;
        break;

      case "lat_long_list":
        url = GEO.LAT_LONG_ROUTE;
        break;

      /**
       * exchange
       */
      case "inventory_source":
        url = EXCHANGE.INVENTORY_SOURCE_ROUTE;
        break;

      case "auction_type":
        url = EXCHANGE.AUCTION_TYPE_ROUTE;
        break;

      /**
       * environment
       */
      case "environment_type":
        url = ENVIROMENT.ENVIROMENT_TYPE_ROUTE;
        break;

      case "topframe":
        url = ENVIROMENT.ENVIROMENT_TPFRAME_ROUTE;
        break;

      case "video_api":
        url = ENVIROMENT.ENVIROMENT_VIDEO_ROUTE;
        break;

      case "rewarded":
        url = ENVIROMENT.ENVIROMENT_REWARDED_ROUTE;
        break;

      /**
       * content
       */
      case "content_category":
        url = CONTENT.CONTENT_CATEGORY_ROUTE;
        break;

      case "language":
        url = CONTENT.CONTENT_LENGUAGE_ROUTE;
        break;

      /**
       * platform
       */
      case "browser":
        url = PLATFORM.PLATFORM_BROWSER_ROUTE;
        break;

      case "browser_version":
        url = PLATFORM.PLATFORM_BROWSER_VERSION_ROUTE;
        break;

      case "carrier":
        url = PLATFORM.PLATFORM_CARRIER_ROUTE;
        break;

      case "device_type":
        url = PLATFORM.PLATFORM_DEVICE_TYPE_ROUTE;
        break;

      case "device_make":
        url = PLATFORM.PLATFORM_DEVICE_MAKE_ROUTE;
        break;

      case "device_model":
        url = PLATFORM.PLATFORM_DEVICE_MODEL_ROUTE;
        break;

      case "device_screen_size":
        url = PLATFORM.PLATFORM_DEVICE_SCREEN_SIZE_ROUTE;
        break;

      case "operating_system":
        url = PLATFORM.PLATFORM_OPERATING_SYSTEM_ROUTE;
        break;

      case "operating_system_version":
        url = PLATFORM.PLATFORM_OPERATING_SYSTEM_VERSION_ROUTE;
        break;
    }

    return url;
  }
}

function getFilters(filters: TargetingFilters): string {
  let filter = "";

  const id = !!filters.id ? filters.id : "";
  const line_item_id = !!filters.line_item_id ? filters.line_item_id : "";
  const active = typeof filters.active === "undefined" ? "" : !!filters.active ? 1 : 0;

  filter +=
    "filters[advertisers.id]=" +
    id +
    "&filters[line_item_id]=" +
    line_item_id +
    "&filters[advertisers.active]=" +
    active;

  return filter;
}

function getOptions(options: SortingOption, mode: string, paginated?: Paginated): string {
  let option = "";

  const sort = isUndefined(options.sort) ? "" : options.sort;
  const order = isUndefined(options.order) ? "" : options.order;

  option += "sort=" + sort + "&order=" + order + "&mode=" + mode;

  if (mode == "paginated") {
    option += "&page=" + paginated?.page + "&limit=" + paginated?.limit;
  }

  return option;
}

function getURL(filters: string, options: string): string {
  let url = "";

  if (!isEmpty(filters) && !isEmpty(options)) {
    url = "?" + filters + "&" + options;
  } else if (!isEmpty(filters)) {
    url = "?" + filters;
  } else if (!isEmpty(options)) {
    url = "?" + options;
  }

  return url;
}

export default new TargetingService();
