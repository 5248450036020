import { Credential } from "@/interfaces/credential";
import { AxiosPost, GetData, GetErrors, GetMessage, AxiosGet } from "@/services/axios-service";
import { isUndefined } from "lodash";
import { AccountDataUpdate } from "@/interfaces/account";
import { catchServiceErrors } from "@/utils/services-global";
import { TwoFactor, TwoFactorCode } from "@/interfaces/user";
import { isArray } from "lodash";

export const LOGIN_ROUTE = "/api/auth/login";
export const INIT_ROUTE = "/api/auth/init";
export const LOGOUT_ROUTE = "/api/auth/logout";
export const FORGOT_PASSWORD_ROUTE = "/api/auth/password/forgot";
export const PASSWORD_RESET_ROUTE = "/api/auth/password/reset";
export const CHANGE_PASSWORD = "/api/auth/password/change";
import ROUTES from "@/api/routes";

const ROUTE = ROUTES.AUTH;

class AuthService {
  async login(credentials: Credential) {
    try {
      const response = await AxiosPost(LOGIN_ROUTE, credentials);

      if (isArray(response?.data?.response)) {
        const res = response?.data?.response[0];
        if (res.hasOwnProperty("two_factor_code_from")) {
          return Promise.resolve({
            two_factor: true,
            two_factor_code_from: res.two_factor_code_from,
            email: res.email,
          });
        }
      }

      return Promise.resolve({
        success: true,
        token: ProviderToken(response),
      });
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async init(initData: any) {
    try {
      const response = await AxiosPost(`${INIT_ROUTE}/${initData.init_token}`, initData);
      return Promise.resolve({
        success: true,
        token: ProviderToken(response),
      });
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async logout() {
    try {
      const response = await AxiosPost(LOGOUT_ROUTE, null);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async forgotPassword(email) {
    try {
      const response = await AxiosPost(FORGOT_PASSWORD_ROUTE, { email });
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async resetPassword({ email, password, password_confirmation, token }) {
    try {
      const response = await AxiosPost(PASSWORD_RESET_ROUTE, {
        email,
        password,
        password_confirmation,
        token,
      });
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async changePassword(account: AccountDataUpdate) {
    try {
      const response = await AxiosPost(`${CHANGE_PASSWORD}/${account.id}`, account);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  async getCodeQRGoogle() {
    try {
      const response = await AxiosGet(ROUTE.CODE_QR_GOOGLE_ROUTE);
      const res = await Promise.resolve(GetData(response));

      let data = {
        google_two_factor_code: res.google_two_factor_code,
        qr_svg: addClassSvg(res.qr_svg),
      };

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject({
        success: false,
        message: GetMessage(error),
        errors: GetErrors(error),
      });
    }
  }

  async setTwoFactorAuthenticator(params: TwoFactor) {
    try {
      const url = `${ROUTE.SET_TO_FACTOR_ROUTE}/${params.user_id}`;
      const response = await AxiosPost(url, params.two_factor);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return Promise.reject({
        success: false,
        message: GetMessage(error),
        errors: GetErrors(error),
      });
    }
  }

  async TwoFactorAuthenticator(params: TwoFactorCode) {
    try {
      const url = `${ROUTE.TWOFA_ROUTE}`;
      const response = await AxiosPost(url, params);
      return Promise.resolve({
        success: true,
        token: ProviderToken(response),
      });
    } catch (error) {
      return Promise.reject({
        success: false,
        message: GetMessage(error),
        errors: GetErrors(error),
      });
    }
  }

  async resetToken() {
    try {
      const response = await AxiosPost(ROUTE.REFRESH_ROUTE, null);
      return Promise.resolve({
        success: true,
        token: ProviderToken(response),
      });
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }
}

function addClassSvg(_imagenSVG: String) {
  let classAdd = ' class="QR" ';
  let index = _imagenSVG.indexOf("xmlns");
  return _imagenSVG.slice(0, index) + classAdd + _imagenSVG.slice(index);
}

/**
 * Util: has token on response
 * @param response
 */
export function HasProviderToken(response: any): boolean {
  if (isUndefined(response?.data?.success) && !response?.data?.success) return false;
  return Boolean(String(response?.data?.response?.access_token).length > 0);
}

/**
 * Util: get token
 * @param response
 */
export function ProviderToken(response: any): string {
  if (!HasProviderToken(response)) return "";
  return response?.data?.response?.access_token;
}

export default new AuthService();
