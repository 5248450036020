import FieldEntity from "@/models/FieldEntity";
import SelectedEntity from "@/models/SelectedEntity";
import {
	prepareSelectedData,
} from "@/utils/CustomizeViewData";

export const CustomizeViewModule = {
	namespaced: true,
	state: () => ({
		AdvertisersIndex: new FieldEntity(),
		CampaignsIndex: new FieldEntity(),
		LineItemList: new FieldEntity(),
		CreativesIndex: new FieldEntity(),
		SegmentsIndex: new FieldEntity(),
		ModifiersIndex: new FieldEntity(),
		UsersIndex: new FieldEntity(),
		RequestCampaignIndex: new FieldEntity(),
		PrivatePoisCreate: new FieldEntity(),
		EventIndex: new FieldEntity(),
		LookalikeIndex: new FieldEntity(),
		selected: {
			AdvertisersIndex: new SelectedEntity(),
			CampaignsIndex: new SelectedEntity(),
			LineItemList: new SelectedEntity(),
			CreativesIndex: new SelectedEntity(),
			SegmentsIndex: new SelectedEntity(),
			CustomListIndex: new SelectedEntity(),
			ModifiersIndex: new SelectedEntity(),
			UsersIndex: new SelectedEntity(),
			RequestCampaignIndex: new SelectedEntity(),
			PrivatePoisCreate: new SelectedEntity(),
			EventIndex: new SelectedEntity(),
			LookalikeIndex: new SelectedEntity()
		},
	}),
	mutations: {
		SET_FIELDS(state, params) {
			state[params.view] = new FieldEntity(params.fields);
		},
		SET_SELECTED(state, params) {
			const { view, tab, selected } = params;
			state.selected[view][tab] = selected;
		},
		SET_COLUMNS(state, params) {
			const { event, columns } = params;
			if (!event.view) return;
			state.selected[event.view]["columns"] = columns;
		},
		RESET_SELECTED_COLUMNS(state) {
			Object.keys(state.selected).map((key) => {
				state.selected[key].columns = [];
			});
		},
		RESET_MODULE(state) {
			state.AdvertisersIndex = new FieldEntity();
			state.CampaignsIndex = new FieldEntity();
			state.LineItemList = new FieldEntity();
			state.CreativesIndex = new FieldEntity();
			state.SegmentsIndex = new FieldEntity();
			state.ModifiersIndex = new FieldEntity();
			state.UsersIndex = new FieldEntity();
			state.RequestCampaignIndex = new FieldEntity();
			state.PrivatePoisCreate = new FieldEntity();
			state.EventIndex = new FieldEntity();
			state.LookalikeIndex = new FieldEntity();

			state.selected = {
				AdvertisersIndex: new SelectedEntity(),
				CampaignsIndex: new SelectedEntity(),
				LineItemList: new SelectedEntity(),
				CreativesIndex: new SelectedEntity(),
				SegmentsIndex: new SelectedEntity(),
				ModifiersIndex: new SelectedEntity(),
				UsersIndex: new SelectedEntity(),
				RequestCampaignIndex: new SelectedEntity(),
				PrivatePoisCreate: new SelectedEntity(),
				EventIndex: new SelectedEntity(),
				LookalikeIndex: new SelectedEntity(),
			}
		},
	},
	getters: {
		getFieldByView:
			(state) =>
			(view: string): FieldEntity => {
				return state[view];
			},
		getSelectedByView:
			(state) =>
			(view: string): SelectedEntity => {
				return state.selected[view];
			},
	},
	actions: {
		setFields({ commit }, params) {
			return new Promise((resolve) => {
				commit("SET_FIELDS", params);
				resolve(params);
			});
		},

		saveSelected({ commit }: any, params: { dimensions: any, metrics: any }) {
			return new Promise((resolve, reject) => {

				for (const [key, value] of Object.entries(params)) {
					commit("SET_SELECTED", value);
				}
				
				resolve(params);
			});
		},

		resetSelectedColumns({ commit }) {
			commit("RESET_SELECTED_COLUMNS");
		},

		saveColumns({ commit }, params: any) {
			// return new Promise((resolve) => {

			// 	if(params.dimensions || params.metrics) {
			// 		for (const [key, value] of Object.entries(params)) {
			// 			commit("SET_COLUMNS", value);
			// 		}
			// 	}
			// 	else if(!params.dimensions && !params.metrics) {
			// 		console.warn(`[saveColumns] {else}`, { params });
			// 		//commit("SET_COLUMNS", params);
			// 	}

			// 	resolve(params);
			// });
		},

		resetModule({commit}) {
			commit("RESET_MODULE");
		},

		async getTableDataSelected(
			{ commit, getters },
			params: { route: string; entity: any[] }
		) {
			const selected: SelectedEntity = await getters.getSelectedByView(
				params.route
			);

			const fields = await prepareSelectedData(selected);

			return {
				selected,
				fields,
			};
		},
	},
};
