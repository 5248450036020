import { AxiosGet, GetData, GetErrors, GetMessage } from "@/services/axios-service";
import { catchServiceErrors } from "@/utils/services-global";
import axios, { AxiosResponse } from "axios";
import ROUTES from "@/api/routes";

const ROUTE = ROUTES.REPORT;

class DashboardService {
  async reportList() {
    try {
      const response = await AxiosGet(ROUTE.REPORT_LIST_ROUTE);
      return Promise.resolve(prepareDashboardData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }
  async lookerUrl() {
    try {
      const response = await AxiosGet(ROUTE.LOOKER_ENDPOINT);
      return Promise.resolve(response.data?.response);
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }
}

function prepareDashboardData(response: AxiosResponse<any>) {
  const result: Array<any> = GetData(response);
  return result.map((r: any, index: number) => {
    return {
      key: index,
      code: r.code,
      tab: r.name,
      iframe_src: r.src,
      icon: "", // mdi-poll
      disabled: false,
    };
  });
}

export default new DashboardService();
