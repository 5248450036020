import {
	ConfigPaginate,
	ConfigPost,
	ConfigPostFilter,
} from "@/interfaces/persons/v10/response";
import { TotalTypeAll } from "@/interfaces/persons/v10/select_all";
import {
	PersonGeoKey,
	PersonOohKey,
	PersonPoisKey,
	PersonPosKey,
} from "@/interfaces/persons/v10/types";
import { GetErrors, GetMessage } from "@/services/axios-service";
import { isArray, isEmpty, isString } from "lodash";

const omitGeoFilters = ["codigo_ciudad", "codigo_estado", "codigo_barrio"];

const omitFilters = [
	"categories",
	"subcategories",
	"brands",
	"names",
	"ooh_categories",
	"ooh_subcategories",
	"ooh_brands",
	"ooh_names",
	"privates",
];

export class ConfigPaginateEntity implements ConfigPaginate {
	mode: "paginated" = "paginated";
	page: number = 1;
	limit: number = 25;
	search?: string;
	filters?: any;
	country_code?: number;

	constructor(parametters?: { country_code?: number }) {
		this.country_code = parametters?.country_code;
	}
}

export class ConfigPostFilterEntity implements ConfigPostFilter {
	/**
	 * GEO
	 */
	codigo_estado?: string[];
	_like_estado?: string;

	codigo_municipio?: string[];
	_like_municipio?: string;

	codigo_ciudad?: string[];
	_like_ciudad?: string;

	codigo_barrio?: string[];
	_like_barrio?: string;

	/**
	 * POIS
	 */
	categoria?: string[];

	subcategoria?: string[];
	_like_categoria?: string;

	marca?: string[];
	_like_subcategoria?: string;

	nombre?: string[];
	_like_nombre?: string;

	/**
	 * OOH
	 */
	ooh_categoria?: string[];
	_like_ooh_categoria?: string;

	ooh_subcategoria?: string[];
	_like_ooh_subcategoria?: string;

	ooh_marca?: string[];
	_like_ooh_marca?: string;

	ooh_nombre?: string[];
	_like_ooh_nombre?: string;

	/**
	 * POS
	 */
	macro?: string[];
	_like_macro?: string;

	category?: string[];
	_like_category?: string;

	company?: string[];
	_like_company?: string;

	brand?: string[];
	_like_brand?: string;

	name_sku?: string[];
	_like_name_sku?: string;

	store_type?: string[];
	_like_store_type?: string;

	/**
	 * PRIVATES
	 */
	layer_name?: string;

	layers: number[] = [];

	country_name?: string;

	constructor() {}

	getPayload(): Record<string, any> {
		const payload: Record<string, any> = {};

		// Iterar sobre las propiedades de la instancia
		for (const key of Object.keys(this)) {
			const filterValue = this[key];

			// Si la propiedad está en la lista de omitGeoFilters, agregar al payload
			if (omitGeoFilters.includes(key)) {
				payload[key] = filterValue;
			}

			// Si la propiedad no está en la lista de omitFilters y no está vacía, agregar al payload
			else if (!omitFilters.includes(key) && isArray(filterValue)) {
				payload[key] = !isEmpty(filterValue) ? filterValue : [];
			} else if (omitFilters.includes(key)) {
				payload[key] = undefined;
			}
			
			if (isString(filterValue)) {
				payload[key] = isEmpty(filterValue) ? undefined : filterValue;
			}
		}

		return payload;
	}

	setPayload(newFilters: Record<string, any>) {
		Object.assign(this, newFilters);
	}
}

const matchedFilterlikeData = {
	geography: {
		states: {
			filter: "codigo_estado",
			like: "_like_dpto",
		},
		cities: {
			filter: "codigo_ciudad",
			like: "_like_ciudad",
		},
		neighborhoods: {
			filter: "codigo_barrio",
			like: "_like_barrio",
		},
	},
	geo: {
		states: {
			filter: "codigo_estado",
			like: "_like_estado",
		},
		cities: {
			filter: "codigo_ciudad",
			like: "_like_ciudad",
		},
		neighborhoods: {
			filter: "codigo_barrio",
			like: "_like_barrio",
		},
	},
	pois: {
		categories: {
			filter: "categoria",
			like: "_like_categoria",
		},
		subcategories: {
			filter: "subcategoria",
			like: "_like_subcategoria",
		},
		brands: {
			filter: "marca",
			like: "_like_marca",
		},
		names: {
			filter: "nombre",
			like: "_like_nombre",
		},
	},
	privates: {
		privates: {
			filter: "privates",
			like: "layer_name",
		},
	},
	ooh: {
		ooh_categories: {
			filter: "ooh_categoria",
			like: "_like_ooh_categoria",
		},
		ooh_subcategories: {
			filter: "ooh_subcategoria",
			like: "_like_ooh_subcategoria",
		},
		ooh_brands: {
			filter: "ooh_marca",
			like: "_like_ooh_marca",
		},
		ooh_names: {
			filter: "ooh_nombre",
			like: "_like_ooh_nombre",
		},
	},
	pos: {
		chipper_states: {
			filter: "codigo_estado",
			like: "_like_codigo_estado",
		},
		chipper_cities: {
			filter: "codigo_ciudad",
			like: "_like_codigo_ciudad",
		},
		chipper_neighborhoods: {
			filter: "codigo_barrio",
			like: "_like_codigo_barrio",
		},
		macro: {
			filter: "macro",
			like: "_like_macro",
		},
		category: {
			filter: "category",
			like: "_like_category",
		},
		company: {
			filter: "company",
			like: "_like_company",
		},
		brand: {
			filter: "brand",
			like: "_like_brand",
		},
		name_sku: {
			filter: "name_sku",
			like: "_like_name_sku",
		},
		store_type: {
			filter: "store_type",
			like: "_like_store_type",
		},
	},
};

export class ConfigPostEntity implements ConfigPost {
	country_code?: number;
	strategy?: number;
	use_case?: number;
	quintile_min?: number;
	quintile_max?: number;
	filters: ConfigPostFilter = new ConfigPostFilterEntity();
	constructor(parametters?: {
		country_code?: number;
		strategy?: number;
		use_case?: number;
	}) {
		this.country_code = parametters?.country_code;
		this.strategy = parametters?.strategy;
		this.use_case = parametters?.use_case;
	}

	addLayers(layers: number[] = []) {
		this.filters.layers = layers;
	}

	addGeoFilter(
		filter: PersonGeoKey,
		likeData: TotalTypeAll,
		value: string[]
	) {
		const { total, shown } = likeData;

		this.filters[matchedFilterlikeData.geo[filter].filter] = total.checked
			? []
			: value;
		this.filters[matchedFilterlikeData.geo[filter].like] = total.checked
			? total.term
			: undefined;
	}

	addPoisFilter(
		filter: PersonPoisKey,
		likeData: TotalTypeAll,
		value: string[]
	) {
		const { total, shown } = likeData;

		this.filters[matchedFilterlikeData.pois[filter].filter] = total.checked
			? []
			: value;

		this.filters[matchedFilterlikeData.pois[filter].like] = total.checked
			? total.term
			: undefined;
	}

	addOohFilter(
		filter: PersonPoisKey | PersonOohKey,
		likeData: TotalTypeAll,
		value: string[]
	) {
		const { total, shown } = likeData;

		this.filters[matchedFilterlikeData.ooh[filter].filter] = total.checked
			? []
			: value;

		this.filters[matchedFilterlikeData.ooh[filter].like] = total.checked
			? total.term
			: undefined;
	}

	addPosFilter(
		filter: PersonPosKey,
		likeData: TotalTypeAll,
		value: string[]
	) {
		const { total, shown } = likeData;

		this.filters[matchedFilterlikeData.pos[filter].filter] = total.checked
			? []
			: value;

		this.filters[matchedFilterlikeData.pos[filter].like] = total.checked
			? total.term
			: undefined;
	}

	addPosQuintils(quintils: (string | number)[]) {
		const [quintile_min, quintile_max] = quintils;

		this.quintile_min = quintile_min ? Number(quintile_min) : 0;
		this.quintile_max = quintile_max ? Number(quintile_max) : 5;
	}
}

export class RejectError {
	success = false;
	message: string;
	errors: any;

	constructor(error: any) {
		this.message = GetMessage(error);
		this.errors = GetErrors(error);
	}
}
