import { UserLanguage } from "@/interfaces/internalization";
import { AxiosGet, AxiosPost, GetData, GetErrors, GetMessage } from "@/services/axios-service";
import { catchServiceErrors } from "@/utils/services-global";
import ROUTES from "@/api/routes";

const INTERNALIZATION = ROUTES.INTERNALIZATION;

class InternalizationService {
  async getLanguages() {
    try {
      const response = await AxiosGet(`${INTERNALIZATION.TRANSLATION_LANGUAGES}`);
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }
  async setLanguage(params: UserLanguage) {
    try {
      const response = await AxiosPost(`api/users/${params.id}/setLanguage`, { language: params.language });
      return Promise.resolve(GetData(response));
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }
}

export default new InternalizationService();
