import { isAuthenticated } from "./utils";

/**
 * Meta:
 * requiresAuth: No requiere autenticacion
 * retorna a Root si esta autenticado
 */

export default async function ({ from, next, router, to }) {
	if (isAuthenticated()) {
		return router
			.push({
				name: "Root",
			})
			.catch((failure: any) => {
				//console.error("Guest error on push", { failure });
			});
	}

	return next();
}
