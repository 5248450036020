///
/// SOLO CONFIGURACIONES DE AXIOS
///

import axios, {
	AxiosRequestConfig,
	AxiosResponse,
	CancelTokenSource,
} from "axios";
import { HasProviderToken, ProviderToken } from "@/services/auth-service";
import store from "@/store";
import { hasBearer, isValidToken } from "@/services/jwt-service";
import { isArray, isString, isUndefined } from "lodash";

import router from "@/router";
import {
	getTokenFromStorage,
	setTokenToStorage,
} from "@/services/storage-service";
import { MessageTypes } from "@/interfaces/proccess";
import {
	ErrorResponse,
	isAxiosCancel,
	prepareFileName,
} from "@/utils/services-global";
import { ResultData } from "@/interfaces/persons/v10/response";
import { DownloadType, EnumReportType } from "@/interfaces/report";
import { sleep } from "@/utils/convert";
import { isWillBeRetried } from "@/store/Modules/Persons/v10/utils";
import { IAxiosAttemptDownloadConfig } from "@/interfaces/persons/v11/axios_download";

/**
 * Set axios configuration defaults
 */
axiosDefaultsConfig(process.env.VUE_APP_API_URL);

/**
 * Get token from storage
 */
let token: string = getTokenFromStorage();

/**
 * Verify if token is valid
 */
let isValid: boolean = isValidToken(token);

/**
 * Add the token in the axios header
 */
setAuthentication(token || "");

const pendingRequests: { [key: string]: CancelTokenSource } = {};

// Función para extraer la parte base de la URL (sin parámetros)
function getBaseURL(url: string): string {
	return url.split("?")[0];
}

// Interceptor de solicitud para cancelar peticiones anteriores
// Add a request interceptor
axios.interceptors.request.use(
	async function (config) {
		const currentEndpoint = getBaseURL(config.url || "");

		if (currentEndpoint) {
			if (pendingRequests[currentEndpoint]) {
				pendingRequests[currentEndpoint].cancel(
					"Previous request to the same endpoint was canceled"
				);
			}

			const source: CancelTokenSource = axios.CancelToken.source();
			config.cancelToken = source.token;
			pendingRequests[currentEndpoint] = source;
		}

		const result = await verifyToken();
		token = result._token;
		isValid = result._isValid;
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

// Add a response interceptor
axios.interceptors.response.use(
	async function (response) {
		const currentEndpoint: string | undefined = response.config.url;
		if (currentEndpoint) {
			delete pendingRequests[currentEndpoint];
		}

		if (HasProviderToken(response)) {
			const provider = ProviderToken(response);

			isValid = isValidToken(provider);

			if (hasBearer(provider) && isValid) {
				setTokenToStorage(provider);
				setAuthentication(provider);
			}
		}

		return response;
	},
	function (error) {
		if (isAxiosCancel(error)) {
			// Manejar específicamente peticiones canceladas
			return Promise.reject(error);
		}

		const currentEndpoint = error.config?.url;
		delete pendingRequests[currentEndpoint];

		if (error.code == "ERR_NETWORK") {
			store.commit("proccess/SET_NOTIFICATION", {
				message: error.message || "ERR_NETWORK",
				type: MessageTypes.ERROR,
				title: "",
				btn_text: "",
				details: {},
				show: true,
			});
		}

		if (error.response && error.response.status === 401) {
			setTokenToStorage("");
			setAuthentication("");
			store.commit("auth/SET_TOKEN", "");
			router.push({ path: "/auth/login" });
		}

		//console.error("AxiosService.interceptors", { error });

		return Promise.reject(error);
	}
);

/**
 * POST
 * @param url
 * @param payload
 */
export function AxiosPost(
	url: string,
	payload: any,
	has_file: boolean = false,
	isArrayBuffer: boolean = false
) {
	var headers: AxiosRequestConfig<any> = {
		headers: {
			"Content-Type": has_file
				? "multipart/form-data"
				: "application/json",
		},
	};

	if (isArrayBuffer) {
		headers["responseType"] = "arraybuffer";
	}

	return axios.post(url, payload, headers);
}

/**
 * GET
 * @param url
 */
export function AxiosDelete(url: string, payload?: any) {
	return axios.delete(url, { data: payload });
}

export function AxiosDeleteWithBody(url: string, params: any) {
	return axios.delete(url, { data: params });
}

/**
 * GET
 * @param url
 */
export function AxiosGet(url: string, config?: AxiosRequestConfig<any>) {
	return axios.get(url, config);
}

/**
 * GET with data
 * @param url
 * @param data
 * @returns
 */
export function AxiosGetData(url: string, data?: any) {
	const requestHeader = {
		"Content-Type": "application/json",
	};
	return axios.get(url, { params: data, headers: requestHeader });
}

/**
 * PUT
 * @param url
 * @param payload
 */
export function AxiosPut(url: string, payload: any) {
	return axios.put(url, payload);
}

/**
 * PATCH
 * @param url
 * @param payload
 */
export function AxiosPatch(url: string, payload: any) {
	return axios.patch(url, payload);
}

const getFormData = (attributes) => {
	let formData = new FormData();
	Object.keys(attributes).forEach((key, value) => {
		if (Array.isArray(attributes[key])) {
			formData.append(key, JSON.stringify(attributes[key]));
		} else {
			formData.append(key, attributes[key]);
		}
	});
	return formData;
};

export function AxiosUpload(url: string, payload: any, onUploadProgress: any) {
	const formData = getFormData(payload);
	return axios.post(url, formData, {
		onUploadProgress,
		headers: { "Content-Type": "multipart/form-data" },
	});
}

/**
 * GET
 * @param url
 */
export async function AxiosDownload(url: string, title: string) {
	const response = await axios.get(url, { responseType: "blob" });
	forceFileDownload(response, title);
}

/**
 * Download
 * POST
 * @param url
 * @param title
 * @param data
 */
export async function AxiosPostDownload(
	url: string,
	title: string,
	data: any,
	extension = "csv"
) {
	const response = await axios.post(url, data, { responseType: "blob" });
	forceFileDownload(response, prepareFileName(title, extension));
	return response;
}

/**
 * Download
 * Get
 * @param url
 * @param title
 * @param data
 */
export async function AxiosGetDownload(url: string, title: string) {
	const response = await axios.get(url, { responseType: "blob" });
	forceFileDownload(response, prepareFileName(title));
}

export class AxiosAttemptDownload {
	title: string;
	maxAttempts = 30;
	wait = 2000;

	constructor(title: string, config?: IAxiosAttemptDownloadConfig) {
		this.title = title;

		if (config) {
			if (!isUndefined(config.maxAttempts))
				this.maxAttempts = config.maxAttempts;
			if (!isUndefined(config.waitTime)) this.wait = config.waitTime;
		}
	}

	private async attemptFetch(METHOD: string, url: string, data?: any) {
		return new Promise(async (resolve, reject) => {
			let attempt = 0;
			let willBeRetried = true;

			while (willBeRetried && attempt < this.maxAttempts) {
				try {
					// Obtener datos desde la API
					const response = await axios(url, {
						responseType: "blob",
						method: METHOD,
						data,
					});

					if (response.status < 200 && response.status >= 300)
						return reject(ErrorResponse(response));
					const result: ResultData = response.data;

					// Si la respuesta es un strig (PENDING | PROCESSING) se volvera a realizar el llamado a la API
					// La respuesta null se considera como sin datos | No deberia volver a hacer el llamado
					willBeRetried = await isWillBeRetried(result);

					// Setear loading para el grafico

					if (!willBeRetried) {
						/**
						 * Parsear y Setear datos {source}
						 */

						forceFileDownload(
							response,
							prepareFileName(this.title)
						);
						resolve({ success: true });
					} else {
						/**
						 * if {willBeRetried} reintentar en {x} segundos
						 */
						await sleep(this.wait);
					}
				} catch (error) {
					reject({ error });
					willBeRetried = false;
					return undefined;
				}
			}
		});
	}

	async get(url: string) {
		this.attemptFetch("GET", url);
	}

	async post(url: string, data: any) {
		this.attemptFetch("POST", url, data);
	}
}

/**
 * GET
 * @param url
 */
export async function ForceDownload(
	response: AxiosResponse<any>,
	title: string
) {
	forceFileDownload(response, title);
}

export async function dataForceDownload(
	response: string,
	title: string,
	type: EnumReportType
) {
	dataDownload(response, title, type);
}

function forceFileDownload(response: AxiosResponse<any>, title: string) {
	const url = window.URL.createObjectURL(new Blob([response.data]));
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", title);
	document.body.appendChild(link);
	link.click();
}

function dataDownload(data: string, title: string, type: EnumReportType) {
	const url = window.URL.createObjectURL(
		new Blob([data], { type: DownloadType[type] })
	);
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", title);
	document.body.appendChild(link);
	link.click();
}

// RESPONSE BOOLEAN CHECK //

/**
 * HasSuccess
 * @param response AxiosResponse<any>
 */
export function HasSuccess(response: AxiosResponse<any>) {
	return Boolean(
		response.status >= 200 &&
			response.status < 300 &&
			typeof response.data.success !== typeof undefined &&
			response.data.success
	);
}

/**
 * HasError
 * @param response AxiosResponse<any>
 */
export function HasError(response: AxiosResponse<any>) {
	return Boolean(
		(response.status < 200 || response.status > 300) &&
			!response.data.success
	);
}

/**
 * HasErrors
 * @param error AxiosResponse<any>
 */
export function HasErrors(error: any) {
	return Boolean(error.response?.data?.errors);
}

/**
 * HasMessage
 * @param response AxiosResponse<any>
 */
export function HasMessage(response: AxiosResponse<any>) {
	return String(response.data?.message).length > 0;
}

// GET DATA FROM RESPONSE //

/**
 * GetData
 * @param response AxiosResponse<any>
 */
export function GetData(response: AxiosResponse<any>) {
	if (isArray(response.data)) return response.data;
	return response?.data?.response || [];
}

/**
 * GetDataResponse
 * Se verifica si el { data } es un string | array | object
 * @param response AxiosResponse<any>
 */
export function GetDataResponse(response: AxiosResponse<any>) {
	if (isString(response.data)) return response.data;
	if (isArray(response.data)) return response.data;
	return response?.data?.response;
}

export function ForceGetData(response: AxiosResponse<any>) {
	return response.data as ResultData;
}

/**
 * GetDataError
 * @param error AxiosError<any>
 */
export function GetDataError(error: any) {
	return error.response?.data;
}

/**
 * GetErrors
 * @param error AxiosError<any>
 */
export function GetErrors(error: any) {
	return error.response?.data?.errors || {};
}

/**
 * GetMessage
 * @param response AxiosError<any>
 */
export function GetMessage(error: any) {
	return error.response?.data?.message;
}

/**
 * Set Authorization
 * @param token string
 */
export function setAuthentication(token: string = "") {
	axios.defaults.headers.common.Authorization = token;
}

export async function verifyToken() {
	let _token = getTokenFromStorage();
	let _isValid: boolean = isValidToken(_token);
	return { _token, _isValid };
}

/**
 * Set axios configuration defaults
 */
export function axiosDefaultsConfig(baseUrl: string = "") {
	axios.defaults.baseURL = baseUrl;
	axios.defaults.headers.common = {
		"Access-Control-Allow-Origin": "*",
		// Request methods you wish to allow
		"Access-Control-Allow-Methods":
			"GET, POST, OPTIONS, PUT, PATCH, DELETE",
		// Request headers you wish to allow
		"Access-Control-Allow-Headers": "Content-Type,Authorization",
		"Access-Control-Allow-Credentials": true,
		Accept: "application/json",
	};
}
