import {
	ModuleSelectAll,
	SelectAll,
	SelectAllPrivate,
	TotalTypeAll,
} from "@/interfaces/persons/v10/select_all";
import {
	PersonFilterKey,
	PersonGeoKey,
	PersonOohKey,
	PersonPoisKey,
	PersonPosKey,
	PersonPrivateKey,
	TotalType,
} from "@/interfaces/persons/v10/types";
import { isUndefined } from "lodash";

export class SelectAllEntity implements SelectAll {
	loading: boolean = false;
	disabled: boolean = false;
	checked: boolean = false;
	term: string = "";

	constructor(properties?: SelectAllEntity) {
		if (properties) {
			this.loading = properties.loading;
			this.disabled = properties.disabled;
			this.checked = properties.checked;
			this.term = properties.term;
		}
	}
}

export class TotalTypeAllEntity implements TotalTypeAll {
	total: SelectAllEntity = new SelectAllEntity();
	shown: SelectAllEntity = new SelectAllEntity();

	constructor(properties?: TotalTypeAllEntity) {
		if (properties) {
			this.total = new SelectAllEntity(properties.total);
			this.shown = new SelectAllEntity(properties.shown);
		}
	}

	hasTerm() {
		return this.total.term !== "";
	}

	reset(type: "total" | "shown") {
		this[type] = {
			...this[type],
			checked: false,
			loading: false,
			disabled: false,
		};
	}
}

export class SelectAllGeoEntity {
	[PersonGeoKey.STATES]: TotalTypeAllEntity = new TotalTypeAllEntity();
	[PersonGeoKey.CITIES]: TotalTypeAllEntity = new TotalTypeAllEntity();
	[PersonGeoKey.NEIGHBORHOODS]: TotalTypeAllEntity = new TotalTypeAllEntity();

	constructor(properties?: SelectAllGeoEntity) {
		if (properties) {
			this[PersonGeoKey.STATES] = new TotalTypeAllEntity(
				properties[PersonGeoKey.STATES]
			);
			this[PersonGeoKey.CITIES] = new TotalTypeAllEntity(
				properties[PersonGeoKey.CITIES]
			);
			this[PersonGeoKey.NEIGHBORHOODS] = new TotalTypeAllEntity(
				properties[PersonGeoKey.NEIGHBORHOODS]
			);
		}
	}
}

export class SelectAllPoisEntity {
	[PersonPoisKey.CATEGORIES]: TotalTypeAllEntity = new TotalTypeAllEntity();
	[PersonPoisKey.SUBCATEGORIES]: TotalTypeAllEntity =
		new TotalTypeAllEntity();
	[PersonPoisKey.BRANDS]: TotalTypeAllEntity = new TotalTypeAllEntity();
	[PersonPoisKey.NAMES]: TotalTypeAllEntity = new TotalTypeAllEntity();

	constructor(properties?: SelectAllPoisEntity) {
		if (properties) {
			this[PersonPoisKey.CATEGORIES] = new TotalTypeAllEntity(
				properties[PersonPoisKey.CATEGORIES]
			);
			this[PersonPoisKey.SUBCATEGORIES] = new TotalTypeAllEntity(
				properties[PersonPoisKey.SUBCATEGORIES]
			);
			this[PersonPoisKey.BRANDS] = new TotalTypeAllEntity(
				properties[PersonPoisKey.BRANDS]
			);
			this[PersonPoisKey.NAMES] = new TotalTypeAllEntity(
				properties[PersonPoisKey.NAMES]
			);
		}
	}
}

export class SelectAllPrivateEntity implements SelectAllPrivate {
	[PersonPrivateKey.PRIVATES]: TotalTypeAllEntity = new TotalTypeAllEntity();

	constructor(properties?: SelectAllPrivateEntity) {
		if (properties) {
			this[PersonPrivateKey.PRIVATES] = new TotalTypeAllEntity(
				properties[PersonPrivateKey.PRIVATES]
			);
		}
	}
}

export class SelectAllOhhEntity {
	[PersonOohKey.OOH_CATEGORIES]: TotalTypeAllEntity =
		new TotalTypeAllEntity();
	[PersonOohKey.OOH_SUBCATEGORIES]: TotalTypeAllEntity =
		new TotalTypeAllEntity();
	[PersonOohKey.OOH_BRANDS]: TotalTypeAllEntity = new TotalTypeAllEntity();
	[PersonOohKey.OOH_NAMES]: TotalTypeAllEntity = new TotalTypeAllEntity();

	constructor(properties?: SelectAllOhhEntity) {
		if (properties) {
			this[PersonOohKey.OOH_CATEGORIES] = new TotalTypeAllEntity(
				properties[PersonOohKey.OOH_CATEGORIES]
			);
			this[PersonOohKey.OOH_SUBCATEGORIES] = new TotalTypeAllEntity(
				properties[PersonOohKey.OOH_SUBCATEGORIES]
			);
			this[PersonOohKey.OOH_BRANDS] = new TotalTypeAllEntity(
				properties[PersonOohKey.OOH_BRANDS]
			);
			this[PersonOohKey.OOH_NAMES] = new TotalTypeAllEntity(
				properties[PersonOohKey.OOH_NAMES]
			);
		}
	}
}

export class SelectAllPosEntity {
	[PersonPosKey.CHIPPER_STATES]: TotalTypeAllEntity =
		new TotalTypeAllEntity();
	[PersonPosKey.CHIPPER_CITIES]: TotalTypeAllEntity =
		new TotalTypeAllEntity();
	[PersonPosKey.CHIPPER_NEIGHBORHOODS]: TotalTypeAllEntity =
		new TotalTypeAllEntity();

	[PersonPosKey.CHIPPER_MACRO_CATEGORIES]: TotalTypeAllEntity =
		new TotalTypeAllEntity();
	[PersonPosKey.CHIPPER_CATEGORIES]: TotalTypeAllEntity =
		new TotalTypeAllEntity();
	[PersonPosKey.CHIPPER_COMPANIES]: TotalTypeAllEntity =
		new TotalTypeAllEntity();
	[PersonPosKey.CHIPPER_BRANDS]: TotalTypeAllEntity =
		new TotalTypeAllEntity();
	[PersonPosKey.CHIPPER_NAMES_SKU]: TotalTypeAllEntity =
		new TotalTypeAllEntity();
	[PersonPosKey.CHIPPER_STORES_TYPE]: TotalTypeAllEntity =
		new TotalTypeAllEntity();

	constructor(properties?: SelectAllPosEntity) {
		if (properties) {
			this[PersonPosKey.CHIPPER_STATES] = new TotalTypeAllEntity(
				properties[PersonPosKey.CHIPPER_STATES]
			);

			this[PersonPosKey.CHIPPER_CITIES] = new TotalTypeAllEntity(
				properties[PersonPosKey.CHIPPER_CITIES]
			);

			this[PersonPosKey.CHIPPER_NEIGHBORHOODS] = new TotalTypeAllEntity(
				properties[PersonPosKey.CHIPPER_NEIGHBORHOODS]
			);

			this[PersonPosKey.CHIPPER_MACRO_CATEGORIES] =
				new TotalTypeAllEntity(
					properties[PersonPosKey.CHIPPER_MACRO_CATEGORIES]
				);

			this[PersonPosKey.CHIPPER_CATEGORIES] = new TotalTypeAllEntity(
				properties[PersonPosKey.CHIPPER_CATEGORIES]
			);

			this[PersonPosKey.CHIPPER_COMPANIES] = new TotalTypeAllEntity(
				properties[PersonPosKey.CHIPPER_COMPANIES]
			);

			this[PersonPosKey.CHIPPER_BRANDS] = new TotalTypeAllEntity(
				properties[PersonPosKey.CHIPPER_BRANDS]
			);

			this[PersonPosKey.CHIPPER_NAMES_SKU] = new TotalTypeAllEntity(
				properties[PersonPosKey.CHIPPER_NAMES_SKU]
			);

			this[PersonPosKey.CHIPPER_STORES_TYPE] = new TotalTypeAllEntity(
				properties[PersonPosKey.CHIPPER_STORES_TYPE]
			);
		}
	}
}

export class ModuleSelectAllEntity {
	geo: SelectAllGeoEntity = new SelectAllGeoEntity();
	pois: SelectAllPoisEntity = new SelectAllPoisEntity();
	privates: SelectAllPrivateEntity = new SelectAllPrivateEntity();
	ooh: SelectAllOhhEntity = new SelectAllOhhEntity();
	pos: SelectAllPosEntity = new SelectAllPosEntity();

	constructor(properties?: ModuleSelectAllEntity) {
		if (properties) {
			this.geo = new SelectAllGeoEntity(properties.geo);
			this.pois = new SelectAllPoisEntity(properties.pois);
			this.privates = new SelectAllPrivateEntity(properties.privates);
			this.ooh = new SelectAllOhhEntity(properties.ooh);
			this.pos = new SelectAllPosEntity(properties.pos);
		}
	}

	async resetByType(type: PersonFilterKey) {
		for (const [key, value] of Object.entries(this[type])) {
			value.reset("total");
			value.reset("shown");
		}
	}

	/**
	 * Get value by {type} & {key}
	 * @param type
	 * @param name
	 * @param key
	 */
	getModuleAll(
		type: keyof ModuleSelectAll, // geo|pois
		name:
			| keyof SelectAllGeoEntity
			| keyof SelectAllPoisEntity
			| keyof SelectAllOhhEntity
			| keyof SelectAllPosEntity, // filters
		key: keyof TotalTypeAll, // total|shown
		property?: string
	) {
		if (isUndefined(property)) return this[type][name][key];
		return this[type][name][key][property];
	}

	clearCheck(
		type: keyof ModuleSelectAll, // geo|pois
		name:
			| keyof SelectAllGeoEntity
			| keyof SelectAllPoisEntity
			| keyof SelectAllOhhEntity
			| keyof SelectAllPosEntity, // filters
		key: keyof TotalTypeAll // total|shown
	) {
		// type . name . key
		//this.geo.cities.shown.checked
		this[type][name][key].checked = false;
	}

	clearSearch(
		type: keyof ModuleSelectAll, // geo|pois
		name:
			| keyof SelectAllGeoEntity
			| keyof SelectAllPoisEntity
			| keyof SelectAllOhhEntity
			| keyof SelectAllPosEntity, // filters
		key: keyof TotalTypeAll // total|shown
	) {
		// type . name . key
		//this.geo.cities.shown.checked
		this[type][name][key].term = "";
	}

	clearChecked(
		type: keyof ModuleSelectAll, // geo|pois|privates
		name:
			| keyof SelectAllGeoEntity
			| keyof SelectAllPoisEntity
			| keyof SelectAllPrivateEntity
			| keyof SelectAllOhhEntity
			| keyof SelectAllPosEntity // filters
	) {
		this[type][name].total.checked = false;
		this[type][name].shown.checked = false;
	}

	updateChecked(
		type: keyof ModuleSelectAll, // geo|pois
		name:
			| keyof SelectAllGeoEntity
			| keyof SelectAllPoisEntity
			| keyof SelectAllOhhEntity
			| keyof SelectAllPosEntity, // filters
		key: keyof TotalTypeAll, // total|shown
		checked: Boolean = false
	) {
		this[type][name][key].checked = checked;

		switch (key) {
			case TotalType.TOTAL:
				this[type][name][TotalType.SHOWN].checked = false;
				break;

			case TotalType.SHOWN:
				this[type][name][TotalType.TOTAL].checked = false;
				break;
		}
	}
}
