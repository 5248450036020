import Vue from "vue";
import { ItemGraphic } from "@/interfaces/graphic";
import {
	AudienceEntity,
	AudienceStrategy,
	GeoAudienceStrategy,
	PoisAudienceStrategy,
	PoisCountEntity,
	PosAudienceStrategy,
	initialPanel,
} from "@/models/persons/v10/Audience";
import {
	cloneDeep,
	isArray,
	isEqual,
	isNull,
	isUndefined,
	sortBy,
} from "lodash";
import {
	attemptFetchAudienceGraphic,
	attemptFetchPois,
	convertToFilterType,
	exportAudienceSegments,
	filterAudienceGraphics,
	attemptCanFiltersReadyAudience,
} from "./utils";
import {
	FilterItem,
	FilterParam,
	ISegmentAudience,
	TAudienceType,
} from "@/interfaces/persons/v10/audience";
import { TypeLoading } from "@/interfaces/loading";
import audienceService from "@/services/persons/v10/audience-service";
import { Notification } from "@/interfaces/proccess";
import i18n from "@/plugins/i18n";
import { RejectError } from "@/models/persons/v10/response";
import notificationService from "@/services/notification-service";
import { FilterEntity } from "@/models/persons/v10/Implements";
import { ITotalPois } from "@/interfaces/persons/v10/person";
import { AnalyzeAudienceType } from "@/interfaces/persons/v10/types";

const audienceState = {
	audience: cloneDeep(new AudienceEntity()),
	strategy: new AudienceStrategy(),
	hasFilters: false,
	canFilter: false,
	pois: new PoisCountEntity(),
};

export const AudienceModule = {
	namespaced: true,
	state: () => cloneDeep(audienceState),
	mutations: {
		ADD_FILTER(
			state: typeof audienceState,
			param: { id: string; data: FilterItem | FilterItem[] }
		) {
			if (isArray(param.data)) {
				state.audience.filters[param.id] = param.data;
			} else {
				state.audience.filters[param.id] = [param.data];
			}
		},
		REMOVE_FILTER(state: typeof audienceState, param: FilterItem) {
			const filterIndex = state.audience.filters[param.type].findIndex(
				(item) => item == param
			);
			state.audience.filters[param.type].splice(filterIndex, 1);
		},
		SAVE_FILTER(state: typeof audienceState) {
			state.audience.last_filters = cloneDeep(state.audience.filters);
		},
		RESET_FILTER(state: typeof audienceState, clearAll = true) {
			state.audience.filters = new FilterEntity();

			if (clearAll) {
				state.audience = cloneDeep(new AudienceEntity());
			}
		},
		SET_GRAPHIC(
			state: typeof audienceState,
			params: {
				name: string;
				loading: Boolean;
				source: ItemGraphic[];
			}
		) {
			let isInvalid = isNull(params.source);

			if (!isInvalid && params.source) {
				params.source.forEach((item) => {
					if (item.f0_) isInvalid = true;
				});
			}

			if (isInvalid) params.source = [];

			state.audience[params.name] = Object.assign(
				state.audience[params.name],
				params
			);
		},
		SET_FILTER_CHANGES(
			state: typeof audienceState,
			params: { filters: boolean }
		) {
			state.hasFilters = params.filters;
		},
		SET_CAN_FILTER(
			state: typeof audienceState,
			params: { canfilter: boolean }
		) {
			state.canFilter = params.canfilter;
		},
		SET_STRATEGY(state: typeof audienceState, params: AudienceStrategy) {
			state.strategy = cloneDeep(params);
		},
		RESET_STRATEGY(state: typeof audienceState) {
			state.strategy.deleteStoredData();
			state.strategy = cloneDeep(new AudienceStrategy());
		},
	},
	getters: {
		getAudience(state: typeof audienceState) {
			return state.audience;
		},

		getStrategy(state: typeof audienceState) {
			return state.strategy;
		},

		getPoisCount(state: typeof audienceState) {
			return state.pois;
		},

		canFilters(state: typeof audienceState) {
			return state.canFilter;
		},

		objectFilters(state: typeof audienceState) {
			return state.audience.filters;
		},

		getFilters:
			(
				state,
				getters: {
					objectFilters: () => {
						[key: string]: FilterItem[] | undefined;
					};
				}
			) =>
			(type: string | string[]) => {
				let filters = getters.objectFilters;
				if (typeof type === "object")
					return type.map((item) => filters[item] || []).flat();
				return filters[type] || [];
			},

		hasFilters(state: typeof audienceState) {
			let filterHasChange = false;
			let filters_count = Object.values(state.audience.filters).flat();

			return filters_count.length > 0;
		},

		filterHasChanges(state: typeof audienceState) {
			let filterHasChange = false;
			let keys = Object.keys(state.audience.filters);

			keys.forEach((item) => {
				if (filterHasChange) {
					return;
				}

				if (state.audience.filters[item][0]?.extra) {
					const current = JSON.stringify(
						state.audience.filters[item][0].extra
					);
					const last = JSON.stringify(
						state.audience.last_filters[item][0]?.extra
					);

					if (!isEqual(current, last)) {
						return (filterHasChange = true);
					}
				}

				if (
					!isEqual(
						sortBy(state.audience.filters[item], ["type", "name"]),
						sortBy(state.audience.last_filters[item], [
							"type",
							"name",
						])
					)
				) {
					filterHasChange = true;
				}
			});

			return filterHasChange;
		},

		loadingData(state: typeof audienceState) {
			let loading = false;
			state.strategy.active_keys.forEach((graphic) => {
				if (state.audience[graphic]?.loading) {
					loading = true;
				}
			});

			return loading;
		},
	},
	actions: {
		async initialize({ commit }) {
			let params;

			try {
				const type = await audienceService.fetchAudienceType();
				const { response } = type;

				if(typeof response === "undefined") return;

				/**
				 * Set current strategy
				 */
				let strategy: GeoAudienceStrategy | PoisAudienceStrategy | PosAudienceStrategy;

				switch (response) {
					case TAudienceType.GEO:
						strategy = new GeoAudienceStrategy();
						break;

					case TAudienceType.POIS:
						strategy = new PoisAudienceStrategy();
						break;

					case TAudienceType.POS:
						strategy = new PosAudienceStrategy();
						break;
				}
						
				await strategy.setSection();
				/**
				 * Initialize strategy
				 */
				commit("SET_STRATEGY", strategy);

				params = await audienceService.fetchPreviousFilters(commit);

				if (!isNull(params) && params.filters) {
					const {
						filters: {
							age,
							app_bundle,
							app_name,
							categoria,
							city_residence,
							city_seen,
							codigo_barrio,
							codigo_ciudad,
							codigo_estado,
							domain,
							estado_residence,
							gender,
							iab,
							interest,
							marca,
							neighborhood_residence,
							nivsocio,
							platform_browser,
							platform_carrier,
							platform_device_language,
							platform_device_make,
							platform_device_type,
							platform_os,
							subcategoria,
							user_id_type,
							distance,
							end_date,
							frequency,
							start_date,
						},
					}: FilterParam = params;
					// const date = filters.start_date;

					// filters: {
					// 	start_date,
					// 	end_date,
					if (!isUndefined(start_date) && !isUndefined(end_date)) {
						commit(
							"ADD_FILTER",
							convertToFilterType("date", [
								`${start_date}, ${end_date}`,
							])
						);
					}

					if (frequency) {
						// 	frequency: frequency[0] ? frequency[0].extra as IFrequency : undefined,
						commit("ADD_FILTER", {
							id: "frequency",
							data: [
								{
									type: "frequency",
									name: "6",
									extra: frequency,
								},
							],
						});
					}

					// 	user_id_type: getNames(user_type),
					commit(
						"ADD_FILTER",
						convertToFilterType("user_type", user_id_type)
					);
					// 	gender: getNames(gender),
					commit("ADD_FILTER", convertToFilterType("gender", gender));
					// 	age: getNames(age),
					commit("ADD_FILTER", convertToFilterType("age", age));
					// 	estado_residence: getNames(residence_dpto),
					commit(
						"ADD_FILTER",
						convertToFilterType("residence_dpto", estado_residence)
					);
					// 	city_residence: getNames(residence_city),
					commit(
						"ADD_FILTER",
						convertToFilterType("residence_city", city_residence)
					);
					// 	neighborhood_residence: getNames(residence_barrio),
					commit(
						"ADD_FILTER",
						convertToFilterType(
							"residence_barrio",
							neighborhood_residence
						)
					);
					// 	nivsocio: getNames(niv_socio),
					commit(
						"ADD_FILTER",
						convertToFilterType("niv_socio", nivsocio)
					);
					// 	app_bundle: getNames(app_bundle),
					commit(
						"ADD_FILTER",
						convertToFilterType("app_bundle", app_bundle)
					);
					// 	app_name: getNames(app_name),
					commit(
						"ADD_FILTER",
						convertToFilterType("app_name", app_name)
					);
					// 	domain: getNames(sites),
					commit("ADD_FILTER", convertToFilterType("sites", domain));
					// 	iab: getNames(iab),
					commit("ADD_FILTER", convertToFilterType("iab", iab));
					// 	city_seen: getNames(city_connection),
					commit(
						"ADD_FILTER",
						convertToFilterType("city_connection", city_seen)
					);
					// 	platform_device_type: getNames(device_type),
					commit(
						"ADD_FILTER",
						convertToFilterType("device_type", platform_device_type)
					);
					// 	platform_browser: getNames(browser),
					commit(
						"ADD_FILTER",
						convertToFilterType("browser", platform_browser)
					);
					// 	platform_device_make: getNames(make),
					commit(
						"ADD_FILTER",
						convertToFilterType("make", platform_device_make)
					);
					// 	platform_device_language: getNames(content_language),
					commit(
						"ADD_FILTER",
						convertToFilterType(
							"content_language",
							platform_device_language
						)
					);
					// 	platform_os: getNames(os),
					commit(
						"ADD_FILTER",
						convertToFilterType("os", platform_os)
					);
					// 	platform_carrier: getNames(carrier),
					commit(
						"ADD_FILTER",
						convertToFilterType("carrier", platform_carrier)
					);

					// 	codigo_estado: getNames(dpto_poi),
					commit(
						"ADD_FILTER",
						convertToFilterType("dpto_poi", codigo_estado)
					);
					// 	codigo_ciudad: getNames(city_poi),
					commit(
						"ADD_FILTER",
						convertToFilterType("city_poi", codigo_ciudad)
					);
					// 	codigo_barrio: getNames(barrio_poi),
					commit(
						"ADD_FILTER",
						convertToFilterType("barrio_poi", codigo_barrio)
					);
					// 	distance: poi_distance[0] ? poi_distance[0].name : undefined,
					if (distance) {
						commit(
							"ADD_FILTER",
							convertToFilterType("poi_distance", [distance])
						);
					}
					// 	categoria: getNames(category_poi),
					commit(
						"ADD_FILTER",
						convertToFilterType("category_poi", categoria)
					);
					// 	subcategoria: getNames(sub_category_poi),
					commit(
						"ADD_FILTER",
						convertToFilterType("sub_category_poi", subcategoria)
					);
					// 	marca: getNames(marca_poi),
					commit(
						"ADD_FILTER",
						convertToFilterType("marca_poi", marca)
					);
					// 	interest: getNames(interest)
					commit(
						"ADD_FILTER",
						convertToFilterType("interest", interest)
					);
					// },

					commit("SAVE_FILTER");
				}
			} catch (err) {
				if (err instanceof RejectError) {
					notificationService.notifyError(this, {
						message: err.message,
						details: err.errors,
					} as Notification);
				}
			}

			return Promise.resolve();
		},

		async fetchAll(
			{
				commit,
				rootGetters,
				state,
			}: { commit: any; rootGetters: any; state: typeof audienceState },
			graphics?: string[]
		) {
			const mappeableGraphics = graphics || state.strategy.active_keys;

			mappeableGraphics.forEach((item) => {
				const audience = state.audience as AudienceEntity;

				/**
				 * Verify with the GraphicEntity validator if
				 * this operation must be fetched. Otherwhise
				 * set the chart loading to false
				 */
				if (!audience[item].validator()) {
					return commit("SET_GRAPHIC", {
						name: item,
						source: [],
						loading: false,
					});
				}
				/**
				 * NOTE: All GraphicEntity can have their own
				 * custom validators. The default validator
				 * always return true
				 */

				attemptFetchAudienceGraphic(
					commit,
					item,
					state.strategy.type as AnalyzeAudienceType
				);
			});
		},

		async fetchFilters({ commit, getters, dispatch }) {
			try {
				dispatch("loading/setLoadingData", TypeLoading.loading, {
					root: true,
				});
				await filterAudienceGraphics(getters.objectFilters);
				await commit("SET_FILTER_CHANGES", { filters: true });
				await commit("SAVE_FILTER");
				await dispatch("fetchAll");
			} catch (err) {
				if (err instanceof RejectError) {
					notificationService.notifyError(this, {
						message: err.message,
						details: err.errors,
					} as Notification);
				}
				dispatch("loading/setLoadingData", undefined, { root: true });
			}

			dispatch("loading/setLoadingData", undefined, { root: true });
		},

		async clearFilters({ commit, dispatch }, clearAll = true) {
			await commit("RESET_FILTER", clearAll);

			if (clearAll) {
				await commit("RESET_STRATEGY");
			}
		},

		async fetchPois({ getters, commit }, params: { breakdown?: Boolean }) {
			try {
				const response = (await attemptFetchPois(
					params.breakdown
				)) as ITotalPois[];
				let getPoisCount = getters.getPoisCount as PoisCountEntity;
				await getPoisCount.setPois(response);
			} catch (err) {
				if (err instanceof RejectError) {
					notificationService.notifyError(this, {
						message: err.message,
						details: err.errors,
					} as Notification);
				}
			}
		},

		async addFilter(
			{ commit },
			param: {
				id: string;
				data: { id: string; data: FilterItem | FilterItem[] | null };
			}
		) {
			if (param) commit("ADD_FILTER", param);
		},

		async removeFilter({ commit }, param: FilterItem) {
			commit("REMOVE_FILTER", param);
		},

		async exportAudience(
			{ commit, getters, dispatch },
			param: ISegmentAudience
		) {
			try {
				dispatch("loading/setLoadingData", TypeLoading.loading, {
					root: true,
				});
				await exportAudienceSegments(param, getters.objectFilters);

				notificationService.notifySuccess(this, {
					message: i18n.t("success"),
				} as Notification);
			} catch (err) {
				if (err instanceof RejectError) {
					notificationService.notifyError(this, {
						details: err.errors,
					} as Notification);
				}
				dispatch("loading/setLoadingData", undefined, { root: true });
			}

			dispatch("loading/setLoadingData", undefined, { root: true });
		},

		async postStrategySection(
			{
				state,
				dispatch,
			}: { dispatch: any; state: typeof audienceState; commit: any },
			param: string
		) {
			try {
				const section = state.strategy.sections.find(
					(section) => section.name === param
				);

				const keys = await section?.setSelected(state.strategy.type);

				if (keys) {
					await dispatch("fetchAll", keys);
				}
			} catch (err) {
				if (err instanceof RejectError) {
					notificationService.notifyError(this, {
						message: err.message,
						details: err.errors,
					} as Notification);
				}
			}
		},

		async fetchReadyFilterAudience(
			{
				commit,
				state,
			}: { commit: any; state: typeof audienceState },
		) {
			attemptCanFiltersReadyAudience(commit);
		},
	},
};
