import { Field, ViewField } from "@/interfaces/custom_view"

export default class FieldEntity implements ViewField {

	route_name: string = "";
	dimensions: Field[] = [];
	metrics: Field[] = [];

	dimensions_hold: string[] = [];
	dimensions_default: string[] = [];

	metrics_hold: string[] = [];
	metrics_default: string[] = [];

	constructor(payload?: Partial<ViewField>) {
		this.route_name = payload?.route_name ?? "";
		this.dimensions = payload?.dimensions ?? [];
		this.metrics = payload?.metrics ?? [];

		this.dimensions_hold = payload?.dimensions_hold ?? [];
		this.dimensions_default = payload?.dimensions_default ?? [];

		this.metrics_hold = payload?.metrics_hold ?? [];
		this.metrics_default = payload?.metrics_default ?? [];
	}
}
