import ProfileService, { ProviderProfile } from "@/services/profile-service";
import AccountService from "@/services/account-service";
import { catchError } from "@/store/Util";
import { Profile } from "@/interfaces/user";
import { Commit } from "vuex";
import defineAbility from '@/config/ability';

export const ProfileModule = {
	namespaced: true,
	state: () => ({
		profile: {} as Profile,
		account: null,
		lookFeel: null,
		permission: {},
		ability: {},
		forbidden_accounts: [300] //SE INCLUYE LA CUENTA DE BLASTER PARA OCULTAR BOTONES DEL MENU Y PERSONALIZAR VISTAS!!
	}),
	mutations: {
		SET_PROFILE(state: any, _profile: Profile = {} as Profile) {
			state.profile = _profile;
		},
		SET_ACCOUNT(state: any, _account = null) {
			state.account = _account;
		},
		SET_LOOK_FEEL(state: any, _lookFeel = null) {
			state.lookFeel = _lookFeel;
		},
		SET_PROFILE_LANGUAGE(state: { profile: Profile }, language: string) {
			state.profile.language = language;
		},
		SET_PERMISSION(state: any, _permission: any) {
			state.permission = _permission;
		},
		SET_ABILITY(state: any, _ability: any) {
			state.ability = _ability;
		},
		UPDATE_PROFILE<T>(
			state: { profile: Profile },
			data: { key: string; value: T }
		) {
			state.profile[data.key] = data.value;
		},
	},
	getters: {
		profile(state: { profile: Profile }) {
			return state.profile;
		},
		getCurrentLanguage(state: { profile: Profile }) {
			return state.profile.language;
		},
		getForbiddenAccounts(state: any) {
			return state.forbidden_accounts;
		},
		account(state: any) {
			return state.account;
		},
		lookFeel(state: any) {
			return state.lookFeel;
		},
		logo(state: any) {
			return state.profile?.logo;
		},
		isRolReport(state: any) {
			return false;
		},
		getPermission(state: any) {
			return state.permission;
		},
		getAbility(state: any) {
			return state.ability;
		},
		getProfileData(state: { profile: Profile }) {
			return state.profile;
		},
		getColors(state: { profile: Profile }){
			return state.profile.colors;
		},
		isAccountRM(state: any) {
			return state.account.account_type === "retail_media";
		},
	},
	actions: {
		async fetchProfile({ commit, rootGetters }) {
			try {
				const token: string = rootGetters["auth/getToken"];
				if(!token) return;

				const response = await ProfileService.profile();
				const account = await AccountService.account();
				const getProfile = ProviderProfile(response, account);
				commit("SET_PROFILE", getProfile);
				commit("SET_ACCOUNT", account);
				commit("SET_PERMISSION", response.permissions);

				const ability = defineAbility();
				commit("SET_ABILITY", ability);

				return await Promise.resolve(getProfile);
			} catch (error) {
				catchError(this, error, { to: "Root" });
				return await Promise.reject(error);
			}
		},
		async fetchLookAndFeel({ commit }: any, params: { domain: string }) {
			try {
				const response = await ProfileService.getLookAndFeelLogin(
					params
				);
				commit("SET_LOOK_FEEL", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "Root" });
				return await Promise.reject(error);
			}
		},
		async setProfileLanguage(
			{ commit }: { commit: Commit },
			language: string
		) {
			return commit("SET_PROFILE_LANGUAGE", language);
		},
		async setProfileData<T>(
			{ commit }: { commit: Commit },
			data: { key: string; value: T }
		) {
			return commit("UPDATE_PROFILE", data);
		},
		async setAbility({ commit }, ability) {
			try {
				return commit("SET_ABILITY", ability);
			} catch (error) {
				
			}
		},
		isAccountAuthorized({ commit, state }, accountID) {
			try {
				return !state.forbidden_accounts.includes(accountID);
			} catch (error) {
				
			}
		},
	},
};
