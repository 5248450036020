import { FilterEntity, GraphicEntity } from "./Implements";
import {
	allowAttributions,
	allowRichAttributions,
	allowTableReports,
} from "@/store/Modules/Persons/v10/utils";
import { convLocaleString } from "@/utils/convert";
import i18n from "@/plugins/i18n";
import {
	AttributionsItem,
	BrandAndPoiItem,
	HeatmapItem,
	DailyRichAttributionsItem,
	TotalRichAttributionsItem,
	ItemGraphic,
} from "@/interfaces/graphic";
import { ITotalPois } from "@/interfaces/persons/v10/person";
import {
	AnalyzeAudienceType,
	PersonStrategyKey,
	PersonUsesCasesKey,
	TotalPoisType,
} from "@/interfaces/persons/v10/types";
import {
	AudienceSectionItemOptions,
	TStorageAudienceSection,
} from "@/interfaces/persons/v10/audience";
import { isString, isUndefined } from "lodash";
import audienceService from "@/services/persons/v10/audience-service";
import { getFromStorage, setToStorage } from "@/services/storage-service";
import { removeFromStorage } from "@/services/storage-service";
import {
	getStrategyUseCases,
	isUseCasesStoreAttribution,
} from "@/utils/services-global";

export const audienceTypePois: number[] = [1, 4];

export class AudienceEntity {
	total_reach = new GraphicEntity();
	proximity_to_poi = new GraphicEntity();

	gender = new GraphicEntity();
	age = new GraphicEntity();
	niv_socio = new GraphicEntity();

	category_poi = new GraphicEntity();
	sub_category_poi = new GraphicEntity();
	marca_poi = new GraphicEntity();
	dpto_poi = new GraphicEntity();
	city_poi = new GraphicEntity();
	barrio_poi = new GraphicEntity();

	date_of_week = new GraphicEntity();
	time_of_day = new GraphicEntity();
	date = new GraphicEntity();
	time_of_day_of_week = new GraphicEntity<HeatmapItem>();

	residence_dpto = new GraphicEntity();
	residence_city = new GraphicEntity();
	residence_barrio = new GraphicEntity();

	iab = new GraphicEntity();
	interest = new GraphicEntity();
	sites = new GraphicEntity();
	app_bundle = new GraphicEntity();
	app_name = new GraphicEntity();
	content_language = new GraphicEntity();
	city_connection = new GraphicEntity();

	carrier = new GraphicEntity();
	device_type = new GraphicEntity();
	make = new GraphicEntity();
	browser = new GraphicEntity();
	os = new GraphicEntity();

	tables = new GraphicEntity<string>(allowTableReports);
	// metrics_by_brand_and_poi = new GraphicEntity<BrandAndPoiItem>();
	// attributions = new GraphicEntity<AttributionsItem>(allowAttributions);
	// rich_attributions = new GraphicEntity<TotalRichAttributionsItem>(
	// 	allowRichAttributions
	// );
	// rich_attributions_daily = new GraphicEntity<DailyRichAttributionsItem>(
	// 	allowRichAttributions
	// );

	// Filters
	filters: FilterEntity = new FilterEntity();
	last_filters: FilterEntity = new FilterEntity();

	constructor() {}
}
export class PoisCountEntity {
	public = 0;
	private = 0;
	ooh = 0;
	fetched = false;
	maxPois = 200000;

	isFetched() {
		return this.fetched;
	}

	resetFetch() {
		this.fetched = false;
		this.public = 0;
		this.private = 0;
		this.ooh = 0;
	}

	getPublic() {
		return this.public;
	}

	getPrivate() {
		return this.private;
	}

	getOoh() {
		return this.ooh;
	}

	getTotal() {
		return this.public + this.private + this.ooh;
	}

	isValid() {
		return this.getTotal() > 0; //  && this.getTotal() <= this.maxPois
	}

	async setPois(items: ITotalPois[]) {
		//console.log("PoisCountEntity::setPois", { items });
		items.forEach((item) => {
			if (item.key.toLocaleLowerCase().includes(TotalPoisType.PUBLIC)) {
				this.public += item.value;
			} else if (
				item.key.toLocaleLowerCase().includes(TotalPoisType.OOH)
			) {
				this.ooh += item.value;
			} else {
				this.private += item.value;
			}
		});
		this.fetched = true;
	}

	preparePoisCount() {
		let poisCount: { count: string }[] = [];

		if (this.getPublic() > 0) {
			poisCount.push({
				count: `${i18n.t(
					"Persons10.poisCount.public"
				)}: ${convLocaleString(this.getPublic())}`,
			});
		}

		if (this.getPrivate() > 0) {
			poisCount.push({
				count: `${i18n.t(
					"Persons10.poisCount.private"
				)}: ${convLocaleString(this.getPrivate())}`,
			});
		}

		if (this.getOoh() > 0) {
			poisCount.push({
				count: `${i18n.t(
					"Persons10.poisCount.ooh"
				)}: ${convLocaleString(this.getOoh())}`,
			});
		}

		return poisCount;
	}

	async prepareGraphicData() {
		let source: ItemGraphic[] = [];

		if (this.public) {
			source.push({
				name: i18n.t("persons.v10.poisCount.public"),
				uniques: this.public,
			} as ItemGraphic);
		}

		if (this.ooh) {
			source.push({
				name: i18n.t("persons.v10.poisCount.ooh"),
				uniques: this.ooh,
			} as ItemGraphic);
		}

		if (this.private) {
			source.push({
				name: i18n.t("persons.v10.poisCount.private"),
				uniques: this.private,
			} as ItemGraphic);
		}

		return source;
	}
}

export class AudienceSectionItem {
	/**
	 * Keys of graphic to be loaded in this strategy
	 */
	graphic_keys: string[];

	/**
	 * Name of the current section
	 */
	name: string;

	/**
	 * If the post is already done
	 */
	fetched = false;

	/**
	 * If the button was clicked to show the graphics
	 */
	selected = false;

	/**
	 * When is perfirming a fetching
	 */
	loading = false;

	/**
	 * Icon to be showed in button
	 */
	icon = "mdi-sitemap-outline";

	panel = NaN;

	/**
	 * List of graphic keys associated to this strategy
	 * @param graphic_keys keys of api url to be fetched
	 */
	constructor(options: AudienceSectionItemOptions) {
		const { name, graphic_keys, panel, ...rest } = options;

		this.name = name;
		this.panel = panel;
		this.graphic_keys = graphic_keys;

		/**
		 * Set options to object
		 */
		Object.keys(rest).forEach((option) => {
			if (
				!isUndefined(options[option]) && // has value
				!isUndefined(this[option]) // exist in class
			) {
				this[option] = options[option];
			}
		});
	}

	/**
	 * Set fetched to avoid re-posting
	 * @param fetched default `true`
	 */
	setFetched(fetched = true) {
		this.fetched = fetched;
	}

	/**
	 *
	 * @param type analyze type to post section build
	 */
	async setSelected(type?: AnalyzeAudienceType) {
		try {
			if (!this.fetched && type) {
				this.loading = true;
				/** Post action */
				await audienceService.postSectionByKey(type, this.name);

				this.setFetched();
				this.loading = false;
			}

			this.selected = true;

			await this.saveStorage();
			return this.graphic_keys;
		} catch (e) {
			this.loading = false;
			Promise.reject(e);
		}
	}

	/**
	 * Store item in storage
	 */
	async saveStorage() {
		const savedStorage = (await this.getStorage()) || {};

		savedStorage[this.name] = this;

		setToStorage("sections", JSON.stringify(savedStorage));
	}

	async getStorage() {
		const sections = await getFromStorage("sections");

		if (isString(sections) && sections) {
			return JSON.parse(sections) as TStorageAudienceSection;
		}
	}
}

export const campaignsSection = new AudienceSectionItem({
	name: "campaigns",
	graphic_keys: [],
	fetched: true,
	selected: true,
	panel: -1,
});

/**
 * To be showed first in pois strategies
 */
export const firstPoisInformationSection = new AudienceSectionItem({
	name: "first_pois",
	graphic_keys: ["total_reach", "proximity_to_poi", "tables"],
	fetched: true,
	selected: true,
	panel: 0,
});

/**
 * To be showed with pois strategy
 */
export const poisInformationSection = new AudienceSectionItem({
	name: "hour_and_pois_information",
	graphic_keys: [
		// Date
		"date_of_week",
		"time_of_day",
		"date",
		"time_of_day_of_week",
		// Pois Information
		"category_poi",
		"sub_category_poi",
		"marca_poi",
		"dpto_poi",
		"city_poi",
		"barrio_poi",
	],
	fetched: true,
	selected: true,
	icon: "mdi-sitemap-outline",
	panel: 2,
});

/**
 * To be showed first in geo strategies
 */
export const firstGeoInformationSection = new AudienceSectionItem({
	name: "first_geo",
	graphic_keys: ["total_reach"],
	fetched: true,
	selected: true,
	panel: 0,
});

/**
 * To be showed with geo strategy
 */
export const geoInformationSection = new AudienceSectionItem({
	name: "hour_information",
	graphic_keys: [
		// Date
		"date_of_week",
		"time_of_day",
		"date",
		"time_of_day_of_week",
		"dpto_poi",
		"city_poi",
		"barrio_poi",
	],
	fetched: true,
	selected: true,
	icon: "mdi-sitemap-outline",
	panel: 2,
});

/**
 * Config the class with fetched or not if is geo or pois
 * @param fetched if the post is done or not
 * @returns AudienceSectionItem to be used in main class
 */
export const demoHomeAudience = (fetched = false) =>
	new AudienceSectionItem({
		name: "demo_home_audience",
		graphic_keys: [
			// Socio-demographic info
			"gender",
			"age",
			"niv_socio",
			"residence_dpto",
			"residence_city",
			"residence_barrio",
		],
		fetched,
		icon: "mdi-human-male-female",
		panel: 1,
		selected: true,
	});

/**
 * Information of reach
 */
export const bidRequestAudience = new AudienceSectionItem({
	name: "bid_request_audience",
	graphic_keys: [
		"iab",
		"sites",
		"app_name",
		"app_bundle",
		"content_language",
		"city_connection",
		"carrier",
		"device_type",
		"make",
		"browser",
		"os",
	],
	icon: "mdi-cellphone-link",
	panel: 3,
	selected: true,
	fetched: true,
});

/**
 * Main class of each strategies
 */
export class AudienceStrategy {
	/**
	 * Sections to be loaded in buttons
	 */
	sections: AudienceSectionItem[] = [];

	/**
	 * Analysis type to perform fetchings
	 */
	type?: AnalyzeAudienceType;

	get active_keys(): string[] {
		const keys: string[] = [];

		this.sections.forEach((section) => {
			if (section.selected) {
				keys.push(...section.graphic_keys);
			}
		});

		return keys;
	}

	get isAllSectionSelected(): boolean {
		return (
			this.sections.length > 0 &&
			!this.sections.some((item) => !item.selected)
		);
	}

	hasKey(...keys: string[]) {
		return this.active_keys.some((key) => keys.includes(key));
	}

	/**
	 * Get data from storage
	 */
	restoreStoredData() {
		const sections = getFromStorage("sections");

		/** If is defined */
		if (isString(sections) && sections) {
			const savedSections = JSON.parse(sections);

			/** map each item to set previous data */
			this.sections.forEach((section, index) => {
				const savedSection = savedSections[section.name];

				/** save current saved data from storage in new class again */
				if (savedSection) {
					this.sections[index] = new AudienceSectionItem(
						savedSection
					);
				}
			});
		}
	}

	/**
	 * Get data from storage
	 */
	deleteStoredData() {
		const sections = removeFromStorage("sections");
	}
}

/**
 * Main class for Pois strategy
 */
export class PoisAudienceStrategy extends AudienceStrategy {
	type = AnalyzeAudienceType.POIS;

	constructor() {
		super();
		this.restoreStoredData();
	}

	async setSection() {
		const isStore = isUseCasesStoreAttribution();
		if (isStore) {
			this.sections.splice(0, 0, campaignsSection);
		}
	}

	sections = [
		firstPoisInformationSection,
		demoHomeAudience(false),
		poisInformationSection,
		bidRequestAudience,
	];
}

/**
 * Main class for Geo strategy
 */
export class GeoAudienceStrategy extends AudienceStrategy {
	type = AnalyzeAudienceType.GEO;

	constructor() {
		super();
		this.restoreStoredData();
	}

	async setSection() {}

	sections = [
		firstGeoInformationSection,
		demoHomeAudience(true),
		geoInformationSection,
		bidRequestAudience,
	];
}

/**
 * Main class for POS strategy
 */
export class PosAudienceStrategy extends AudienceStrategy {
	type = AnalyzeAudienceType.POS;

	constructor() {
		super();
		this.restoreStoredData();
	}

	async setSection() {}

	sections = [
		firstGeoInformationSection,
		demoHomeAudience(true),
		geoInformationSection,
		bidRequestAudience,
	];
}

export const enableFetchSections = [
	{
		strategy: PersonStrategyKey.CREATE_AUDIENCE,
		use_cases: PersonUsesCasesKey.SEEN_CLOSE_TO_POI_OOH,
	},
	{
		strategy: PersonStrategyKey.CREATE_AUDIENCE,
		use_cases: PersonUsesCasesKey.STORE_ATTRIBUTION_REPORT,
	},
	{
		strategy: PersonStrategyKey.CREATE_AUDIENCE,
		use_cases: PersonUsesCasesKey.SEEN_CERTAIN_AREA,
	},
];

export function willMakeFetchSection(
	strategy: PersonStrategyKey,
	use_cases: PersonUsesCasesKey
) {
	return enableFetchSections.some((s) => {
		return s.strategy === strategy && s.use_cases === use_cases;
	});
}

export function verifyStrategyUseCases() {
	const { strategy, use_cases } = getStrategyUseCases();

	const isWillMakeFetchSection = willMakeFetchSection(
		strategy as PersonStrategyKey,
		use_cases as PersonUsesCasesKey
	);

	return isWillMakeFetchSection;
}

export const initialPanel: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

export const matchNameID = {
	[campaignsSection.name]: campaignsSection.panel,
	[firstPoisInformationSection.name]: firstPoisInformationSection.panel,
	[firstGeoInformationSection.name]: firstGeoInformationSection.panel,
	[demoHomeAudience().name]: demoHomeAudience().panel,
	[poisInformationSection.name]: poisInformationSection.panel,
	[geoInformationSection.name]: geoInformationSection.panel,
	[bidRequestAudience.name]: bidRequestAudience.panel,
};

export const audiencePanelIdentifier: string[] = [
	campaignsSection.name,
	firstPoisInformationSection.name,
	firstGeoInformationSection.name,
	demoHomeAudience().name,
	poisInformationSection.name,
	geoInformationSection.name,
	bidRequestAudience.name,
];
