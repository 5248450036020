import { PersonGeoKey, PersonOohKey, PersonPoisKey, PersonPosKey } from "./types";

export enum LikeType {
	GEO = "geo",
	POIS = "pois",
	OOH = "ooh",
}

export const AssignIDs = [
	-1000, -1001, -1002, -2000, -2001, -2002, -2003, -3000, -3001, -3002, -3003,
];

export enum AssignID {
	states = -1000,
	cities = -1001,	
	neighborhoods = -1002,

	categories = -2000,
	subcategories = -2001,
	brands = -2002,
	names = -2003,

	ooh_categories = -3000,
	ooh_subcategories = -3001,
	ooh_brands = -3002,
	ooh_names = -3003,
	
	macro = -4000,
	category = -4001,
	company = -4002,
	brand = -4003,
	name_sku = -4004,
	store_type = -4005,
}

export enum LikeName {
	STATES = "states",
	CITIES = "cities",
	NEIGHBORHOODS = "neighborhoods",

	CATEGORIES = "categories",
	SUBCATEGORIES = "subcategories",
	BRANDS = "brands",
	NAMES = "names",

	CHIPPER_STATES = "chipper_states",
	CHIPPER_CITIES = "chipper_cities",
	CHIPPER_NEIGHBORHOODS = "chipper_neighborhoods",
	CHIPPER_MACRO_CATEGORIES = "macro",
	CHIPPER_CATEGORIES = "category",
	CHIPPER_COMPANIES = "company",
	CHIPPER_BRANDS = "brand",
	CHIPPER_NAMES_SKU = "name_sku",
	CHIPPER_STORES_TYPE = "store_type",

	OOH_CATEGORIES = "ooh_categories",
	OOH_SUBCATEGORIES = "ooh_subcategories",
	OOH_BRANDS = "ooh_brands",
	OOH_NAMES = "ooh_names",

	PRIVATES = "privates",
}

export interface LikeData {
	all: Boolean;
	term: string | null;
}

export interface LikeAllGeo {
	[PersonGeoKey.STATES]: LikeData;
	[PersonGeoKey.CITIES]: LikeData;
	[PersonGeoKey.NEIGHBORHOODS]: LikeData;
}

export interface LikeAllPois {
	[PersonPoisKey.CATEGORIES]: LikeData;
	[PersonPoisKey.SUBCATEGORIES]: LikeData;
	[PersonPoisKey.BRANDS]: LikeData;
	[PersonPoisKey.SUBCATEGORIES]: LikeData;
}

export interface LikeAllOoh {
	[PersonOohKey.OOH_CATEGORIES]: LikeData;
	[PersonOohKey.OOH_SUBCATEGORIES]: LikeData;
	[PersonOohKey.OOH_BRANDS]: LikeData;
	[PersonOohKey.OOH_NAMES]: LikeData;
}

export interface LikeAllPos {
	[PersonPosKey.CHIPPER_STATES]: LikeData;
	[PersonPosKey.CHIPPER_CITIES]: LikeData;
	[PersonPosKey.CHIPPER_NEIGHBORHOODS]: LikeData;	
	[PersonPosKey.CHIPPER_MACRO_CATEGORIES]: LikeData;
	[PersonPosKey.CHIPPER_CATEGORIES]: LikeData;
	[PersonPosKey.CHIPPER_COMPANIES]: LikeData;
	[PersonPosKey.CHIPPER_BRANDS]: LikeData;
	[PersonPosKey.CHIPPER_NAMES_SKU]: LikeData;
	[PersonPosKey.CHIPPER_STORES_TYPE]: LikeData;
}

export interface LikeDataAll {
	geo: LikeAllGeo;
	pois: LikeAllPois;
	ooh: LikeAllOoh;
}
